import {
	FETCH_LOGIN,
	FETCH_SIGNUP,
	LOGIN_FAILURE,
	SIGNUP_RESULT,
	FETCH_LOGOUT,
	LOGOUT_RESULT,
	FETCH_FORGET_PASSWORD,
	FORGET_PASSWORD_RESULT,
	FORGET_PASSWORD_FAILURE,
	FETCH_DELETE_USER,
	DELETE_USER_RESULT,
	FETCH_LOGIN_WITH_FB,
	FETCH_SIGNUP_WITH_FB,
	LOGOUT_FAILURE,
	DELETE_USER_FAILURE,
	FETCH_CLEAR_PASSWORD,
	SET_CLEAR_PASSWORD_RESULT,
	SET_CLEAR_PASSWORD_FAILURE,
	FETCH_REGISTER_PROFESSIONAL,
	SET_REGISTER_PROFESSIONAL_RESULT,
	SET_REGISTER_PROFESSIONAL_FAILURE,
	FETCH_CHANGE_PASSWORD,
	SET_CHANGE_PASSWORD_RESULT,
	SET_CHANGE_PASSWORD_FAILURE,
	FETCH_ACTIVATE_ACCOUNT,
	SET_ACTIVATE_ACCOUNT_RESULT,
	SET_ACTIVATE_ACCOUNT_FAILURE,
	FETCH_LOGIN_USING_TOKEN,
	SET_STRATEGY
} from "./types";
import { apiAction } from "./api";
import { fetchGetUser } from "./profile";

export function fetchLogin(email, password) {
	return apiAction({
		url: "/api/auth",
		data: { email, password, fromPro: true },
		method: "POST",
		onSuccess: () => [
			fetchGetUser(true),
			setStrategy("local"),
			setEmail(email)
		],
		onFailure: loginFailure,
		label: FETCH_LOGIN
	});
}

export function fetchLoginUsingToken(data) {
	return apiAction({
		url: "/api/auth/jwt",
		data,
		method: "POST",
		onSuccess: data => [fetchGetUser(true), setStrategy("jwt")],
		onFailure: loginFailure,
		label: FETCH_LOGIN_USING_TOKEN
	});
}

function loginFailure(error) {
	return {
		type: LOGIN_FAILURE,
		payload: error
	};
}

export function fetchLoginWithFB(communityId, token, email) {
	return apiAction({
		url: "/api/auth/fb",
		data: { token, communityId },
		method: "POST",
		onSuccess: () => [
			fetchGetUser(true),
			setStrategy("facebook"),
			setEmail(email)
		],
		onFailure: loginFailure,
		label: FETCH_LOGIN_WITH_FB
	});
}

function setStrategy(strategy) {
	localStorage.setItem("strategy", strategy);
	return {
		type: SET_STRATEGY,
		payload: strategy
	};
}

function setEmail(email) {
	if (email) {
		localStorage.setItem("emailOrPseudo", email);
	}
}

export function fetchLogout() {
	return apiAction({
		url: "/api/auth",
		method: "DELETE",
		onSuccess: logout,
		onFailure: logoutFailure,
		label: FETCH_LOGOUT
	});
}

function logout(data) {
	return {
		type: LOGOUT_RESULT,
		payload: data
	};
}

function logoutFailure(error) {
	return {
		type: LOGOUT_FAILURE,
		payload: error
	};
}

export function fetchDeleteUser() {
	return apiAction({
		url: "/api/user",
		method: "DELETE",
		onSuccess: deleteUserResult,
		onFailure: deleteUserFailure,
		label: FETCH_DELETE_USER
	});
}

function deleteUserResult(data) {
	return {
		type: DELETE_USER_RESULT,
		payload: data
	};
}

function deleteUserFailure(error) {
	return {
		type: DELETE_USER_FAILURE,
		payload: error
	};
}

export function fetchForgetPassword(email) {
	return apiAction({
		url: "/api/clear",
		method: "POST",
		data: { email, appname: "OCamping" },
		onSuccess: forgetPaswordResult,
		onFailure: forgetPasswordFailure,
		label: FETCH_FORGET_PASSWORD
	});
}

function forgetPaswordResult(data) {
	return {
		type: FORGET_PASSWORD_RESULT,
		payload: data
	};
}

function forgetPasswordFailure(error) {
	return {
		type: FORGET_PASSWORD_FAILURE,
		payload: error
	};
}

export function fetchSignUp(email, registerkey, communityId, lang) {
	return apiAction({
		url: "/api/users",
		data: { email, registerkey, communityId, lang },
		method: "POST",
		onSuccess: setSignupResult,
		onFailure: loginFailure,
		label: FETCH_SIGNUP
	});
}

export function fetchSignUpWithFB(token, email) {
	return apiAction({
		url: "/api/auth/signup/fb",
		data: { token, email },
		method: "POST",
		onSuccess: data => [
			fetchGetUser(true),
			setStrategy("facebook"),
			setEmail(email)
		],
		onFailure: loginFailure,
		label: FETCH_SIGNUP_WITH_FB
	});
}

function setSignupResult(data) {
	return {
		type: SIGNUP_RESULT,
		payload: data
	};
}

export function fetchClearPassword(params) {
	return apiAction({
		url: "/api/clear" + params,
		method: "GET",
		onSuccess: setClearPasswordResult,
		onFailure: setClearPasswordFailure,
		label: FETCH_CLEAR_PASSWORD
	});
}

function setClearPasswordResult(message) {
	return {
		type: SET_CLEAR_PASSWORD_RESULT,
		payload: message
	};
}

function setClearPasswordFailure(error) {
	return {
		type: SET_CLEAR_PASSWORD_FAILURE,
		payload: error
	};
}

export function fetchActivateAccount(params) {
	return apiAction({
		url: "/api/activate" + params,
		method: "GET",
		onSuccess: setActivateAccountResult,
		onFailure: setActivateAccountFailure,
		label: FETCH_ACTIVATE_ACCOUNT
	});
}

function setActivateAccountResult(message) {
	return {
		type: SET_ACTIVATE_ACCOUNT_RESULT,
		payload: message
	};
}

function setActivateAccountFailure(error) {
	return {
		type: SET_ACTIVATE_ACCOUNT_FAILURE,
		payload: error
	};
}

export function fetchRegisterProfessional(data) {
	return apiAction({
		url: "/api/register/pro",
		method: "POST",
		data,
		onSuccess: setRegisterProfessionalResult,
		onFailure: setRegisterProfessionalFailure,
		label: FETCH_REGISTER_PROFESSIONAL
	});
}

function setRegisterProfessionalResult(message) {
	return {
		type: SET_REGISTER_PROFESSIONAL_RESULT,
		payload: message
	};
}

function setRegisterProfessionalFailure(error) {
	return {
		type: SET_REGISTER_PROFESSIONAL_FAILURE,
		payload: error
	};
}

export function fetchChangePassword(data) {
	return apiAction({
		url: "/api/password",
		method: "POST",
		data,
		onSuccess: setChangePasswordResult,
		onFailure: setChangePasswordFailure,
		label: FETCH_CHANGE_PASSWORD
	});
}

function setChangePasswordResult(message) {
	return {
		type: SET_CHANGE_PASSWORD_RESULT,
		payload: message
	};
}

function setChangePasswordFailure(error) {
	return {
		type: SET_CHANGE_PASSWORD_FAILURE,
		payload: error
	};
}
