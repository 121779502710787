const actionArrayMiddleware =
	({ dispatch }) =>
	next =>
	action => {
		if (Array.isArray(action)) {
			action.forEach(obj => obj && dispatch(obj));
			return;
		}

		next(action);
	};

export default actionArrayMiddleware;
