// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import withStyles from "@material-ui/core/styles/withStyles";
import FormHelperText from "@material-ui/core/FormHelperText";

// @material-ui/icons
import { fetchGetGoodDealsCategories } from "actions/gooddeal";
import { fetchGetGoodDealsTemplates } from "actions/template";
import {
	REMOVE_GOODDEALS_IMAGE,
	SET_GOODDEALS_FILES_IMAGES,
	SET_GOODDEALS_CATEGORY,
	SET_IMAGE_ERROR,
	SET_TITLE_ERROR,
	SET_GOODDEALS_TITLE_FR,
	SET_GOODDEALS_TEMPLATE,
	SET_GOODDEALS_CUSTOM_URI,
	SET_CUSTOM_URI_ERROR
} from "actions/types";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import CustomInput from "components/CustomInput/CustomInput";
import ImageUpload from "components/CustomUpload/ImageUpload";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	...customInputStyle,
	...customSelectStyle
};

class Step1 extends React.Component {
	componentDidMount() {
		const {
			fetchGetGoodDealsCategories,
			fetchGetGoodDealsTemplates,
			templatesUsedByGooddeals
		} = this.props;
		fetchGetGoodDealsCategories();
		if (!templatesUsedByGooddeals) {
			fetchGetGoodDealsTemplates();
		}
	}

	sendState() {
		return this.state;
	}

	isValidated() {
		const { dispatch, title, files, photoUri, customUri } = this.props;

		let success = true;
		if (files.length === 0 && !photoUri) {
			dispatch({
				type: SET_IMAGE_ERROR
			});
			success = false;
		}
		if (!title) {
			dispatch({
				type: SET_TITLE_ERROR
			});
			success = false;
		}
		if (!customUri || customUri.trim().length === 0) {
			dispatch({
				type: SET_CUSTOM_URI_ERROR
			});
			success = false;
		}
		return success;
	}

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_GOODDEALS_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_GOODDEALS_IMAGE
		});
	};

	renderMenuItems = () => {
		const { classes, goodDealsCategories } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const goodDealCategory of goodDealsCategories) {
			components.push(
				<MenuItem
					key={goodDealCategory.label}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={goodDealCategory.value}
				>
					{goodDealCategory.label}
				</MenuItem>
			);
		}
		return components;
	};

	renderTemplatesMenuItems = () => {
		const { classes, templatesUsedByGooddeals } = this.props;
		const components = [];

		// eslint-disable-next-line
		for (const templateUsedByGooddeal of templatesUsedByGooddeals) {
			components.push(
				<MenuItem
					key={templateUsedByGooddeal.label}
					classes={{
						root: classes.selectMenuItem,
						selected: classes.selectMenuItemSelected
					}}
					value={templateUsedByGooddeal.value}
				>
					{templateUsedByGooddeal.label}
				</MenuItem>
			);
		}
		return components;
	};

	render() {
		const {
			classes,
			dispatch,
			imageErrorMsg,
			title,
			titleErrorMsg,
			categoryId,
			photoUri,
			templatesUsedByGooddeals,
			templateId,
			templates,
			customUri,
			customUriErrorMsg
		} = this.props;
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={4} md={4}>
					<legend>Image au format paysage *</legend>
					<ImageUpload
						ref={this.child}
						onImageLoaded={this.onImageLoaded}
						onImageRemoved={this.onImageRemoved}
						photoUri={photoUri}
						addButtonProps={{
							color: "brown",
							round: true
						}}
						changeButtonProps={{
							color: "brown",
							round: true
						}}
						removeButtonProps={{
							color: "danger",
							round: true
						}}
					/>
					{imageErrorMsg && (
						<FormHelperText className={classes.labelRootError}>
							{imageErrorMsg}
						</FormHelperText>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{categoryId && (
						<FormControl fullWidth className={classes.selectFormControl}>
							<InputLabel
								htmlFor="simple-select"
								className={classes.selectLabel}
							>
								Catégorie *
							</InputLabel>
							<Select
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select: classes.select
								}}
								value={categoryId}
								onChange={e =>
									dispatch({
										type: SET_GOODDEALS_CATEGORY,
										category: { ...e.target }
									})
								}
								inputProps={{
									required: true,
									name: "simpleSelect",
									id: "simple-select"
								}}
							>
								<MenuItem
									disabled
									classes={{
										root: classes.selectMenuItem
									}}
								>
									Sélectionner la catégorie *
								</MenuItem>
								{this.renderMenuItems()}
							</Select>
						</FormControl>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Titre *"
						id="title"
						formControlProps={{
							fullWidth: true
						}}
						error={titleErrorMsg !== null}
						helperText={titleErrorMsg ? titleErrorMsg : undefined}
						inputProps={{
							value: title ? title : "",
							onChange: e =>
								dispatch({
									type: SET_GOODDEALS_TITLE_FR,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="URI *"
						id="uri"
						formControlProps={{
							fullWidth: true
						}}
						error={customUriErrorMsg !== null}
						helperText={customUriErrorMsg ? customUriErrorMsg : undefined}
						inputProps={{
							value: customUri ? customUri : "",
							onChange: e =>
								dispatch({
									type: SET_GOODDEALS_CUSTOM_URI,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{templatesUsedByGooddeals && (
						<FormControl fullWidth className={classes.selectFormControl}>
							<InputLabel
								htmlFor="simple-select"
								className={classes.selectLabel}
							>
								Activité
							</InputLabel>
							<Select
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select: classes.select
								}}
								value={templateId}
								onChange={e =>
									dispatch({
										type: SET_GOODDEALS_TEMPLATE,
										template: templates[e.target.value]
									})
								}
								inputProps={{
									required: false,
									name: "simpleSelect",
									id: "simple-select"
								}}
							>
								<MenuItem
									key="default"
									classes={{
										root: classes.selectMenuItem
									}}
									value=""
								>
									Sélectionner l'activité
								</MenuItem>
								{this.renderTemplatesMenuItems()}
							</Select>
						</FormControl>
					)}
				</GridItem>
			</GridContainer>
		);
	}
}

Step1.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		imageErrorMsg,
		title,
		titleErrorMsg,
		categoryId,
		files,
		photoUri,
		templateId,
		customUri,
		customUriErrorMsg
	} = state.gooddealReducer;
	const { community, templatesUsedByGooddeals } = state.entitiesReducer;
	return {
		templatesUsedByGooddeals:
			templatesUsedByGooddeals &&
			Object.values(templatesUsedByGooddeals).map(obj => ({
				label: obj.name,
				value: obj._id
			})),
		goodDealsCategories: Object.values(
			state.entitiesReducer.goodDealsCategories
		).map(obj => ({
			label: obj.name,
			value: obj._id
		})),
		imageErrorMsg,
		title,
		titleErrorMsg,
		categoryId,
		files,
		photoUri,
		community,
		templateId,
		templates: templatesUsedByGooddeals,
		customUri,
		customUriErrorMsg
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetGoodDealsCategories,
			fetchGetGoodDealsTemplates
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(Step1));
