import React from "react";

import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Circle,
	InfoWindow,
	Marker
} from "react-google-maps";

import Button from "../../components/CustomButtons/Button";

import SearchBox from "react-google-maps/lib/components/places/SearchBox";
// import GoogleMapMarkerInfoWindow from './gMapMarkerInfoWindow';

const google = (window.google = window.google ? window.google : {});

const INPUT_STYLE = {
	boxSizing: "border-box",
	MozBoxSizing: "border-box",
	border: "1px solid transparent",
	width: "240px",
	height: "32px",
	marginTop: "27px",
	marginRight: "20px",
	padding: "0 12px",
	borderRadius: "1px",
	boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
	fontSize: "14px",
	outline: "none",
	textOverflow: "ellipses"
};

const GoogleMapComponent = withScriptjs(
	withGoogleMap(props => (
		<GoogleMap
			ref={props.onMapMounted}
			defaultZoom={props.zoom}
			center={props.center}
			// onBoundsChanged={props.onBoundsChanged}
			options={{
				streetViewControl: false,
				mapTypeControl: false,
				mapTypeId: google.maps.MapTypeId.ROADMAP
			}}
		>
			{props.withSearchBox && (
				<SearchBox
					ref={props.onSearchBoxMounted}
					bounds={props.bounds}
					controlPosition={google.maps.ControlPosition.TOP_RIGHT}
					onPlacesChanged={props.onPlacesChanged}
				>
					<input
						type="text"
						placeholder="Rechercher un lieu"
						style={INPUT_STYLE}
					/>
				</SearchBox>
			)}

			{props.markers.map((marker, index) => (
				<Marker
					key={index}
					position={marker.position}
					draggable={true && marker.draggable}
					onClick={() => props.onMarkerClick(marker)}
					// onDragEnd={coord => console.log("coord: " + JSON.stringify(coord))}
					onDragEnd={coord => props.onMarkerDragEnd(coord, marker)}
					options={
						marker.icon
							? {
									icon: marker.icon
							  }
							: {}
					}
				>
					{/*
           Show info window only if the 'showInfo' key of the marker is true.
           That is, when the Marker pin has been clicked and 'onCloseClick' has been
           Successfully fired.

        */}
					{marker.showInfo &&
						marker.name &&
						(marker.pinOnly === null ||
							marker.pinOnly === undefined ||
							!marker.pinOnly) && (
							<InfoWindow onCloseClick={() => props.onMarkerClose(marker)}>
								{/* <GoogleMapMarkerInfoWindow infoContent={marker.infoContent} /> */}
								<Button
									color="blue"
									size="sm"
									simple
									className={props.classes.marginRight}
									onClick={e => {
										if (props.addProp) {
											props.dispatch({
												type: props.addProp,
												value: marker
											});
										}
									}}
								>
									{marker.name}
								</Button>
							</InfoWindow>
						)}
				</Marker>
			))}

			{props.center && (
				<Circle
					center={props.center}
					radius={props.radius}
					options={{
						fillColor: "red",
						fillOpacity: 0.2,
						strokeColor: "red",
						strokeOpacity: 1,
						strokeWeight: 1
					}}
				/>
			)}
		</GoogleMap>
	))
);

export default GoogleMapComponent;
