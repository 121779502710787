import {
	FETCH_GET_GOOD_DEAL_DETAILS,
	FETCH_GET_GOOD_DEALS_CATEGORIES,
	SET_GOOD_DEALS_CATEGORIES,
	SET_GOOD_DEALS_CATEGORIES_FAILURE,
	FETCH_ADD_GOOD_DEAL,
	SET_ADD_GOOD_DEAL_RESULT,
	SET_ADD_GOOD_DEAL_ERROR,
	FETCH_UPDATE_GOOD_DEAL,
	SET_UPDATE_GOOD_DEAL_RESULT,
	SET_UPDATE_GOOD_DEAL_ERROR,
	FETCH_GET_OWN_GOOD_DEALS,
	SET_OWN_GOOD_DEAL_RESULT,
	SET_OWN_GOOD_DEAL_FAILURE,
	SET_GET_GOOD_DEAL_DETAILS_RESULT,
	SET_GET_GOOD_DEAL_DETAILS_FAILURE,
	FETCH_DELETE_GOOD_DEAL,
	SET_DELETE_GOOD_DEAL_RESULT,
	SET_DELETE_GOOD_DEAL_FAILURE,
	FETCH_PAY_GOOD_DEAL,
	SET_FETCH_PAY_GOOD_DEAL_RESULT,
	SET_FETCH_PAY_GOOD_DEAL_FAILURE,
	FETCH_GET_OWN_GOOD_DEALS_TITLES,
	SET_OWN_GOOD_DEAL_TITLES_RESULT,
	FETCH_PUBLISH_GOOD_DEAL,
	SET_PUBLISH_GOOD_DEAL_RESULT,
	SET_PUBLISH_GOOD_DEAL_FAILURE,
	FETCH_MODERATING_GOOD_DEAL_ACCEPT,
	SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT,
	SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE,
	FETCH_MODERATING_GOOD_DEAL_REFUSE,
	SET_MODERATING_GOOD_DEAL_REFUSE_RESULT,
	SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE,
	FETCH_GET_HOME_GOOD_DEALS,
	SET_HOME_GOODDEALS_RESULT,
	SET_HOME_GOOD_DEALS_FAILURE,
	FETCH_GET_GOOD_DEAL_CHANNELS,
	SET_GOOD_DEAL_CHANNELS_RESULT,
	SET_GET_GOOD_DEAL_CHANNELS_FAILURE
} from "./types";
import { schema } from "normalizr";
import { apiAction } from "./api";

const goodDealsCategories = new schema.Entity(
	"goodDealsCategories",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicitychannel = new schema.Entity(
	"hapicity_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const ocampingcitychannel = new schema.Entity(
	"ocamping_city_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const ocampingchannel = new schema.Entity(
	"ocamping_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicolibricitychannel = new schema.Entity(
	"hapicolibri_city_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicolibrichannel = new schema.Entity(
	"hapicolibri_channels",
	{},
	{
		idAttribute: "_id"
	}
);

export const ad = new schema.Entity("ad", {
	HapiCity: [hapicitychannel],
	OCamping_City: [ocampingcitychannel],
	OCamping: [ocampingchannel],
	HapiColibri_City: [hapicolibricitychannel],
	HapiColibri: [hapicolibrichannel]
});

const goodDeals = new schema.Entity(
	"goodDeals",
	{},
	{
		idAttribute: "_id"
	}
);

const infospage = new schema.Entity("infospages", {
	docs: [goodDeals]
});

export function fetchGetGoodDealsCategories() {
	return apiAction({
		url: "/gooddeals/categories",
		method: "GET",
		schema: [goodDealsCategories],
		onSuccess: setGoodDealsCategories,
		onFailure: setGoodDealsCategoriesFailure,
		label: FETCH_GET_GOOD_DEALS_CATEGORIES
	});
}

function setGoodDealsCategories(normalized) {
	return {
		type: SET_GOOD_DEALS_CATEGORIES,
		entities: normalized.entities
	};
}

function setGoodDealsCategoriesFailure(error) {
	return {
		type: SET_GOOD_DEALS_CATEGORIES_FAILURE,
		payload: error
	};
}

export function fetchGetOwnGoodDeal(page = 1, pageSize = 3, sorted = []) {
	return apiAction({
		url:
			"/gooddeals/own?page=" +
			page +
			"&pageSize=" +
			pageSize +
			(sorted.length > 0
				? "&sorted=" +
				  sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
				: ""),
		method: "GET",
		schema: infospage,
		onSuccess: setOwnGoodDeals,
		onFailure: setOwnGoodDealsFailure,
		label: FETCH_GET_OWN_GOOD_DEALS
	});
}

function setOwnGoodDeals(normalized) {
	return {
		type: SET_OWN_GOOD_DEAL_RESULT,
		entities: normalized.entities
	};
}

export function fetchGetOwnGoodDealsTitles() {
	return apiAction({
		url: "/gooddeals/own/titles",
		method: "GET",
		schema: [goodDeals],
		onSuccess: setOwnGoodDealsTitles,
		onFailure: setOwnGoodDealsFailure,
		label: FETCH_GET_OWN_GOOD_DEALS_TITLES
	});
}

function setOwnGoodDealsTitles(normalized) {
	return {
		type: SET_OWN_GOOD_DEAL_TITLES_RESULT,
		entities: normalized.entities
	};
}

function setOwnGoodDealsFailure(error) {
	return {
		type: SET_OWN_GOOD_DEAL_FAILURE,
		payload: error
	};
}

export function fetchAddGoodDeal(gooddeal) {
	return apiAction({
		url: "/gooddeals",
		method: "POST",
		data: gooddeal,
		onSuccess: setAddGoodDealResult,
		onFailure: setAddGoodDealFailure,
		label: FETCH_ADD_GOOD_DEAL
	});
}

function setAddGoodDealResult(data) {
	return {
		type: SET_ADD_GOOD_DEAL_RESULT,
		payload: data
	};
}

function setAddGoodDealFailure(error) {
	return {
		type: SET_ADD_GOOD_DEAL_ERROR,
		payload: error
	};
}

export function fetchPublishGoodDeal(goodDealId, channelsIds) {
	return apiAction({
		url: "/gooddeals/own/" + goodDealId,
		method: "POST",
		data: { channelsIds },
		onSuccess: setPublishGoodDealResult,
		onFailure: setPublishGoodDealFailure,
		label: FETCH_PUBLISH_GOOD_DEAL
	});
}

function setPublishGoodDealResult(data) {
	return {
		type: SET_PUBLISH_GOOD_DEAL_RESULT,
		payload: data
	};
}

function setPublishGoodDealFailure(error) {
	return {
		type: SET_PUBLISH_GOOD_DEAL_FAILURE,
		payload: error
	};
}

export function fetchModeratingAccept(goodDealId) {
	return apiAction({
		url: "/gooddeals/moderating/" + goodDealId,
		method: "POST",
		onSuccess: setModeratingGoodDealAcceptResult,
		onFailure: setModeratingGoodDealAcceptFailure,
		label: FETCH_MODERATING_GOOD_DEAL_ACCEPT
	});
}

function setModeratingGoodDealAcceptResult(data) {
	return {
		type: SET_MODERATING_GOOD_DEAL_ACCEPT_RESULT,
		payload: data
	};
}

function setModeratingGoodDealAcceptFailure(error) {
	return {
		type: SET_MODERATING_GOOD_DEAL_ACCEPT_FAILURE,
		payload: error
	};
}

export function fetchModeratingRefuse(goodDealId) {
	return apiAction({
		url: "/gooddeals/moderating/" + goodDealId,
		method: "POST",
		data: {
			isAccepted: false
		},
		onSuccess: setModeratingGoodDealRefuseResult,
		onFailure: setModeratingGoodDealRefuseFailure,
		label: FETCH_MODERATING_GOOD_DEAL_REFUSE
	});
}

function setModeratingGoodDealRefuseResult(data) {
	return {
		type: SET_MODERATING_GOOD_DEAL_REFUSE_RESULT,
		payload: data
	};
}

function setModeratingGoodDealRefuseFailure(error) {
	return {
		type: SET_MODERATING_GOOD_DEAL_REFUSE_FAILURE,
		payload: error
	};
}

export function fetchUpdateGoodDeal(gooddeal) {
	return apiAction({
		url: "/gooddeals",
		method: "PUT",
		data: gooddeal,
		onSuccess: setUpdateGoodDealResult,
		onFailure: setUpdateGoodDealFailure,
		label: FETCH_UPDATE_GOOD_DEAL
	});
}

function setUpdateGoodDealResult(data) {
	return {
		type: SET_UPDATE_GOOD_DEAL_RESULT,
		payload: data
	};
}

function setUpdateGoodDealFailure(error) {
	return {
		type: SET_UPDATE_GOOD_DEAL_ERROR,
		payload: error
	};
}

export function fetchGetGoodDealDetails(id) {
	return apiAction({
		url: "/gooddeals/own/" + id,
		method: "GET",
		onSuccess: setGetGoodDealDetailsResult,
		onFailure: setGetGoodDealDetailsFailure,
		label: FETCH_GET_GOOD_DEAL_DETAILS
	});
}

function setGetGoodDealDetailsResult(data) {
	return {
		type: SET_GET_GOOD_DEAL_DETAILS_RESULT,
		payload: data
	};
}

function setGetGoodDealDetailsFailure(data) {
	return {
		type: SET_GET_GOOD_DEAL_DETAILS_FAILURE,
		payload: data
	};
}

export function fetchGetGoodDealChannels(id) {
	return apiAction({
		url: "/gooddeals/own/" + id + "/channels",
		method: "GET",
		schema: ad,
		onSuccess: setGetGoodDealChannelsResult,
		onFailure: setGetGoodDealChannelsFailure,
		label: FETCH_GET_GOOD_DEAL_CHANNELS
	});
}

function setGetGoodDealChannelsResult(normalized) {
	return {
		type: SET_GOOD_DEAL_CHANNELS_RESULT,
		entities: normalized.entities
	};
}

function setGetGoodDealChannelsFailure(error) {
	return {
		type: SET_GET_GOOD_DEAL_CHANNELS_FAILURE,
		error
	};
}

export function fetchDeleteGoodDeal(id) {
	return apiAction({
		url: "/gooddeals/own/" + id,
		method: "DELETE",
		onSuccess: setDeleteGoodDealResult,
		onFailure: setDeleteGoodDealFailure,
		label: FETCH_DELETE_GOOD_DEAL
	});
}

function setDeleteGoodDealResult(data) {
	return {
		type: SET_DELETE_GOOD_DEAL_RESULT,
		payload: data
	};
}

function setDeleteGoodDealFailure(data) {
	return {
		type: SET_DELETE_GOOD_DEAL_FAILURE,
		payload: data
	};
}

export function fetchPayGoodDeal({ token }) {
	return apiAction({
		url: "/gooddeals/pay",
		data: { token },
		method: "POST",
		onSuccess: setFetchPayGoodDealResult,
		onFailure: setFetchPayGoodDealFailure,
		label: FETCH_PAY_GOOD_DEAL
	});
}

function setFetchPayGoodDealResult(data) {
	return {
		type: SET_FETCH_PAY_GOOD_DEAL_RESULT,
		payload: data
	};
}

function setFetchPayGoodDealFailure(data) {
	return {
		type: SET_FETCH_PAY_GOOD_DEAL_FAILURE,
		payload: data
	};
}

export function fetchGetHomeGoodDeals(page = 1, pageSize = 3, sorted = []) {
	return apiAction({
		url:
			"/gooddeals/home?page=" +
			page +
			"&pageSize=" +
			pageSize +
			(sorted.length > 0
				? "&sorted=" +
				  sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
				: ""),
		method: "GET",
		schema: infospage,
		onSuccess: setHomeGoodDealsResult,
		onFailure: setHomeGoodDealsFailure,
		label: FETCH_GET_HOME_GOOD_DEALS
	});
}

function setHomeGoodDealsResult(normalized) {
	return {
		type: SET_HOME_GOODDEALS_RESULT,
		entities: normalized.entities
	};
}

function setHomeGoodDealsFailure(error) {
	return {
		type: SET_HOME_GOOD_DEALS_FAILURE,
		error
	};
}
