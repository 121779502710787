import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
// import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routesAdvertiser from "routesPro";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchGetUser } from "actions/profile";
import { fetchLogout } from "actions/login";
import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle";
import { ROLE_ADVERTISER, RESET_DATA, ROLE_WEBMASTER } from "actions/types";

var ps;

class Dashboard extends React.Component {
	state = {
		mobileOpen: false,
		miniActive: false,
		image: require("assets/img/sidebar-2.jpg").default,
		color: "primary",
		bgColor: "black",
		hasImage: true,
		fixedClasses: "dropdown",
		logo: require("assets/img/logo-rounded.png").default
	};
	mainPanel = React.createRef();

	componentDidMount() {
		const { fetchGetUser, user } = this.props;

		if (!user) {
			fetchGetUser(true, true);
		}

		if (navigator.platform.indexOf("Win") > -1) {
			ps = new PerfectScrollbar(this.mainPanel.current, {
				suppressScrollX: true,
				suppressScrollY: false
			});
			document.body.style.overflow = "hidden";
		}
		window.addEventListener("resize", this.resizeFunction);
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			ps.destroy();
		}
		window.removeEventListener("resize", this.resizeFunction);
	}

	async componentDidUpdate(prevProps, prevState) {
		const { dispatch, history, logoutResult } = this.props;

		if (prevProps.history.location.pathname !== prevProps.location.pathname) {
			this.mainPanel.current.scrollTop = 0;
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false });
			}
		}

		if (logoutResult && prevProps.logoutResult !== logoutResult) {
			dispatch({
				type: RESET_DATA
			});

			history.push("/");
		}
	}

	handleImageClick = image => {
		this.setState({ image: image });
	};

	handleColorClick = color => {
		this.setState({ color: color });
	};

	handleBgColorClick = bgColor => {
		switch (bgColor) {
			case "white":
				this.setState({ logo: require("assets/img/logo.svg") });
				break;
			default:
				this.setState({ logo: require("assets/img/logo-white.svg") });
				break;
		}
		this.setState({ bgColor: bgColor });
	};

	handleFixedClick = () => {
		if (this.state.fixedClasses === "dropdown") {
			this.setState({ fixedClasses: "dropdown show" });
		} else {
			this.setState({ fixedClasses: "dropdown" });
		}
	};

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	getRoute = () => {
		return window.location.pathname !== "/admin/full-screen-maps";
	};

	getActiveRoute = routes => {
		let activeRoute = "Default Brand Text";
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views);
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute;
				}
			} else {
				if (
					window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
				) {
					return routes[i].name;
				}
			}
		}
		return activeRoute;
	};

	getRoutes = routes => {
		return routes.map((prop, key) => {
			const { user, fetchLogout } = this.props;
			if (prop.collapse) {
				return this.getRoutes(prop.views);
			}
			if (user) {
				if (
					prop.layout === "/pro" &&
					(user.role === ROLE_ADVERTISER ||
						user.role === ROLE_WEBMASTER ||
						!user.role)
				) {
					return (
						<Route
							path={prop.layout + prop.path}
							component={prop.component}
							key={key}
						/>
					);
				} else {
					fetchLogout();
					return null;
				}
			} else {
				return null;
			}
		});
	};

	sidebarMinimize = () => {
		this.setState({ miniActive: !this.state.miniActive });
	};

	resizeFunction = () => {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false });
		}
	};

	render() {
		const { classes, ...rest } = this.props;
		const mainPanel =
			classes.mainPanel +
			" " +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf("Win") > -1
			});

		let myRoutes = routesAdvertiser;

		return (
			<div className={classes.wrapper}>
				<Sidebar
					routes={myRoutes}
					logoText={"DigiHapi Pro"}
					logo={this.state.logo}
					image={this.state.image}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color={this.state.color}
					bgColor={this.state.bgColor}
					miniActive={this.state.miniActive}
					{...rest}
				/>
				<div className={mainPanel} ref={this.mainPanel}>
					<AdminNavbar
						sidebarMinimize={this.sidebarMinimize.bind(this)}
						miniActive={this.state.miniActive}
						brandText={this.getActiveRoute(myRoutes)}
						handleDrawerToggle={this.handleDrawerToggle}
						{...rest}
					/>
					{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
					{this.getRoute() ? (
						<div className={classes.content}>
							<div className={classes.container}>
								<Switch>
									{this.getRoutes(myRoutes)}
									{this.getActiveRoute(myRoutes) === "Default Brand Text" && (
										<Redirect from="/pro" to="/pro/annonces" />
									)}
								</Switch>
							</div>
						</div>
					) : (
						<div className={classes.map}>
							<Switch>
								{this.getRoutes(myRoutes)}
								<Redirect from="/pro" to="/pro/annonces" />
							</Switch>
						</div>
					)}
					{this.getRoute() ? <Footer fluid /> : null}
				</div>
			</div>
		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = state => {
	const { isFetching, user } = state.profileReducer;
	const { logoutResult } = state.loginReducer;

	return {
		isFetching,
		user,
		logoutResult
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchGetUser,
			fetchLogout
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(appStyle)(Dashboard));
