import {
	API_END,
	API_START,
	SET_POST_FILES_IMAGES,
	FETCH_PUBLISH_EVENT,
	REMOVE_POST_IMAGE,
	SET_PUBLISH_EVENT_RESULT,
	SET_POST_URL,
	SET_POST_DATE,
	SET_POST_DESCRIPTION_FR,
	SET_POST_DESCRIPTION_EN,
	CLEAR_POST_URL,
	FETCH_GET_OWN_EVENTS,
	SET_OWN_EVENTS_RESULT,
	SET_DELETE_EVENT_RESULT,
	SET_DELETE_EVENT_FAILURE,
	FETCH_DELETE_EVENT,
	SET_POST_DESCRIPTION_ERROR,
	SET_POST_URL_ERROR,
	ADD_POST_CAMPSITE,
	DELETE_POST_CAMPSITE,
	MISSING_POST_CAMPSITE,
	SET_PUBLISH_EVENT_FAILURE,
	SET_POST_GOODDEAL,
	SET_POST_SORTED,
	RESET_EVENT,
	FETCH_GET_OWN_GOOD_DEALS_TITLES,
	SET_OWN_GOOD_DEAL_TITLES_RESULT,
	SET_POST_TITLE_FR,
	SET_POST_TITLE_EN,
	SET_GET_EVENT_RESULT,
	FETCH_GET_EVENT,
	FETCH_ADD_EVENT,
	SET_ADD_EVENT_FAILURE,
	SET_ADD_EVENT_RESULT,
	FETCH_UPDATE_EVENT,
	SET_UPDATE_EVENT_RESULT,
	SET_UPDATE_EVENT_FAILURE,
	FETCH_MODERATING_EVENT_ACCEPT,
	FETCH_MODERATING_EVENT_REFUSE,
	SET_MODERATING_EVENT_ACCEPT_RESULT,
	SET_MODERATING_EVENT_ACCEPT_FAILURE,
	SET_MODERATING_EVENT_REFUSE_RESULT,
	SET_MODERATING_EVENT_REFUSE_FAILURE,
	FETCH_GET_HOME_EVENTS,
	SET_HOME_EVENTS_RESULT,
	SET_POST_TITLE_ERROR,
	SET_POST_TYPE,
	SET_POST_TYPE_ERROR,
	SET_POST_GOODDEAL_ERROR,
	SET_POST_PROMOTION_FR,
	SET_POST_START_DATE_ERROR,
	SET_POST_PROMOTION_ERROR,
	SET_POST_PROMOTION_EN,
	SET_GET_EVENT_CHANNELS_RESULT,
	PUBLISHED,
	FETCH_GET_EVENT_CHANNELS,
	ADD_WIZARD,
	SET_PROFILE_DETAILS,
	CLEAR_SNACK_WARN_MSG,
	SET_CITIES,
	ADD_POST_CITY,
	DELETE_POST_CITY,
	MISSING_POST_CITY,
	SET_EVENT_TYPES,
	MISSING_POST_CHANNEL,
	ROLE_WEBMASTER,
	SET_EVENT_CITY_CHANNELS,
	ADD_POST_CITY_CHANNEL,
	DELETE_POST_CITY_CHANNEL,
	SET_EVENT_CAMPSITE_CITY_CHANNELS,
	ADD_POST_CAMPSITE_CITY_CHANNEL,
	DELETE_POST_CAMPSITE_CITY_CHANNEL,
	SET_EVENT_CAMPSITE_CHANNELS,
	ADD_POST_CAMPSITE_CHANNEL,
	DELETE_POST_CAMPSITE_CHANNEL,
	SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS,
	SET_EVENT_NEIGHBORHOOD_CHANNELS,
	ADD_POST_NEIGHBORHOOD_CHANNEL,
	DELETE_POST_NEIGHBORHOOD_CHANNEL,
	ADD_POST_NEIGHBORHOOD_CITY_CHANNEL,
	DELETE_POST_NEIGHBORHOOD_CITY_CHANNEL
} from "../actions/types";
import { t } from "../services/i18n";
import moment from "moment";

const initialState = {
	isFetching: false,
	_id: null,
	url: null,
	title: null,
	titleErrorMsg: null,
	description: null,
	descriptionErrorMsg: null,
	urlState: null,
	startDate: null,
	lang: null,
	files: [],
	events: {},
	snackErrorMsg: null,
	snackWarnMsg: null,
	snackInfoMsg: null,
	urlErrorMsg: null,
	campsites: [],
	campsitesIds: [],
	campsitesEntities: [],
	cities: [],
	citiesIds: [],
	citiesEntities: [],
	suggestions: [],
	newWizard: null,
	campsiteErrorMsg: null,
	cityErrorMsg: null,
	goodDealsTitles: {},
	goodDealId: null,
	photoUri: null,
	page: 1,
	limit: 6,
	hasNextPage: false,
	nextPage: 2,
	totalPages: 0,
	totalDocs: 0,
	sorted: [{ id: "dateUpdated", desc: true }],
	eventTypeId: null,
	typeErrorMsg: null,
	isGoodDealType: false,
	goodDealErrorMsg: null,
	promotion: null,
	isDateMandatory: false,
	isPromotionType: false,
	startDateErrorMsg: null,
	promotionErrorMsg: null,
	warnMsgCleared: false,
	company: null,
	eventTypes: {},
	channels: {},
	publishedChannelErrorMsg: null,
	role: null,
	hapicity_channels: {},
	ocamping_city_channels: {},
	ocamping_channels: {},
	hapicolibri_city_channels: {},
	hapicolibri_channels: {},
	hapicity_channels_suggestions: {},
	ocamping_city_channels_suggestions: {},
	ocamping_channels_suggestions: {},
	hapicolibri_city_channels_suggestions: {},
	hapicolibri_channels_suggestions: {},
	channelsIds: []
};

// verifies if value is a valid URL
const verifyUrl = value => {
	try {
		new URL(value);
		return true;
	} catch (_) {
		return false;
	}
};

function keepState(state) {
	const suggestions = [...state.suggestions];
	// eslint-disable-next-line
	for (const suggestion of suggestions) {
		suggestion.showInfo = true;
	}
	return {
		...state,
		isFetching: state.isFetching,
		campsitesEntities: state.campsitesEntities,
		citiesEntities: state.citiesEntities,
		suggestions: suggestions,
		events: state.events,
		goodDealsTitles: state.goodDealsTitles,
		company: state.company,
		page: state.page,
		limit: state.limit,
		hasNextPage: state.hasNextPage,
		totalPages: state.totalPages,
		totalDocs: state.totalDocs
	};
}

export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_GET_OWN_EVENTS ||
				action.payload === FETCH_GET_HOME_EVENTS
			) {
				return {
					...state,
					isFetchingList: true,
					events: {}
				};
			}
			if (action.payload === FETCH_GET_OWN_GOOD_DEALS_TITLES) {
				return {
					...state,
					isFetching: true,
					goodDealsTitles: {}
				};
			}
			if (
				action.payload === FETCH_DELETE_EVENT ||
				action.payload === FETCH_GET_EVENT ||
				action.payload === FETCH_GET_EVENT_CHANNELS ||
				action.payload === FETCH_ADD_EVENT ||
				action.payload === FETCH_UPDATE_EVENT ||
				action.payload === FETCH_PUBLISH_EVENT ||
				action.payload === FETCH_MODERATING_EVENT_ACCEPT ||
				action.payload === FETCH_MODERATING_EVENT_REFUSE
			) {
				return {
					...state,
					isFetching: true,
					newWizard: null,
					snackErrorMsg: null,
					snackInfoMsg: null
				};
			}
			break;

		case ADD_POST_CITY_CHANNEL:
			return {
				...state,
				hapicity_channels: {
					...state.hapicity_channels,
					[action.value._id]: action.value
				},
				channelsIds: [...state.channelsIds, action.value._id]
			};

		case ADD_POST_CAMPSITE_CITY_CHANNEL: {
			const channel = action.value;
			return {
				...state,
				ocamping_city_channels: {
					...state.ocamping_city_channels,
					[action.value._id]: channel
				},
				channelsIds: [...state.channelsIds, channel._id]
			};
		}

		case ADD_POST_NEIGHBORHOOD_CITY_CHANNEL: {
			const channel = action.value;
			return {
				...state,
				hapicolibri_city_channels: {
					...state.hapicolibri_city_channels,
					[action.value._id]: channel
				},
				channelsIds: [...state.channelsIds, channel._id]
			};
		}

		case ADD_POST_CAMPSITE_CHANNEL:
			return {
				...state,
				ocamping_channels: {
					...state.ocamping_channels,
					[action.value._id]: action.value
				},
				channelsIds: [...state.channelsIds, action.value._id]
			};

		case ADD_POST_NEIGHBORHOOD_CHANNEL:
			return {
				...state,
				hapicolibri_channels: {
					...state.hapicolibri_channels,
					[action.value._id]: action.value
				},
				channelsIds: [...state.channelsIds, action.value._id]
			};

		case DELETE_POST_CITY_CHANNEL: {
			const hapicity_channels = { ...state.hapicity_channels };
			const id = Object.values(hapicity_channels)[action.index]._id;
			delete hapicity_channels[id];
			const channelsIds = [...state.channelsIds].filter(key => key !== id);
			return {
				...state,
				hapicity_channels,
				channelsIds
			};
		}

		case DELETE_POST_CAMPSITE_CITY_CHANNEL: {
			const ocamping_city_channels = { ...state.ocamping_city_channels };
			const id = Object.values(ocamping_city_channels)[action.index]._id;
			delete ocamping_city_channels[id];
			const channelsIds = [...state.channelsIds].filter(key => key !== id);
			return {
				...state,
				ocamping_city_channels,
				channelsIds
			};
		}

		case DELETE_POST_NEIGHBORHOOD_CITY_CHANNEL: {
			const hapicolibri_city_channels = { ...state.hapicolibri_city_channels };
			const id = Object.values(hapicolibri_city_channels)[action.index]._id;
			delete hapicolibri_city_channels[id];
			const channelsIds = [...state.channelsIds].filter(key => key !== id);
			return {
				...state,
				hapicolibri_city_channels,
				channelsIds
			};
		}

		case DELETE_POST_CAMPSITE_CHANNEL: {
			const ocamping_channels = { ...state.ocamping_channels };
			const id = Object.values(ocamping_channels)[action.index]._id;
			delete ocamping_channels[id];
			const channelsIds = [...state.channelsIds].filter(key => key !== id);
			return {
				...state,
				ocamping_channels,
				channelsIds
			};
		}

		case DELETE_POST_NEIGHBORHOOD_CHANNEL: {
			const hapicolibri_channels = { ...state.hapicolibri_channels };
			const id = Object.values(hapicolibri_channels)[action.index]._id;
			delete hapicolibri_channels[id];
			const channelsIds = [...state.channelsIds].filter(key => key !== id);
			return {
				...state,
				hapicolibri_channels,
				channelsIds
			};
		}

		case CLEAR_SNACK_WARN_MSG:
			return {
				...state,
				warnMsgCleared: true,
				snackWarnMsg: null
			};

		case ADD_WIZARD:
			if (!state.company && state.role !== ROLE_WEBMASTER) {
				return {
					...state,
					snackWarnMsg: t("event:completeprofile"),
					warnMsgCleared: false
				};
			}
			return { ...state, newWizard: "add", warnMsgCleared: false };

		case SET_PROFILE_DETAILS: {
			const { role, company } = action.payload;

			return { ...state, company, role };
		}

		case RESET_EVENT:
			return {
				...keepState(state)
			};

		case SET_GET_EVENT_CHANNELS_RESULT: {
			const {
				event,
				hapicity_channels = {},
				ocamping_city_channels = {},
				ocamping_channels = {},
				hapicolibri_city_channels = {},
				hapicolibri_channels = {}
			} = action.entities;

			Object.values(ocamping_city_channels).map(
				obj => (obj.name += `(${obj.subscribers})`)
			);
			Object.values(hapicolibri_city_channels).map(
				obj => (obj.name += `(${obj.subscribers})`)
			);

			return {
				...state,
				_id: Object.keys(event)[0],
				channelsIds: Object.values(event)[0].channelsIds,
				newWizard: "publish",
				hapicity_channels,
				ocamping_city_channels,
				ocamping_channels,
				hapicolibri_city_channels,
				hapicolibri_channels
			};
		}

		case SET_POST_TYPE:
			return {
				...keepState(state),
				eventTypeId: action.payload._id,
				isGoodDealType: action.payload.name === "Annonce",
				isPromotionType: action.payload.name === "Promotion",
				isDateMandatory:
					action.payload.name === "Évènement" ||
					action.payload.name === "Spectacle" ||
					action.payload.name === "Concert" ||
					action.payload.name === "Exposition"
			};

		case SET_EVENT_TYPES:
			return { ...state, eventTypes: action.entities.eventTypes };

		case SET_GET_EVENT_RESULT: {
			const {
				_id,
				url,
				title,
				description,
				lang,
				goodDealId,
				startDate,
				photoUri,
				eventTypeId,
				promotion
			} = action.payload;

			return {
				...state,
				_id,
				url,
				title,
				description,
				lang,
				goodDealId,
				startDate: startDate ? moment(startDate) : null,
				photoUri,
				eventTypeId,
				promotion,
				newWizard: "update",
				isGoodDealType: state.eventTypes[eventTypeId].name === "Annonce",
				isPromotionType: state.eventTypes[eventTypeId].name === "Promotion",
				isDateMandatory:
					state.eventTypes[eventTypeId].name === "Évènement" ||
					state.eventTypes[eventTypeId].name === "Spectacle" ||
					state.eventTypes[eventTypeId].name === "Concert" ||
					state.eventTypes[eventTypeId].name === "Exposition"
			};
		}

		case SET_EVENT_CITY_CHANNELS:
			const { channels: hapicity_channels_suggestions = {} } = action.entities;
			for (const id of Object.keys(state.hapicity_channels)) {
				delete hapicity_channels_suggestions[id];
			}
			return { ...state, hapicity_channels_suggestions };

		case SET_EVENT_CAMPSITE_CITY_CHANNELS:
			const { channels: ocamping_city_channels_suggestions = {} } =
				action.entities;
			for (const id of Object.keys(state.ocamping_city_channels)) {
				delete ocamping_city_channels_suggestions[id];
			}
			for (const channel of Object.values(ocamping_city_channels_suggestions)) {
				channel.name += `(${channel.subscribers})`;
			}
			return { ...state, ocamping_city_channels_suggestions };

		case SET_EVENT_NEIGHBORHOOD_CITY_CHANNELS:
			const { channels: hapicolibri_city_channels_suggestions = {} } =
				action.entities;
			for (const id of Object.keys(state.hapicolibri_city_channels)) {
				delete hapicolibri_city_channels_suggestions[id];
			}
			for (const channel of Object.values(
				hapicolibri_city_channels_suggestions
			)) {
				channel.name += `(${channel.subscribers})`;
			}
			return { ...state, hapicolibri_city_channels_suggestions };

		case SET_EVENT_CAMPSITE_CHANNELS:
			const { channels: ocamping_channels_suggestions = {} } = action.entities;
			for (const id of Object.keys(state.ocamping_channels)) {
				delete ocamping_channels_suggestions[id];
			}
			return { ...state, ocamping_channels_suggestions };

		case SET_EVENT_NEIGHBORHOOD_CHANNELS:
			const { channels: hapicolibri_channels_suggestions = {} } =
				action.entities;
			for (const id of Object.keys(state.hapicolibri_channels)) {
				delete hapicolibri_channels_suggestions[id];
			}
			return { ...state, hapicolibri_channels_suggestions };

		case SET_OWN_GOOD_DEAL_TITLES_RESULT:
			return { ...state, goodDealsTitles: action.entities.goodDeals };

		// case SET_CAMPSITES: {
		//   return { ...state,
		//
		// suggestions: [
		//       ...state.suggestions,
		//       ... Object.values(action.entities.campsites).map(obj => ({
		//         position: { lat: obj.latitude, lng: obj.longitude },
		//         showInfo: true,
		//         name: obj.name,
		//         id: obj._id,
		//         icon: obj.subscribe
		//           ? "https://mt.google.com/vt/icon/name=icons/spotlight/camping_v_L_8x.png&scale=1.2"
		//           : "https://mt.google.com/vt/icon/name=icons/spotlight/camping_search_v_L_8x.png&scale=1.2"
		//       }))
		//     ])
		//
		// campsitesEntities: action.entities.campsites);
		// }

		case SET_CITIES:
			return {
				...state,
				suggestions: Object.values(action.entities.cities).map(obj => ({
					position: { lat: obj.latitude, lng: obj.longitude },
					showInfo: true,
					name: obj.name,
					id: obj._id,
					icon: obj.subscribe
						? "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_v_L_8x.png&scale=1.2"
						: "https://mt.google.com/vt/icon/name=icons/spotlight/civic_building_search_v_L_8x.png&scale=1.2"
				})),
				citiesEntities: action.entities.cities
			};

		case ADD_POST_CAMPSITE: {
			const suggestions = [
				...state.suggestions.filter(obj => obj.id !== action.value.id)
			];
			const campsite = {
				...state.suggestions.filter(obj => obj.id === action.value.id)[0]
			};
			campsite.showInfo = false;
			suggestions.push(campsite);
			if (
				action.value.position &&
				!state.campsitesIds.includes(action.value.id)
			) {
				return {
					...state,
					campsites: [...state.campsites, action.value],
					campsitesIds: [...state.campsitesIds, action.value.id],
					suggestions,
					campsiteErrorMsg: null
				};
			} else {
				return { ...state, suggestions, campsiteErrorMsg: null };
			}
		}

		case ADD_POST_CITY: {
			const suggestions = [
				...state.suggestions.filter(obj => obj.id !== action.value.id)
			];
			const city = {
				...state.suggestions.filter(obj => obj.id === action.value.id)[0]
			};
			city.showInfo = false;
			suggestions.push(city);
			if (action.value.position && !state.citiesIds.includes(action.value.id)) {
				return {
					...state,
					cities: [...state.cities, action.value],
					citiesIds: [...state.citiesIds, action.value.id],
					suggestions,
					cityErrorMsg: null
				};
			} else {
				return { ...state, suggestions, cityErrorMsg: null };
			}
		}

		case DELETE_POST_CAMPSITE: {
			const suggestions = [
				...state.suggestions.filter(
					obj => obj.id !== state.campsitesIds[action.value]
				)
			];
			const campsite = { ...state.campsites[action.value] };
			campsite.showInfo = true;
			suggestions.push(campsite);
			return {
				...state,
				campsites: state.campsites.filter(
					(campsite, index) => index !== action.value
				),
				campsitesIds: state.campsitesIds.filter(
					(campsite, index) => index !== action.value
				),
				suggestions,
				campsiteErrorMsg: null
			};
		}

		case DELETE_POST_CITY: {
			const suggestions = [
				...state.suggestions.filter(
					obj => obj.id !== state.citiesIds[action.value]
				)
			];
			const city = { ...state.cities[action.value] };
			city.showInfo = true;
			suggestions.push(city);
			return {
				...state,
				cities: state.cities.filter((city, index) => index !== action.value),
				citiesIds: state.citiesIds.filter(
					(city, index) => index !== action.value
				),
				suggestions,
				cityErrorMsg: null
			};
		}

		case MISSING_POST_CAMPSITE:
			return { ...state, campsiteErrorMsg: t("event:missingcampsite") };

		case MISSING_POST_CITY:
			return { ...state, cityErrorMsg: t("event:missingcity") };

		case MISSING_POST_CHANNEL:
			return { ...state, publishedChannelErrorMsg: t("event:missingchannel") };

		case SET_HOME_EVENTS_RESULT:
		case SET_OWN_EVENTS_RESULT: {
			const { events, infospages } = action.entities;
			const { page, hasNextPage, totalPages, totalDocs, limit } =
				infospages["1"];
			return {
				...state,
				events: events ? events : {},
				page,
				limit,
				hasNextPage,
				totalPages,
				totalDocs
			};
		}

		case SET_POST_SORTED:
			return { ...state, sorted: action.value };

		case SET_MODERATING_EVENT_ACCEPT_RESULT:
			return { ...state, snackInfoMsg: t("event:acceptsuccess") };

		case SET_MODERATING_EVENT_ACCEPT_FAILURE:
			return { ...state, snackErrorMsg: t("event:accepterror") };

		case SET_MODERATING_EVENT_REFUSE_RESULT:
			return { ...state, snackInfoMsg: t("event:refusesuccess") };

		case SET_MODERATING_EVENT_REFUSE_FAILURE:
			return { ...state, snackErrorMsg: t("event:refuseerror") };

		case SET_DELETE_EVENT_RESULT:
			return { ...state, snackInfoMsg: t("event:deletesuccess") };

		case SET_DELETE_EVENT_FAILURE:
			return { ...state, snackErrorMsg: t("event:deleteerror") };

		case SET_POST_DESCRIPTION_ERROR:
			return { ...state, descriptionErrorMsg: t("event:descriptionmandatory") };

		case SET_POST_TYPE_ERROR:
			return { ...state, typeErrorMsg: t("event:typemandatory") };

		case SET_POST_TITLE_ERROR:
			return { ...state, titleErrorMsg: t("event:titlemandatory") };

		case SET_POST_GOODDEAL_ERROR:
			return { ...state, goodDealErrorMsg: t("event:gooddealmandatory") };

		case SET_POST_START_DATE_ERROR:
			return { ...state, startDateErrorMsg: t("event:datemandatory") };

		case SET_POST_PROMOTION_ERROR:
			return { ...state, promotionErrorMsg: t("event:promotionmandatory") };

		case SET_POST_DESCRIPTION_FR:
			return {
				...state,
				description: action.value,
				descriptionErrorMsg: null
			};

		case SET_POST_DESCRIPTION_EN: {
			const en = state.lang
				? { ...state.lang.en, description: action.value }
				: { description: action.value };
			return { ...state, lang: { en } };
		}

		case SET_POST_PROMOTION_EN: {
			const en = state.lang
				? { ...state.lang.en, promotion: action.value }
				: { promotion: action.value };
			return { ...state, lang: { en } };
		}

		case SET_POST_TITLE_FR:
			return { ...state, title: action.value, titleErrorMsg: null };

		case SET_POST_PROMOTION_FR:
			return { ...state, promotion: action.value };

		case SET_POST_TITLE_EN: {
			const en = state.lang
				? { ...state.lang.en, title: action.value }
				: { title: action.value };
			return { ...state, lang: { en } };
		}

		case SET_POST_FILES_IMAGES:
			return { ...state, files: action.payload, photoUri: null };

		case REMOVE_POST_IMAGE:
			return { ...state, files: [], photoUri: null };

		case SET_PUBLISH_EVENT_RESULT:
			return {
				...keepState(state),
				snackInfoMsg:
					action.payload.status === PUBLISHED
						? t("event:publishsuccess")
						: t("gooddeal:moderatesuccess")
			};

		case SET_ADD_EVENT_RESULT:
			return { ...keepState(state), snackInfoMsg: t("event:addsuccess") };

		case SET_UPDATE_EVENT_RESULT:
			return { ...keepState(state), snackInfoMsg: t("event:updatesuccess") };

		case SET_PUBLISH_EVENT_FAILURE:
			return { ...state, snackErrorMsg: t("event:publisherror") };

		case SET_ADD_EVENT_FAILURE:
			return { ...state, snackErrorMsg: t("event:adderror") };

		case SET_UPDATE_EVENT_FAILURE:
			return { ...state, snackErrorMsg: t("event:updateerror") };

		case SET_POST_URL:
			return {
				...state,
				url: action.payload,
				urlErrorMsg: null,
				urlState: verifyUrl(action.payload) ? "success" : "error",
				goodDealId: null
			};

		case SET_POST_GOODDEAL:
			return {
				...state,
				goodDealId: action.payload ? action.payload._id : null,
				title: action.payload ? action.payload.title : null,
				description: action.payload ? action.payload.description : null,
				lang: action.payload ? action.payload.lang : null,
				goodDealErrorMsg: null,
				photoUri: action.payload
					? state.goodDealsTitles[action.payload._id].photoUri
					: null
			};

		case SET_POST_URL_ERROR:
			return { ...state, urlErrorMsg: t("event:invalidurl") };

		case CLEAR_POST_URL:
			return {
				...state,
				urlErrorMsg: null,
				url: null,
				urlState: null
			};

		case SET_POST_DATE:
			return {
				...state,
				startDate:
					action.payload instanceof moment && !isNaN(action.payload)
						? action.payload
						: null,
				startDateErrorMsg: null
			};

		case API_END:
			if (
				action.payload === FETCH_PUBLISH_EVENT ||
				action.payload === FETCH_ADD_EVENT ||
				action.payload === FETCH_UPDATE_EVENT ||
				action.payload === FETCH_DELETE_EVENT ||
				action.payload === FETCH_GET_OWN_GOOD_DEALS_TITLES ||
				action.payload === FETCH_GET_EVENT ||
				action.payload === FETCH_GET_EVENT_CHANNELS ||
				action.payload === FETCH_MODERATING_EVENT_ACCEPT ||
				action.payload === FETCH_MODERATING_EVENT_REFUSE
			) {
				return { ...state, isFetching: false };
			}
			if (
				action.payload === FETCH_GET_HOME_EVENTS ||
				action.payload === FETCH_GET_OWN_EVENTS
			) {
				return { ...state, isFetchingList: false };
			}
			break;

		default:
			return state;
	}
	return state;
}
