import React, { useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";
import AccountBox from "@material-ui/icons/AccountBox";
import Apartment from "@material-ui/icons/Apartment";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter.js";
import Activity from "components/DigiHapi/Activity.js";

import { fetchRegisterProfessional } from "../../actions/login";
import {
	SET_EMAIL_OR_PSEUDO,
	CLEAR_REGISTER_VALIDATE,
	VALIDATE_REGISTER_INPUTS,
	ROLE_WEBMASTER,
	ROLE_ADVERTISER,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_GENDER,
	SET_COMPANY_NAME
} from "../../actions/types";

// Redux
import { useDispatch, useSelector } from "react-redux";

import registerStyles from "assets/jss/DigiHapi/components/registerPageStyle";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

import FullPageImage from "../../components/DigiHapi/FullPageImage";
import CustomFilledInput from "../../components/DigiHapi/CustomFilledInput";
import HomeHeader from "components/DigiHapi/HomeHeader";
import HomeFooter from "components/DigiHapi/HomeFooter";

const styles = theme => {
	return {
		...registerStyles(theme),
		...customCheckboxRadioSwitch,
		...commonStyles
	};
};

const useStyles = makeStyles(styles);

export default function ProRegisterPage(props) {
	const classes = useStyles();

	const { history } = props;

	const cNames = elts => {
		return classNames(elts.split(" ").map(elt => classes[elt]));
	};

	const dispatch = useDispatch();

	const {
		isFetching,
		emailOrPseudo,
		company,
		gender,
		firstname,
		lastname,
		emailOrPseudoErrorMsg,
		companyErrorMsg,
		firstnameErrorMsg,
		lastnameErrorMsg,
		validRegister,
		snackInfoMsg,
		snackErrorMsg,
		clearRegisterValidate
	} = useSelector(state => state.loginReducer);

	const { user } = useSelector(state => state.profileReducer);
	const error = emailOrPseudoErrorMsg || snackErrorMsg;

	useEffect(() => {
		if (clearRegisterValidate) {
			dispatch({
				type: VALIDATE_REGISTER_INPUTS
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearRegisterValidate]);

	useEffect(() => {
		if (validRegister) {
			dispatch(
				fetchRegisterProfessional({
					email: emailOrPseudo,
					gender,
					firstname,
					lastname,
					company
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validRegister]);

	useEffect(() => {
		if (!error && user) {
			if (user.role === ROLE_WEBMASTER || user.role === ROLE_ADVERTISER) {
				history.push("/pro/annonces");
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const [bc, setBc] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");

	useEffect(() => {
		if (snackInfoMsg || snackErrorMsg) {
			setBc(true);
			setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
			setColor(snackInfoMsg ? "info" : "danger");
			if (snackInfoMsg) {
				setTimeout(() => setBc(false), 5000);
			}
		}
	}, [snackInfoMsg, snackErrorMsg]);

	return (
		<FullPageImage
			bgImage="register"
			child={
				<GridContainer justify="center">
					<HomeHeader bgDarkGray />

					<GridItem xs={12} sm={12} md={6} lg={4}>
						<form
							onSubmit={event => {
								event.preventDefault();
								dispatch({
									type: CLEAR_REGISTER_VALIDATE
								});
							}}
						>
							<Card plain noaos className={cNames("bgWhite07 rounded10")}>
								<CardBody className={cNames("flex1 justifyContentCenter")}>
									<CustomFilledInput
										labelText="Email *"
										id="email"
										error={emailOrPseudoErrorMsg !== null}
										helperText={
											emailOrPseudoErrorMsg ? emailOrPseudoErrorMsg : undefined
										}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: emailOrPseudo ? emailOrPseudo : "",
											endAdornment: (
												<InputAdornment position="end">
													<Email className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_EMAIL_OR_PSEUDO,
													value: e.target.value
												})
										}}
									/>
									<CustomFilledInput
										labelText="Entreprise *"
										id="company"
										error={companyErrorMsg !== null}
										helperText={companyErrorMsg ? companyErrorMsg : undefined}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: company ? company : "",
											endAdornment: (
												<InputAdornment position="end">
													<Apartment className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_COMPANY_NAME,
													value: e.target.value
												})
										}}
									/>
									<div className={cNames("justifyContentCenter")}>
										<FormControlLabel
											control={
												<Radio
													checked={!gender || gender === "M"}
													onChange={e =>
														dispatch({
															type: SET_GENDER,
															value: e.target.value
														})
													}
													value="M"
													name="mister"
													disabled={isFetching}
													classes={{
														checked: classes.radio,
														root: classes.radioRoot
													}}
												/>
											}
											classes={{
												label: cNames("label black"),
												root: classes.labelRoot
											}}
											label="M."
										/>
										<FormControlLabel
											control={
												<Radio
													checked={gender && gender === "F"}
													onChange={e =>
														dispatch({
															type: SET_GENDER,
															value: e.target.value
														})
													}
													value="F"
													name="miss"
													disabled={isFetching}
												/>
											}
											classes={{
												label: cNames("label black"),
												root: classes.labelRoot
											}}
											label="Mme"
										/>
									</div>
									<CustomFilledInput
										labelText="Prénom *"
										id="firstname"
										error={firstnameErrorMsg !== null}
										helperText={
											firstnameErrorMsg ? firstnameErrorMsg : undefined
										}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: firstname ? firstname : "",
											endAdornment: (
												<InputAdornment position="end">
													<AccountBox className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_FIRST_NAME,
													value: e.target.value
												})
										}}
									/>
									<CustomFilledInput
										labelText="Nom *"
										id="lastname"
										error={lastnameErrorMsg !== null}
										helperText={lastnameErrorMsg ? lastnameErrorMsg : undefined}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: lastname ? lastname : "",
											endAdornment: (
												<InputAdornment position="end">
													<AccountBox className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_LAST_NAME,
													value: e.target.value
												})
										}}
									/>
									<Snackbar
										place="bc"
										color={color}
										icon={AddAlert}
										message={message ? message : ""}
										open={bc}
										closeNotification={() => setBc(false)}
										close
									/>
								</CardBody>
								<CardFooter className={cNames("justifyContentCenter column")}>
									<Button
										disabled={isFetching}
										className={cNames("h50")}
										type="submit"
										color="primary"
										size="lg"
										block
									>
										{isFetching ? <Activity /> : "S'ENREGISTRER"}
									</Button>
									<Button
										disabled={isFetching}
										className={cNames("underline fs16 textTransformNone black")}
										simple
										onClick={() => history.push("/")}
									>
										{"Déjà un compte"}
									</Button>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
					<HomeFooter bgDarkGray />
				</GridContainer>
			}
		/>
	);
}
