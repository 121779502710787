import React from "react";
import PropTypes from "prop-types";

import "moment/locale/fr";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch";

// material ui icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import PictureUpload from "components/CustomUpload/PictureUpload";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import CardIcon from "components/Card/CardIcon";
import CardBody from "components/Card/CardBody";
import Snackbar from "components/Snackbar/Snackbar";
import Activity from "components/DigiHapi/Activity";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUpdateProfile } from "actions/profile";

import {
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_GENDER,
	SET_COMPANY_NAME,
	SET_SIRET_CODE,
	SET_ZIP_CODE,
	SET_CITY,
	SET_ADDRESS,
	CLEAR_PROFILE_VALIDATE,
	VALIDATE_PROFILE_INPUTS,
	SET_COMPANY_PHONE,
	REMOVE_PROFILE_IMAGE,
	SET_PROFILE_FILES_IMAGES,
	SET_PROFILE_EDIT
} from "actions/types.js";
import { PhoneMask } from "utils/masks";

const style = {
	...loginPageStyle,
	...customCheckboxRadioSwitch,
	...commonStyles
};

class ProProfilePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			br: false,
			color: "info",
			message: null,
			focused: false
		};
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			fetchUpdateProfile,
			dispatch,
			photoUri,
			firstname,
			lastname,
			gender,
			company,
			companySiret,
			companyPhone,
			companyAddress,
			companyZipCode,
			companyCity,
			job,
			lang,
			clearValidate,
			isValid,
			snackErrorMsg,
			snackInfoMsg
		} = this.props;

		if (
			(snackErrorMsg && prevProps.snackErrorMsg !== snackErrorMsg) ||
			(snackInfoMsg && prevProps.snackInfoMsg !== snackInfoMsg)
		) {
			this.autoCloseMessage();
		}

		if (clearValidate && !prevProps.clearValidate) {
			dispatch({
				type: VALIDATE_PROFILE_INPUTS
			});
		}

		if (isValid && !prevProps.isValid) {
			fetchUpdateProfile({
				photoUri,
				gender,
				firstname,
				lastname,
				company,
				companySiret,
				companyPhone,
				companyAddress,
				companyZipCode,
				companyCity,
				job,
				lang
			});
		}
	}

	hideAlert = () => {
		this.setState({
			br: false
		});
	};

	autoCloseMessage = () => {
		const { snackErrorMsg, snackInfoMsg } = this.props;

		this.setState({
			br: true,
			message: snackInfoMsg ? snackInfoMsg : snackErrorMsg,
			color: snackInfoMsg ? "info" : "danger"
		});

		if (snackInfoMsg) {
			setTimeout(this.hideAlert, 5000);
		}
	};

	fetchUpdateProfile = e => {
		this.props.dispatch({
			type: CLEAR_PROFILE_VALIDATE
		});
	};

	onImageLoaded = (name, imagePreviewUrl) => {
		const { dispatch } = this.props;
		dispatch({
			type: SET_PROFILE_FILES_IMAGES,
			payload: [{ name, base64: imagePreviewUrl }]
		});
	};

	onImageRemoved = () => {
		this.props.dispatch({
			type: REMOVE_PROFILE_IMAGE
		});
	};

	render() {
		const {
			classes,
			dispatch,
			profileEdit,
			photoUri,
			firstname,
			lastname,
			gender,
			company,
			companySiret,
			companyPhone,
			companyAddress,
			companyZipCode,
			companyCity,
			isFetching,
			firstnameErrorMsg,
			lastnameErrorMsg,
			companyErrorMsg,
			siretErrorMsg,
			phoneErrorMsg,
			addressErrorMsg,
			zipCodeErrorMsg,
			cityErrorMsg
		} = this.props;
		const { color, message, focused } = this.state;

		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color="primary" icon>
							<CardIcon color="primary">
								<PermIdentity />
							</CardIcon>
							<h4
								className={classes.cardIconTitle}
								style={{ color: "#444041" }}
							>
								Profil Professionnel
							</h4>
						</CardHeader>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={3}>
									<PictureUpload
										onImageLoaded={this.onImageLoaded}
										onImageRemoved={this.onImageRemoved}
										disabled={!profileEdit || isFetching}
										photoUri={photoUri}
									/>
								</GridItem>
								<GridItem xs={12} sm={9}>
									<GridContainer>
										<GridItem xs={12} sm={4}>
											<div className={classes.checkboxAndRadio}>
												<FormControlLabel
													control={
														<Radio
															checked={!gender || gender === "M"}
															onChange={e =>
																dispatch({
																	type: SET_GENDER,
																	value: e.target.value
																})
															}
															value="M"
															name="mister"
															disabled={!profileEdit || isFetching}
															classes={{
																checked: classes.radio,
																root: classes.radioRoot
															}}
														/>
													}
													classes={{
														label: classes.label,
														root: classes.labelRoot
													}}
													label="M."
												/>
												<FormControlLabel
													control={
														<Radio
															checked={gender && gender === "F"}
															onChange={e =>
																dispatch({
																	type: SET_GENDER,
																	value: e.target.value
																})
															}
															value="F"
															name="miss"
															disabled={!profileEdit || isFetching}
														/>
													}
													classes={{
														label: classes.label,
														root: classes.labelRoot
													}}
													label="Mme"
												/>
											</div>
										</GridItem>

										<GridItem xs={12} sm={4}>
											<CustomInput
												labelText="Prénom *"
												id="firstname"
												formControlProps={{
													fullWidth: true
												}}
												error={firstnameErrorMsg !== null}
												helperText={firstnameErrorMsg}
												inputProps={{
													value: firstname ? firstname : "",
													onChange: e =>
														dispatch({
															type: SET_FIRST_NAME,
															value: e.target.value
														}),
													disabled: !profileEdit || isFetching
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={4}>
											<CustomInput
												labelText="Nom *"
												id="lastname"
												formControlProps={{
													fullWidth: true
												}}
												error={lastnameErrorMsg !== null}
												helperText={lastnameErrorMsg}
												inputProps={{
													value: lastname ? lastname : "",
													onChange: e =>
														dispatch({
															type: SET_LAST_NAME,
															value: e.target.value
														}),
													disabled: !profileEdit || isFetching
												}}
											/>
										</GridItem>

										<GridItem xs={12} sm={12} md={4}>
											<CustomInput
												labelText="Nom entreprise *"
												id="company"
												formControlProps={{
													fullWidth: true
												}}
												error={companyErrorMsg !== null}
												helperText={companyErrorMsg}
												inputProps={{
													value: company ? company : "",
													onChange: e =>
														dispatch({
															type: SET_COMPANY_NAME,
															value: e.target.value
														}),
													disabled: !profileEdit || isFetching
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<CustomInput
												labelText="Code SIRET"
												id="siret"
												formControlProps={{
													fullWidth: true
												}}
												error={siretErrorMsg !== null}
												helperText={siretErrorMsg}
												inputProps={{
													value: companySiret ? companySiret : "",
													onChange: e =>
														dispatch({
															type: SET_SIRET_CODE,
															value: e.target.value
														}),
													disabled: !profileEdit || isFetching
												}}
											/>
										</GridItem>
										<GridItem xs={12} sm={12} md={4}>
											<CustomInput
												labelText="Téléphone *"
												id="phone"
												formControlProps={{
													fullWidth: true
												}}
												error={phoneErrorMsg !== null}
												helperText={phoneErrorMsg}
												labelProps={{
													shrink: Boolean(companyPhone || focused)
												}}
												inputProps={{
													placeholder: "+33 (0)X XX XX XX XX",
													inputComponent: PhoneMask,
													value: companyPhone ? companyPhone : "",
													onChange: e =>
														dispatch({
															type: SET_COMPANY_PHONE,
															value: e.target.value
														}),
													onFocus: () => this.setState({ focused: true }),
													onBlur: () => this.setState({ focused: false }),
													disabled: !profileEdit || isFetching
												}}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<CustomInput
										labelText="Adresse *"
										id="address"
										formControlProps={{
											fullWidth: true
										}}
										error={addressErrorMsg !== null}
										helperText={addressErrorMsg}
										inputProps={{
											value: companyAddress ? companyAddress : "",
											onChange: e =>
												dispatch({
													type: SET_ADDRESS,
													value: e.target.value
												}),
											disabled: !profileEdit || isFetching
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={2}>
									<CustomInput
										labelText="Code postal *"
										id="zipcode"
										formControlProps={{
											fullWidth: true
										}}
										error={zipCodeErrorMsg !== null}
										helperText={zipCodeErrorMsg}
										inputProps={{
											value: companyZipCode ? companyZipCode : "",
											onChange: e =>
												dispatch({
													type: SET_ZIP_CODE,
													value: e.target.value
												}),
											disabled: !profileEdit || isFetching
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={12} md={4}>
									<CustomInput
										labelText="Ville *"
										id="city"
										formControlProps={{
											fullWidth: true
										}}
										error={cityErrorMsg !== null}
										helperText={cityErrorMsg}
										inputProps={{
											value: companyCity ? companyCity : "",
											onChange: e =>
												dispatch({
													type: SET_CITY,
													value: e.target.value
												}),
											disabled: !profileEdit || isFetching
										}}
									/>
								</GridItem>
							</GridContainer>
							<Snackbar
								place="br"
								color={color}
								icon={AddAlert}
								message={message ? message : ""}
								open={this.state.br}
								closeNotification={() => this.setState({ br: false })}
								close
							/>
						</CardBody>
						<CardFooter product>
							<div className={classes.w100}>
								<Button
									color={profileEdit ? "primary" : "brown"}
									className={classes.floatRight}
									onClick={() => {
										if (profileEdit) {
											this.fetchUpdateProfile();
											// dispatch({ type: SET_PROFILE_EDIT, value: false });
										} else {
											dispatch({ type: SET_PROFILE_EDIT, value: true });
										}
									}}
									disabled={isFetching}
								>
									{isFetching ? (
										<Activity />
									) : profileEdit ? (
										"Modifier"
									) : (
										"Edition"
									)}
								</Button>
								{profileEdit && (
									<Button
										color={undefined}
										onClick={() =>
											dispatch({ type: SET_PROFILE_EDIT, value: false })
										}
										className={classes.floatRight}
										disabled={isFetching}
									>
										{"Annuler"}
									</Button>
								)}
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			</GridContainer>
		);
	}
}

ProProfilePage.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const {
		isFetching,
		profileEdit,
		photoUri,
		firstname,
		lastname,
		company,
		companySiret,
		gender,
		companyAddress,
		companyZipCode,
		companyCity,
		companyPhone,
		firstnameErrorMsg,
		lastnameErrorMsg,
		companyErrorMsg,
		siretErrorMsg,
		phoneErrorMsg,
		addressErrorMsg,
		zipCodeErrorMsg,
		cityErrorMsg,
		isValid,
		clearValidate,
		snackInfoMsg,
		snackErrorMsg
	} = state.profileReducer;

	return {
		isFetching,
		profileEdit,
		photoUri,
		firstname,
		lastname,
		company,
		companySiret,
		gender,
		companyAddress,
		companyZipCode,
		companyCity,
		companyPhone,
		firstnameErrorMsg,
		lastnameErrorMsg,
		companyErrorMsg,
		siretErrorMsg,
		phoneErrorMsg,
		addressErrorMsg,
		zipCodeErrorMsg,
		cityErrorMsg,
		isValid,
		clearValidate,
		snackInfoMsg,
		snackErrorMsg
	};
};

const mapDispatchToProps = dispatch => {
	let actions = bindActionCreators(
		{
			fetchUpdateProfile
		},
		dispatch
	);
	return { ...actions, dispatch };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(style)(ProProfilePage));
