import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/DigiHapi/commonStyles";
import FullPageImage from "components/DigiHapi/FullPageImage";

const useStyles = makeStyles(styles);

export default function MaintenancePage() {
	const classes = useStyles();

	const cNames = elts => {
		return classNames(elts.split(" ").map(elt => classes[elt]));
	};

	return (
		<FullPageImage
			bgImage="maintenance"
			child={
				<section>
					<h3 className={cNames("textCenter red")}>
						{"Veuillez nous excuser !"}
					</h3>
					<h3 className={cNames("textCenter red")}>
						{"Le serveur est en maintenance !"}
					</h3>
				</section>
			}
		/>
	);
}
