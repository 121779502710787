import React, { useState, useEffect } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import Email from "@material-ui/icons/Email";
import AddAlert from "@material-ui/icons/AddAlert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import CardFooter from "components/Card/CardFooter.js";
import Activity from "components/DigiHapi/Activity.js";

import {
	fetchLogin,
	fetchForgetPassword,
	fetchLoginUsingToken
} from "../../actions/login";
import { fetchGetUser } from "../../actions/profile";
import {
	SET_EMAIL_OR_PSEUDO,
	SET_PASSWORD,
	CLEAR_VALIDATE,
	VALIDATE_INPUTS,
	ROLE_WEBMASTER,
	CLEAR_FORGET_PWD_VALIDATE,
	VALIDATE_FORGET_PWD_INPUT,
	ROLE_ADVERTISER,
	SET_TOKEN
} from "../../actions/types";
import { parse } from "search-params";

// Redux
import { useDispatch, useSelector } from "react-redux";

import styles from "../../assets/jss/DigiHapi/components/adminLoginPageStyle";

import FullPageImage from "../../components/DigiHapi/FullPageImage";
import CustomFilledInput from "../../components/DigiHapi/CustomFilledInput";
import HomeHeader from "components/DigiHapi/HomeHeader";
import HomeFooter from "components/DigiHapi/HomeFooter";

const useStyles = makeStyles(styles);

export default function ProLoginPage(props) {
	const classes = useStyles();

	const { location, history } = props;

	const cNames = elts => {
		return classNames(elts.split(" ").map(elt => classes[elt]));
	};

	const dispatch = useDispatch();

	const {
		isFetching,
		emailOrPseudo,
		emailOrPseudoErrorMsg,
		password,
		passwordErrorMsg,
		valid,
		snackInfoMsg,
		snackErrorMsg,
		clearValidate,
		strategy,
		validForgetPwd,
		clearForgetPwdValidate
	} = useSelector(state => state.loginReducer);

	const { user } = useSelector(state => state.profileReducer);
	const error = emailOrPseudoErrorMsg || passwordErrorMsg || snackErrorMsg;

	useEffect(() => {
		if (location.search) {
			const { token } = parse(location.search);
			if (token) {
				dispatch({
					type: SET_TOKEN,
					payload: token
				});
				dispatch(fetchLoginUsingToken({ token }));
			}
		} else {
			dispatch(fetchGetUser());
			const emailOrPseudo = localStorage.getItem("emailOrPseudo");
			if (emailOrPseudo) {
				dispatch({
					type: SET_EMAIL_OR_PSEUDO,
					value: emailOrPseudo
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (clearForgetPwdValidate) {
			dispatch({
				type: VALIDATE_FORGET_PWD_INPUT
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearForgetPwdValidate]);

	useEffect(() => {
		if (clearValidate) {
			dispatch({
				type: VALIDATE_INPUTS
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearValidate]);

	useEffect(() => {
		if (valid) {
			dispatch(fetchLogin(emailOrPseudo, password));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valid]);

	useEffect(() => {
		if (validForgetPwd) {
			dispatch(fetchForgetPassword(emailOrPseudo));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [validForgetPwd]);

	useEffect(() => {
		if (!error && user) {
			if (user.role === ROLE_WEBMASTER || user.role === ROLE_ADVERTISER) {
				if (strategy === "jwt") {
					history.push("/pro/mot-de-passe");
				} else {
					history.push("/pro/annonces");
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	const [bc, setBc] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");

	useEffect(() => {
		if (snackInfoMsg || snackErrorMsg) {
			setBc(true);
			setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
			setColor(snackInfoMsg ? "info" : "danger");
			if (snackInfoMsg) {
				setTimeout(() => setBc(false), 5000);
			}
		}
	}, [snackInfoMsg, snackErrorMsg]);

	return (
		<FullPageImage
			bgImage="home"
			child={
				<GridContainer justify="center">
					<HomeHeader bgDarkGray />

					<GridItem xs={12} sm={12} md={6} lg={4}>
						<form
							onSubmit={event => {
								event.preventDefault();
								dispatch({
									type: CLEAR_VALIDATE
								});
							}}
						>
							<Card plain noaos className={cNames("bgWhite07 rounded10")}>
								<CardBody className={classes.flexColumn}>
									<CustomFilledInput
										labelText="Email"
										id="email"
										error={emailOrPseudoErrorMsg !== null}
										helperText={
											emailOrPseudoErrorMsg ? emailOrPseudoErrorMsg : undefined
										}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: emailOrPseudo ? emailOrPseudo : "",
											endAdornment: (
												<InputAdornment position="end">
													<Email className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_EMAIL_OR_PSEUDO,
													value: e.target.value
												})
										}}
									/>
									<CustomFilledInput
										labelText="Mot de passe"
										id="password"
										error={passwordErrorMsg !== null}
										helperText={passwordErrorMsg ? passwordErrorMsg : undefined}
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											value: password ? password : "",
											endAdornment: (
												<InputAdornment position="end">
													<Icon className={classes.inputAdornmentIcon}>
														lock_outline
													</Icon>
												</InputAdornment>
											),
											type: "password",
											autoComplete: "off",
											disabled: isFetching,
											onChange: e =>
												dispatch({
													type: SET_PASSWORD,
													value: e.target.value
												})
										}}
									/>
									<Snackbar
										place="bc"
										color={color}
										icon={AddAlert}
										message={message ? message : ""}
										open={bc}
										closeNotification={() => setBc(false)}
										close
									/>
								</CardBody>
								<CardFooter className={cNames("justifyContentCenter column")}>
									{emailOrPseudo && (
										<Button
											disabled={isFetching}
											className={cNames(
												"darkgrey fs16 underline textTransformNone hoverRed"
											)}
											onClick={() =>
												dispatch({
													type: CLEAR_FORGET_PWD_VALIDATE
												})
											}
											simple
										>
											{"Perte de mot de passe ?"}
										</Button>
									)}
									<Button
										disabled={isFetching}
										className={cNames("h50")}
										type="submit"
										color="primary"
										size="lg"
										block
									>
										{isFetching ? (
											<Activity />
										) : (
											<Icon className={classes.fontWeight900}>
												arrow_forward
											</Icon>
										)}
									</Button>
									<Button
										disabled={isFetching}
										className={cNames("underline fs16 textTransformNone black")}
										simple
										onClick={() => history.push("/enregistrement")}
									>
										{"Pas encore de compte"}
									</Button>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
					<HomeFooter bgDarkGray />
				</GridContainer>
			}
		/>
	);
}
