import { apiAction } from "./api";
import {
	FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
	FETCH_PUBLISH_EVENT,
	FETCH_UPLOAD_IMAGES,
	SET_GLOBAL_INFORMATIONS_VERSIONS,
	SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
	SET_PUBLISH_EVENT_FAILURE,
	SET_PUBLISH_EVENT_RESULT,
	SET_UPLOAD_IMAGES_FAILURE,
	SET_UPLOAD_IMAGES_RESULT
} from "./types";

export function fetchGetGlobalInformationsVersions() {
	return apiAction({
		url: "/globalinformations/versions",
		method: "GET",
		onSuccess: setGlobalInformationsVersions,
		onFailure: setGlobalInformationsVersionsFailure,
		label: FETCH_GET_GLOBAL_INFORMATIONS_VERSION
	});
}

function setGlobalInformationsVersions(data) {
	return {
		type: SET_GLOBAL_INFORMATIONS_VERSIONS,
		payload: data
	};
}

function setGlobalInformationsVersionsFailure(error) {
	return {
		type: SET_GLOBAL_INFORMATIONS_VERSIONS_FAILURE,
		payload: error
	};
}

export function fetchUploadImages(subFolderName, files) {
	return apiAction({
		url: "/api/upload",
		method: "POST",
		data: { subFolderName, files },
		onSuccess: setUploadImagesResult,
		onFailure: setUploadImagesFailure,
		label: FETCH_UPLOAD_IMAGES
	});
}

function setUploadImagesResult(data) {
	return {
		type: SET_UPLOAD_IMAGES_RESULT,
		payload: data
	};
}

function setUploadImagesFailure(data) {
	return {
		type: SET_UPLOAD_IMAGES_FAILURE,
		payload: data
	};
}

export function fetchPublishEvent(description, url, startDate, files, lang) {
	return apiAction({
		url: "/events/publish",
		method: "POST",
		data: {
			description,
			url,
			startDate: startDate ? startDate.toDate().getTime() : null,
			files,
			lang
		},
		onSuccess: setPublishEventResult,
		onFailure: setPublishEventFaliure,
		label: FETCH_PUBLISH_EVENT
	});
}

function setPublishEventResult(data) {
	return {
		type: SET_PUBLISH_EVENT_RESULT,
		payload: data
	};
}

function setPublishEventFaliure(error) {
	return {
		type: SET_PUBLISH_EVENT_FAILURE,
		payload: error
	};
}
