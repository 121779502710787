/*eslint-disable*/
import React from "react";

// material-ui core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../assets/jss/DigiHapi/components/headerStyle";
import { getImage } from "assets/Images";

const useStyles = makeStyles(styles);

export default function HomeHeader() {
	const classes = useStyles();

	return (
		<header className={classes.header}>
			<img
				src={getImage("logodigihapi")}
				alt="DigiHapi logo"
				title="DigiHapi logo"
			/>
			<h3 className={classes.pb10}>PRO</h3>
		</header>
	);
}
