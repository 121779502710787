import { Record } from "immutable";
import {
	API_END,
	API_START,
	FETCH_GET_LOCATIONS,
	RESET_LOCATION,
	SET_LOCATION,
	SET_LOCATIONS_RESULT,
	SET_LOCATION_NAME_EN,
	SET_LOCATION_NAME_ERROR,
	SET_LOCATION_NAME_FR,
	FETCH_ADD_LOCATION,
	SET_ADD_LOCATION_RESULT,
	SET_ADD_LOCATION_FAILURE,
	FETCH_UPDATE_LOCATION,
	SET_UPDATE_LOCATION_RESULT,
	SET_UPDATE_LOCATION_FAILURE,
	FETCH_DELETE_LOCATION,
	SET_DELETE_LOCATION_RESULT,
	SET_DELETE_LOCATION_FAILURE,
	SET_LOCATION_ORDER,
	LOCATIONS_ON_DRAG_END,
	SET_REORDER_LOCATIONS_RESULT,
	SET_REORDER_LOCATIONS_FAILURE,
	ADD_LOCATION,
	SET_LOCATION_WIZARD_STEP,
	RESET_DATA
} from "../actions/types";
import { t } from "../services/i18n";

const InitialState = new Record({
	isFetchingList: false,
	isFetching: false,
	locations: {},
	_id: null,
	name: null,
	lang: null,
	error: null,
	nameError: null,
	nameErrorMsg: null,
	snackInfoMsg: null,
	snackErrorMsg: null,
	order: 0,
	newWizard: null,
	areLocationsReordered: false,
	wizardCurrentStep: 0
});

const initialState = new InitialState();
export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_ADD_LOCATION ||
				action.payload === FETCH_UPDATE_LOCATION ||
				action.payload === FETCH_DELETE_LOCATION
			) {
				return state
					.set("isFetching", true)
					.set("snackInfoMsg", null)
					.set("snackErrorMsg", null);
			}
			if (action.payload === FETCH_GET_LOCATIONS) {
				return state
					.set("isFetchingList", true)
					.set("newWizard", null)
					.set("snackInfoMsg", null)
					.set("snackErrorMsg", null)
					.set("areLocationsReordered", false);
			}
			break;

		case RESET_DATA:
			return initialState;

		case RESET_LOCATION:
			return initialState.set("locations", state.locations);

		case LOCATIONS_ON_DRAG_END: {
			const { source, destination } = action.result;
			if (destination) {
				const locationsIds = Object.keys(state.locations);
				// Add 1 to index due to unused items
				const locationId = locationsIds.splice(source.index, 1);
				locationsIds.splice(destination.index, 0, locationId);
				const locations = {};
				// eslint-disable-next-line
				for (const id of locationsIds) {
					locations[id] = { ...state.locations[id] };
				}
				return state
					.set("locations", locations)
					.set("areLocationsReordered", true);
			}
			// dropped outside the list
			break;
		}

		case SET_LOCATION_WIZARD_STEP:
			return state.set("wizardCurrentStep", action.payload);

		case ADD_LOCATION:
			return state.set("newWizard", "add");

		case SET_LOCATION:
			return state.set("_id", action.payload).set("newWizard", "update");

		case SET_LOCATION_NAME_ERROR:
			return state.set("nameErrorMsg", t("template:namemandatory"));

		case SET_REORDER_LOCATIONS_RESULT:
			return initialState.set("snackInfoMsg", t("location:reordersuccess"));

		case SET_LOCATIONS_RESULT:
			return state.set("locations", action.entities.locations);

		case SET_LOCATION_NAME_FR:
			return state.set("nameErrorMsg", null).set("name", action.payload);

		case SET_LOCATION_ORDER:
			return state.set("order", action.payload);

		case SET_LOCATION_NAME_EN: {
			let en = state.get("lang") ? state.get("lang").en : {};
			if (action.payload) {
				en = {
					en: {
						...en,
						name: action.payload
					}
				};
			}
			return state.set("lang", en);
		}

		case SET_ADD_LOCATION_RESULT:
			return initialState.set("snackInfoMsg", t("location:addsuccess"));

		case SET_UPDATE_LOCATION_RESULT:
			return initialState.set("snackInfoMsg", t("location:updatesuccess"));

		case SET_DELETE_LOCATION_RESULT:
			return initialState.set("snackInfoMsg", t("location:deletesuccess"));

		case SET_REORDER_LOCATIONS_FAILURE:
			return state.set("snackErrorMsg", t("location:reordererror"));

		case SET_UPDATE_LOCATION_FAILURE:
			return state.set("snackErrorMsg", t("location:updateerror"));

		case SET_DELETE_LOCATION_FAILURE: {
			const errorMsg =
				action.error && action.error.message ? action.error.message : "";

			if (errorMsg.endsWith("409")) {
				return state.set("snackErrorMsg", t("location:resourceused"));
			} else {
				return state.set("snackErrorMsg", t("location:deleteerror"));
			}
		}

		case SET_ADD_LOCATION_FAILURE: {
			return state.set("snackErrorMsg", t("location:adderror"));
		}

		case API_END:
			if (action.payload === FETCH_GET_LOCATIONS) {
				return state.set("isFetchingList", false);
			}
			if (
				action.payload === FETCH_ADD_LOCATION ||
				action.payload === FETCH_UPDATE_LOCATION ||
				action.payload === FETCH_DELETE_LOCATION
			) {
				return state.set("isFetching", false);
			}
			break;

		default:
			return state;
	}
	return state;
}
