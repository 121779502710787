import {
	hexToRgb,
	containerFluid
} from "assets/jss/material-dashboard-pro-react.js";

const fullPageImageStyle = {
	fullPage: {
		...containerFluid,
		padding: "120px 0",
		position: "relative",
		minHeight: "100vh",
		display: "flex",
		margin: "0",
		border: "0",
		color: "white",
		alignItems: "center",
		justifyContent: "center",
		backgroundSize: "cover",
		backgroundPosition: "center center",
		height: "100%",
		width: "100%",
		"&:before": {
			backgroundColor: "rgba(" + hexToRgb("#000") + ", 0.1)"
		},
		"&:before,&:after": {
			display: "block",
			// content: '""',
			position: "absolute",
			width: "100%",
			height: "100%",
			top: "0",
			left: "0",
			zIndex: "2"
		}
	},
	bigText: {
		fontSize: 40,
		color: "red"
	}
};

export default fullPageImageStyle;
