import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

const fileTypes = ["jpg", "jpeg", "png", "gif"]; //acceptable file types

export default function ImageUpload(props) {
	let {
		avatar,
		onImageLoaded,
		onImageRemoved,
		photoUri,
		isFetching = false
	} = props;

	const [file, setFile] = React.useState(null);
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
		props.avatar ? defaultAvatar : defaultImage
	);
	let fileInput = React.createRef();

	const handleImageChange = e => {
		e.preventDefault();

		const { files } = e.target;
		if (files && files[0]) {
			var extension = files[0].name.split(".").pop().toLowerCase(), //file extension from input file
				isSuccess = fileTypes.indexOf(extension) > -1; //is extension in acceptable types

			if (isSuccess) {
				let reader = new FileReader();
				reader.onloadend = () => {
					if (onImageLoaded) {
						const filename = files[0].name;
						const index = filename.indexOf(".");
						const length = filename.length;

						onImageLoaded(
							filename.substring(0, index < 0 ? length - 1 : index),
							reader.result
						);
					}
					setFile(files[0]);
					setImagePreviewUrl(reader.result);
				};
				reader.readAsDataURL(files[0]);
			} else {
				//no
				//warning
			}
		}
	};

	const handleClick = e => {
		e.preventDefault();
		fileInput.current.click();
	};

	const handleRemove = e => {
		e.preventDefault();
		if (onImageRemoved) {
			onImageRemoved();
		}
		photoUri = null;
		setFile(null);
		setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage);
		fileInput.current.value = null;
	};

	let accept = "";
	fileTypes.map(fileType => (accept += `${accept ? ",." : "."}${fileType}`));

	return (
		<div className="fileinput text-center">
			<input
				type="file"
				accept={accept}
				onChange={handleImageChange}
				ref={fileInput}
			/>
			<button
				className={
					"thumbnail" +
					(photoUri || file ? " thumbnail-red" : "") +
					(avatar ? " img-circle" : "")
				}
				onClick={e => (photoUri || file ? handleRemove(e) : handleClick(e))}
				disabled={isFetching}
			>
				<img src={photoUri ? photoUri : imagePreviewUrl} alt="..." />
			</button>
		</div>
	);
}

ImageUpload.propTypes = {
	avatar: PropTypes.bool,
	onImageLoaded: PropTypes.func,
	onImageRemoved: PropTypes.func
};
