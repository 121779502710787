import {
	SET_PROFILE_DETAILS,
	FETCH_GET_USER,
	SET_USER_FAILURE,
	SET_UPDATE_PROFILE_SUCCESS,
	SET_UPDATE_PROFILE_FAILURE,
	FETCH_UPDATE_PROFILE
} from "./types";
import { apiAction } from "./api";
import { fetchGetGoodDealsCategories } from "./gooddeal";
import { fetchGetGlobalInformationsVersions } from "./index";
import { fetchGetEventTypes } from "./event";

export function fetchGetUser(alldata = true) {
	let onSuccess;

	if (alldata) {
		onSuccess = data => [
			setPseudo(data.pseudo),
			fetchGetEventTypes(),
			fetchGetGoodDealsCategories(),
			fetchGetGlobalInformationsVersions(),
			setProfileDetails(data)
		];
	} else {
		onSuccess = data => [setProfileDetails(data), setPseudo(data.pseudo)];
	}

	return apiAction({
		url: "/api/auth",
		method: "GET",
		onSuccess,
		onFailure: setUserFailure,
		label: FETCH_GET_USER
	});
}

function setProfileDetails(data) {
	return {
		type: SET_PROFILE_DETAILS,
		payload: data
	};
}

function setUserFailure(error) {
	return {
		type: SET_USER_FAILURE,
		payload: error
	};
}

function setPseudo(pseudo) {
	if (pseudo) {
		localStorage.setItem("emailOrPseudo", pseudo);
	}
}

export function fetchUpdateProfile(user) {
	return apiAction({
		url: "/api/user",
		data: user,
		method: "PUT",
		onSuccess: data => [setUpdateProfileSuccess(data), fetchGetUser(false)],
		onFailure: setUpdateProfileFailure,
		label: FETCH_UPDATE_PROFILE
	});
}

function setUpdateProfileSuccess(data) {
	return {
		type: SET_UPDATE_PROFILE_SUCCESS,
		payload: data
	};
}

function setUpdateProfileFailure(error) {
	return {
		type: SET_UPDATE_PROFILE_FAILURE,
		error
	};
}
