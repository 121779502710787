import {
	API_START,
	API_END,
	FETCH_REORDER_COMMUNITY_EQUIPMENTS,
	SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT,
	SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE,
	COMMUNITY_EQUIPMENTS_ON_DRAG_END,
	SET_CURRENT_COMMUNITY_DETAILS_RESULT,
	SET_COMMUNITY_DETAILS_RESULT
} from "../actions/types";
import { t } from "../services/i18n";

const initialState = {
	isFetching: false,
	snackInfoMsg: null,
	snackErrorMsg: null,
	equipments: {},
	areEquipmentsReordered: false
};

export default function communityReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (action.payload === FETCH_REORDER_COMMUNITY_EQUIPMENTS) {
				return {
					...state,
					isFetching: true
				};
			}
			break;

		case SET_REORDER_COMMUNITY_EQUIPMENTS_RESULT:
			return {
				...state,
				snackInfoMsg: t("equipment:reordersuccess"),
				areEquipmentsReordered: false
			};

		case SET_COMMUNITY_DETAILS_RESULT:
		case SET_CURRENT_COMMUNITY_DETAILS_RESULT:
			return {
				...state,
				equipments: action.entities.equipments
					? action.entities.equipments
					: {},
				areEquipmentsReordered: false
			};

		case SET_REORDER_COMMUNITY_EQUIPMENTS_FAILURE:
			return {
				...state,
				snackErrorMsg: t("equipment:reordererror")
			};

		case COMMUNITY_EQUIPMENTS_ON_DRAG_END: {
			const { source, destination, draggableId } = action.result;
			if (destination) {
				const equipmentsIds = Object.keys(state.equipments);
				if (source.droppableId === "used") {
					// Add 1 to index due to unused items
					const equipmentId = equipmentsIds.splice(source.index, 1);
					if (destination.droppableId === "used") {
						equipmentsIds.splice(destination.index, 0, equipmentId);
					}
				} else if (destination.droppableId === "used") {
					equipmentsIds.splice(destination.index, 0, draggableId);
				}
				const equipments = {};
				// eslint-disable-next-line
				for (const id of equipmentsIds) {
					if (state.equipments[id]) {
						equipments[id] = { ...state.equipments[id] };
					} else {
						equipments[id] = { _id: id, name: action.name };
					}
				}
				return {
					...state,
					equipments,
					areEquipmentsReordered: true,
					source,
					destination
				};
			}
			// dropped outside the list
			break;
		}

		case API_END:
			if (action.payload === FETCH_REORDER_COMMUNITY_EQUIPMENTS) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}

	return state;
}
