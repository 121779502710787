import {
	API_END,
	API_START,
	FETCH_REGISTER_EMPLOYEE,
	SET_EMPLOYEE_EMAIL,
	SET_REGISTER_EMPLOYEE_RESULT,
	CLEAR_EMPLOYEE_EMAIL,
	SET_REGISTER_EMPLOYEE_FAILURE,
	SET_EMPLOYEES_RESULT,
	FETCH_GET_EMPLOYEES,
	RESET_EMPLOYEE,
	CLEAR_EMPLOYEE_VALIDATE,
	VALIDATE_EMPLOYEE_INPUTS,
	FETCH_DELETE_EMPLOYEE,
	SET_DELETE_EMPLOYEE_RESULT,
	SET_DELETE_EMPLOYEE_FAILURE,
	SET_ADD_EMPLOYEE_ROLE,
	RESET_DATA,
	SET_EMPLOYEE_GENDER,
	SET_EMPLOYEE_FIRST_NAME,
	SET_EMPLOYEE_LAST_NAME
} from "../actions/types";
import { t } from "../services/i18n";

const initialState = {
	isFetchingList: false,
	isFetching: false,
	gender: "M",
	firstname: null,
	firstnameErrorMsg: null,
	lastname: null,
	lastnameErrorMsg: null,
	email: null,
	emailState: null,
	emailErrorMsg: null,
	employees: [],
	role: null,
	isValid: false,
	clearValidate: false,
	snackInfoMsg: null,
	snackErrorMsg: null
};

// function that returns true if value is email, false otherwise
const verifyEmail = value => {
	var emailRex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (emailRex.test(value)) {
		return true;
	}
	return false;
};

const validateEmail = email => {
	if (!email) {
		return t("employee:emailmandatory");
	}
	if (!verifyEmail(email)) {
		return t("employee:invalidemail");
	}
	return null;
};

export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_REGISTER_EMPLOYEE ||
				action.payload === FETCH_DELETE_EMPLOYEE
			) {
				return {
					...state,
					isFetching: true,
					snackInfoMsg: null,
					snackErrorMsg: null
				};
			}
			if (action.payload === FETCH_GET_EMPLOYEES) {
				return {
					...state,
					isFetchingList: true,
					employees: []
				};
			}
			break;

		case RESET_DATA:
			return initialState;

		case RESET_EMPLOYEE:
			return {
				...initialState,
				employees: state.employees
			};

		case CLEAR_EMPLOYEE_VALIDATE:
			return {
				...state,
				clearValidate: true,
				isValid: false,
				emailErrorMsg: null
			};

		case VALIDATE_EMPLOYEE_INPUTS: {
			const firstnameErrorMsg =
				!state.firstname || state.firstname.trim().length === 0
					? t("profile:firstnamemandatory")
					: null;
			const lastnameErrorMsg =
				!state.lastname || state.lastname.trim().length === 0
					? t("profile:lastnamemandatory")
					: null;
			const emailErrorMsg = validateEmail(state.email);
			return {
				...state,
				firstnameErrorMsg,
				lastnameErrorMsg,
				emailErrorMsg,
				isValid:
					firstnameErrorMsg === null &&
					lastnameErrorMsg === null &&
					emailErrorMsg === null,
				clearValidate: false
			};
		}

		case SET_EMPLOYEES_RESULT:
			return {
				...state,
				employees: action.payload
			};

		case SET_EMPLOYEE_GENDER:
			return {
				...state,
				gender: action.payload
			};

		case SET_EMPLOYEE_FIRST_NAME: {
			if (
				!action.payload ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.payload[action.payload.length - 1]
				)
			) {
				return {
					...state,
					firstname: action.payload,
					firstnameErrorMsg: null
				};
			}
			break;
		}

		case SET_EMPLOYEE_LAST_NAME: {
			if (
				!action.payload ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.payload[action.payload.length - 1]
				)
			) {
				return {
					...state,
					lastname: action.payload,
					lastnameErrorMsg: null
				};
			}
			break;
		}

		case SET_EMPLOYEE_EMAIL:
			return {
				...state,
				email: action.payload,
				emailErrorMsg: null,
				emailState: verifyEmail(action.payload) ? "success" : "error"
			};

		case SET_ADD_EMPLOYEE_ROLE:
			return {
				...state,
				role: action.payload
			};

		case CLEAR_EMPLOYEE_EMAIL:
			return {
				...state,
				email: null,
				emailState: null
			};

		case SET_REGISTER_EMPLOYEE_RESULT:
			return {
				...initialState,
				snackInfoMsg: t("employee:addsuccess")
			};

		case SET_DELETE_EMPLOYEE_RESULT:
			return {
				...initialState,
				snackInfoMsg: t("employee:deletesuccess")
			};

		case SET_DELETE_EMPLOYEE_FAILURE:
			return {
				...state,
				snackErrorMsg: t("employee:deleteerror")
			};

		case SET_REGISTER_EMPLOYEE_FAILURE: {
			const errorMsg =
				action.payload && action.payload.message ? action.payload.message : "";

			if (errorMsg.endsWith("409")) {
				return {
					...state,
					emailErrorMsg: t("employee:duplicatedemail")
				};
			} else {
				return {
					...state,
					snackErrorMsg: t("employee:adderror")
				};
			}
		}

		case API_END:
			if (action.payload === FETCH_GET_EMPLOYEES) {
				return {
					...state,
					isFetchingList: false
				};
			}
			if (
				action.payload === FETCH_REGISTER_EMPLOYEE ||
				action.payload === FETCH_DELETE_EMPLOYEE
			) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}
	return state;
}
