import {
	FETCH_GET_OWN_EVENTS,
	SET_OWN_EVENTS_RESULT,
	SET_OWN_EVENTS_FAILURE,
	FETCH_PUBLISH_EVENT,
	SET_PUBLISH_EVENT_RESULT,
	SET_PUBLISH_EVENT_FAILURE,
	FETCH_DELETE_EVENT,
	SET_DELETE_EVENT_RESULT,
	SET_DELETE_EVENT_FAILURE,
	FETCH_GET_EVENT,
	SET_GET_EVENT_RESULT,
	SET_GET_EVENT_FAILURE,
	FETCH_ADD_EVENT,
	SET_ADD_EVENT_RESULT,
	SET_ADD_EVENT_FAILURE,
	FETCH_UPDATE_EVENT,
	SET_UPDATE_EVENT_RESULT,
	SET_UPDATE_EVENT_FAILURE,
	FETCH_MODERATING_EVENT_ACCEPT,
	FETCH_MODERATING_EVENT_REFUSE,
	SET_MODERATING_EVENT_ACCEPT_RESULT,
	SET_MODERATING_EVENT_REFUSE_RESULT,
	SET_MODERATING_EVENT_ACCEPT_FAILURE,
	SET_MODERATING_EVENT_REFUSE_FAILURE,
	FETCH_GET_HOME_EVENTS,
	SET_HOME_EVENTS_RESULT,
	SET_HOME_EVENTS_FAILURE,
	FETCH_GET_EVENT_TYPES,
	SET_EVENT_TYPES,
	SET_EVENT_TYPES_FAILURE,
	FETCH_GET_EVENT_CHANNELS,
	SET_GET_EVENT_CHANNELS_RESULT,
	SET_GET_EVENT_CHANNELS_FAILURE
} from "./types";
import { apiAction } from "./api";
import { schema } from "normalizr";
import { fetchGetNearCities } from "./city";

const eventType = new schema.Entity(
	"eventTypes",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicitychannel = new schema.Entity(
	"hapicity_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const ocampingcitychannel = new schema.Entity(
	"ocamping_city_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const ocampingchannel = new schema.Entity(
	"ocamping_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicolibricitychannel = new schema.Entity(
	"hapicolibri_city_channels",
	{},
	{
		idAttribute: "_id"
	}
);

const hapicolibrichannel = new schema.Entity(
	"hapicolibri_channels",
	{},
	{
		idAttribute: "_id"
	}
);

export const event = new schema.Entity("event", {
	HapiCity: [hapicitychannel],
	OCamping_City: [ocampingcitychannel],
	OCamping: [ocampingchannel],
	HapiColibri_City: [hapicolibricitychannel],
	HapiColibri: [hapicolibrichannel]
});

const events = new schema.Entity(
	"events",
	{
		eventTypeId: eventType
	},
	{
		idAttribute: "_id"
	}
);

const infospage = new schema.Entity("infospages", {
	docs: [events]
});

export function fetchGetOwnEvents(page = 1, pageSize = 3, sorted = []) {
	return apiAction({
		url:
			"/events/own?page=" +
			page +
			"&pageSize=" +
			pageSize +
			(sorted.length > 0
				? "&sorted=" +
				  sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
				: ""),
		method: "GET",
		schema: infospage,
		onSuccess: setOwnEventsResult,
		onFailure: setOwnEventsFailure,
		label: FETCH_GET_OWN_EVENTS
	});
}

function setOwnEventsResult(normalized) {
	return {
		type: SET_OWN_EVENTS_RESULT,
		entities: normalized.entities
	};
}

function setOwnEventsFailure(error) {
	return {
		type: SET_OWN_EVENTS_FAILURE,
		error
	};
}

export function fetchDeleteEvent(eventId) {
	return apiAction({
		url: "/events/own/" + eventId,
		method: "DELETE",
		onSuccess: setDeleteEventResult,
		onFailure: setDeleteEventFailure,
		label: FETCH_DELETE_EVENT
	});
}

function setDeleteEventResult(data) {
	return {
		type: SET_DELETE_EVENT_RESULT,
		payload: data
	};
}

function setDeleteEventFailure(error) {
	return {
		type: SET_DELETE_EVENT_FAILURE,
		error
	};
}

export function fetchGetEventChannels(id) {
	return apiAction({
		url: "/events/own/" + id + "/channels",
		method: "GET",
		schema: event,
		onSuccess: setGetEventChannelsResult,
		onFailure: setGetEventChannelsFailure,
		label: FETCH_GET_EVENT_CHANNELS
	});
}

function setGetEventChannelsResult(normalized) {
	return {
		type: SET_GET_EVENT_CHANNELS_RESULT,
		entities: normalized.entities
	};
}

function setGetEventChannelsFailure(error) {
	return {
		type: SET_GET_EVENT_CHANNELS_FAILURE,
		error
	};
}

export function fetchGetEvent(eventId) {
	return apiAction({
		url: "/events/own/" + eventId,
		method: "GET",
		onSuccess: setGetEventResult,
		onFailure: setGetEventFailure,
		label: FETCH_GET_EVENT
	});
}

function setGetEventResult(data) {
	return {
		type: SET_GET_EVENT_RESULT,
		payload: data
	};
}

function setGetEventFailure(error) {
	return {
		type: SET_GET_EVENT_FAILURE,
		error
	};
}

export function fetchAddEvent(data) {
	return apiAction({
		url: "/events/own",
		method: "POST",
		data,
		onSuccess: setAddEventResult,
		onFailure: setAddEventFaliure,
		label: FETCH_ADD_EVENT
	});
}

function setAddEventResult(data) {
	return {
		type: SET_ADD_EVENT_RESULT,
		payload: data
	};
}

function setAddEventFaliure(error) {
	return {
		type: SET_ADD_EVENT_FAILURE,
		payload: error
	};
}

export function fetchUpdateEvent(data) {
	return apiAction({
		url: "/events/own",
		method: "PUT",
		data,
		onSuccess: setUpdateEventResult,
		onFailure: setUpdateEventFaliure,
		label: FETCH_UPDATE_EVENT
	});
}

function setUpdateEventResult(data) {
	return {
		type: SET_UPDATE_EVENT_RESULT,
		payload: data
	};
}

function setUpdateEventFaliure(error) {
	return {
		type: SET_UPDATE_EVENT_FAILURE,
		payload: error
	};
}

export function fetchPublishEvent(eventId, channelsIds) {
	return apiAction({
		url: "/events/own/" + eventId,
		method: "POST",
		data: { channelsIds },
		onSuccess: setPublishEventResult,
		onFailure: setPublishEventFailure,
		label: FETCH_PUBLISH_EVENT
	});
}

function setPublishEventResult(data) {
	return {
		type: SET_PUBLISH_EVENT_RESULT,
		payload: data
	};
}

function setPublishEventFailure(error) {
	return {
		type: SET_PUBLISH_EVENT_FAILURE,
		payload: error
	};
}

export function fetchModeratingAccept(eventId) {
	return apiAction({
		url: "/events/moderating/" + eventId,
		method: "POST",
		onSuccess: setModeratingEventAcceptResult,
		onFailure: setModeratingEventAcceptFailure,
		label: FETCH_MODERATING_EVENT_ACCEPT
	});
}

function setModeratingEventAcceptResult(data) {
	return {
		type: SET_MODERATING_EVENT_ACCEPT_RESULT,
		payload: data
	};
}

function setModeratingEventAcceptFailure(error) {
	return {
		type: SET_MODERATING_EVENT_ACCEPT_FAILURE,
		payload: error
	};
}

export function fetchModeratingRefuse(eventId) {
	return apiAction({
		url: "/events/moderating/" + eventId,
		method: "POST",
		data: {
			isAccepted: false
		},
		onSuccess: setModeratingEventRefuseResult,
		onFailure: setModeratingEventRefuseFailure,
		label: FETCH_MODERATING_EVENT_REFUSE
	});
}

function setModeratingEventRefuseResult(data) {
	return {
		type: SET_MODERATING_EVENT_REFUSE_RESULT,
		payload: data
	};
}

function setModeratingEventRefuseFailure(error) {
	return {
		type: SET_MODERATING_EVENT_REFUSE_FAILURE,
		payload: error
	};
}

export function fetchGetHomeEvents(page = 1, pageSize = 3, sorted = []) {
	return apiAction({
		url:
			"/events/home?page=" +
			page +
			"&pageSize=" +
			pageSize +
			(sorted.length > 0
				? "&sorted=" +
				  sorted.map(elt => elt.id + (elt.desc ? ":-1" : ":1")).join("|")
				: ""),
		method: "GET",
		schema: infospage,
		onSuccess: setHomeEventsResult,
		onFailure: setHomeEventsFailure,
		label: FETCH_GET_HOME_EVENTS
	});
}

function setHomeEventsResult(normalized) {
	return {
		type: SET_HOME_EVENTS_RESULT,
		entities: normalized.entities
	};
}

function setHomeEventsFailure(error) {
	return {
		type: SET_HOME_EVENTS_FAILURE,
		error
	};
}

export function fetchGetEventTypes(post) {
	return apiAction({
		url: "/events/types",
		method: "GET",
		schema: [eventType],
		onSuccess: normalized => [setEventTypes(normalized), post && post()],
		onFailure: setEventTypesFailure,
		label: FETCH_GET_EVENT_TYPES
	});
}

function setEventTypes(normalized) {
	return {
		type: SET_EVENT_TYPES,
		entities: normalized.entities
	};
}

function setEventTypesFailure(error) {
	return {
		type: SET_EVENT_TYPES_FAILURE,
		payload: error
	};
}
