import GoodDeals from "views/Pages/GoodDeals/GoodDealsPage";
import ProProfilePage from "views/Pages/ProProfilePage";
import AboutPage from "views/Pages/AboutPage";
import PasswordPage from "views/Pages/PasswordPage";
import Posts from "views/Pages/Posts/PostsPage";

// @material-ui/icons
import Loyalty from "@material-ui/icons/Loyalty";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";

var dashRoutes = [
	{
		path: "/a-propos",
		name: "À propos",
		component: AboutPage,
		layout: "/pro",
		invisible: true
	},
	{
		path: "/profil",
		name: "Profil",
		component: ProProfilePage,
		layout: "/pro",
		invisible: true
	},
	{
		path: "/mot-de-passe",
		name: "Mot de passe",
		component: PasswordPage,
		layout: "/pro",
		invisible: true
	},
	{
		path: "/annonces",
		name: "Annonces",
		icon: Loyalty,
		component: GoodDeals.GoodDealsPage,
		layout: "/pro"
	},
	{
		path: "/publications",
		name: "Publications",
		icon: PlaylistAdd,
		component: Posts.PostsPage,
		layout: "/pro"
	}
];
export default dashRoutes;
