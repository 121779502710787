import {
	API_START,
	API_END,
	FETCH_GET_CITIES,
	REMOVE_CITY_IMAGE,
	SET_CITY_FILES_IMAGES,
	SET_CITY_NAME,
	SET_CITY_NAME_ERROR,
	SET_CITY_LATITUDE,
	SET_CITY_LONGITUDE,
	FETCH_ADD_CITY,
	SET_FETCH_ADD_CITY_RESULT,
	SET_FETCH_ADD_CITY_FAILURE,
	SET_CITYMAP_FILES_IMAGES,
	REMOVE_CITYMAP_IMAGE,
	FETCH_UPDATE_CITY,
	SET_FETCH_UPDATE_CITY_FAILURE,
	SET_FETCH_UPDATE_CITY_RESULT,
	RESET_CITY,
	FETCH_GET_CITY_DETAILS,
	SET_CITY_DETAILS_RESULT,
	SET_CITY_PHONE,
	SET_CITY_PHONE_ERROR,
	CLEAR_CITY_PHONE,
	SET_CITY_ADDRESS,
	SET_CITY_ZIP_CODE,
	SET_CITY_CITY,
	SET_CITY_FAX,
	CLEAR_CITY_FAX,
	SET_CITY_FAX_ERROR,
	SET_CITY_WEB_SITE_URL,
	CLEAR_CITY_WEB_SITE_URL,
	SET_CITY_WEB_SITE_URL_ERROR,
	SET_CITY_MAIL,
	CLEAR_CITY_MAIL,
	SET_CITY_MAIL_ERROR,
	FETCH_GET_CURRENT_CITY_DETAILS,
	RESET_DATA,
	SET_CITY_USING_ZIPCODE_RESULT,
	SET_ADDRESS_USING_ZIPCODE_RESULT,
	SET_CITY_SELECTED_CITY,
	SET_CITY_SELECTED_ADDRESS,
	SET_ADDRESS_USING_COORDINATES_RESULT,
	SET_CITY_SELECTED_ZIPCODE,
	SET_FETCH_DELETE_CITY_FAILURE,
	SET_FETCH_DELETE_CITY_RESULT,
	FETCH_DELETE_CITY,
	SET_CITY_REGISTER_KEY,
	// SET_CURRENT_CITY_DETAILS_RESULT,
	CITY_EQUIPMENTS_ON_DRAG_END,
	SET_REORDER_CITY_EQUIPMENTS_RESULT,
	SET_REORDER_CITY_EQUIPMENTS_FAILURE,
	FETCH_REORDER_CITY_EQUIPMENTS,
	CLEAR_CITY_VALIDATE,
	VALIDATE_CITY_INPUTS,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	FETCH_REGISTER_CITY,
	SET_REGISTER_CITY_RESULT,
	SET_REGISTER_CITY_FAILURE,
	SET_DELETE_CITY_CONFIRMATION_NAME,
	SET_CITY_CHANNELS,
	ADD_CITY_CHANNEL,
	DELETE_CITY_CHANNEL,
	SET_CITY_LOGO_FILES_IMAGES,
	REMOVE_CITY_LOGO_IMAGE,
	SET_CITY_CUSTOM_URI,
	SET_CITY_CUSTOM_URI_ERROR
} from "../actions/types";
import { t } from "../services/i18n";
const OTHER = "Autre ...";

const initialState = {
	isFetching: false,
	isFetchingList: false,
	_id: null,
	name: null,
	nameErrorMsg: null,
	customUri: null,
	customUriErrorMsg: null,
	addresses: {},
	customAddress: null,
	address: null,
	selectedAddress: OTHER,
	addressUpdated: false,
	zipCode: null,
	zipCodes: {},
	customZipCode: null,
	selectedZipCode: OTHER,
	zipCodeUpdated: false,
	customCity: null,
	city: null,
	selectedCity: OTHER,
	cities: {},
	webSiteUrl: null,
	mail: null,
	mailErrorMsg: null,
	latitude: null,
	longitude: null,
	phone: null,
	phoneErrorMsg: null,
	phoneState: null,
	fax: null,
	faxState: null,
	faxErrorMsg: null,
	registerkey: null,
	files: [],
	snackErrorMsg: null,
	snackInfoMsg: null,
	newWizard: null,
	photoUri: null,
	logoUri: null,
	mapUri: null,
	mapSmallUri: null,
	// city: null,
	equipments: {},
	areEquipmentsReordered: false,
	source: null,
	destination: null,
	clearValidate: false,
	isValid: false,
	firstname: null,
	lastname: null,
	lastnameErrorMsg: null,
	firstnameErrorMsg: null,
	addressErrorMsg: null,
	zipCodeErrorMsg: null,
	cityErrorMsg: null,
	deleteConfirmationName: "",
	channelsIds: [],
	channels: [],
	channelsSuggestions: [],
	channelsEntities: {}
};

function validatePhone(phone) {
	let phoneState = "success";
	const phoneRegex =
		/^[+][0-9]{1,4}[" "][(][0][)][1-9][" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}$/g;
	if (phone && !phoneRegex.test(phone)) {
		phoneState = "error";
	}
	return phoneState;
}

function validateUrl(url) {
	try {
		new URL(url);
		return "success";
	} catch (_) {
		return "error";
	}
}

function validateEmail(email) {
	var emailRex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (emailRex.test(email)) {
		return "success";
	}
	return "error";
}

const replaceChar = char => {
	// good nothing to change
	if (RegExp("[a-z0-9-]{1}").test(char)) {
		return char;
	}
	// Uppercase to lower case
	if (RegExp("[A-Z]{1}").test(char)) {
		return char.toLowerCase();
	}
	if (RegExp("[àâÀÂ]{1}").test(char)) {
		return "a";
	}
	if (RegExp("[éèêëÉÈÊË]{1}").test(char)) {
		return "e";
	}
	if (RegExp("[îïÎÏ]{1}").test(char)) {
		return "i";
	}
	if (RegExp("[ôöÔÖ]{1}").test(char)) {
		return "o";
	}
	if (RegExp("[ûùüÛÙÜ]{1}").test(char)) {
		return "u";
	}
	if (RegExp("[ç]{1}").test(char)) {
		return "c";
	}
	return "-";
};

function keepState(state) {
	return {
		...initialState,
		channelsSuggestions: Object.values(state.channelsEntities).map(obj => ({
			name: obj.name,
			id: obj._id
		})),
		channelsEntities: state.channelsEntities
	};
}

export default function cityReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (action.payload === FETCH_GET_CITIES) {
				return {
					...state,
					isFetchingList: true
				};
			}
			if (
				action.payload === FETCH_GET_CITY_DETAILS ||
				action.payload === FETCH_ADD_CITY ||
				action.payload === FETCH_UPDATE_CITY ||
				action.payload === FETCH_DELETE_CITY ||
				action.payload === FETCH_GET_CURRENT_CITY_DETAILS ||
				action.payload === FETCH_REORDER_CITY_EQUIPMENTS ||
				action.payload === FETCH_REGISTER_CITY
			) {
				return {
					...state,
					isFetching: true,
					newWizard: null,
					snackInfoMsg: null,
					snackErrorMsg: null
				};
			}
			break;

		case RESET_DATA:
		case RESET_CITY:
			return {
				...keepState(state)
			};

		case SET_CITY_CHANNELS: {
			return {
				...state,
				channelsSuggestions: Object.values(action.entities.channels).map(
					obj => ({
						name: obj.name,
						id: obj._id
					})
				),
				channelsEntities: action.entities.channels
			};
		}

		case ADD_CITY_CHANNEL: {
			const channelsSuggestions = state.channelsSuggestions.filter(
				(suggestion, index) => suggestion.id !== action.value.id
			);
			return {
				...state,
				channels: [...state.channels, action.value],
				channelsIds: [...state.channelsIds, action.value.id],
				channelsSuggestions
			};
		}

		case DELETE_CITY_CHANNEL: {
			const channelsSuggestions = [
				...state.channelsSuggestions,
				state.channels[action.value]
			];
			return {
				...state,
				channels: state.channels.filter(
					(channel, index) => index !== action.value
				),
				channelsIds: state.channelsIds.filter(
					(channel, index) => index !== action.value
				),
				channelsSuggestions
			};
		}

		case SET_DELETE_CITY_CONFIRMATION_NAME:
			return {
				...state,
				deleteConfirmationName: action.value
			};

		// case SET_CURRENT_CITY_DETAILS_RESULT:
		//   return {
		//     ...state,
		//     city: Object.values(action.entities.city)[0],
		//     equipments: action.entities.equipments,
		//     areEquipmentsReordered: false
		//   };

		case CITY_EQUIPMENTS_ON_DRAG_END: {
			const { source, destination, draggableId } = action.result;
			if (destination) {
				const equipmentsIds = Object.keys(state.equipments);
				if (source.droppableId === "used") {
					// Add 1 to index due to unused items
					const equipmentId = equipmentsIds.splice(source.index, 1);
					if (destination.droppableId === "used") {
						equipmentsIds.splice(destination.index, 0, equipmentId);
					}
				} else if (destination.droppableId === "used") {
					equipmentsIds.splice(destination.index, 0, draggableId);
				}
				const equipments = {};
				// eslint-disable-next-line
				for (const id of equipmentsIds) {
					if (state.equipments[id]) {
						equipments[id] = { ...state.equipments[id] };
					} else {
						equipments[id] = { _id: id, name: action.name };
					}
				}
				return {
					...state,
					equipments,
					areEquipmentsReordered: true,
					source,
					destination
				};
			}
			// dropped outside the list
			break;
		}

		case SET_REORDER_CITY_EQUIPMENTS_RESULT:
			return {
				...state,
				snackInfoMsg: t("equipment:reordersuccess"),
				areEquipmentsReordered: false
			};

		case SET_REORDER_CITY_EQUIPMENTS_FAILURE:
			return {
				...state,
				snackErrorMsg: t("equipment:reordererror")
			};

		case SET_ADDRESS_USING_ZIPCODE_RESULT:
		case SET_ADDRESS_USING_COORDINATES_RESULT: {
			const gouvData = action.payload;
			if (gouvData && gouvData.features && gouvData.features.length > 0) {
				// Sort data by distance
				const sortedData = gouvData.features.sort(function (obj1, obj2) {
					if (obj1.properties && obj2.properties) {
						return obj1.properties.distance - obj2.properties.distance;
					} else {
						return 0;
					}
				});
				const addresses = {};
				const zipCodes = {};
				const cities = {};
				let address = null;
				// eslint-disable-next-line
				for (const val of sortedData) {
					if (
						val.properties !== null &&
						val.properties.postcode !== null &&
						val.properties.name !== null &&
						val.properties.city !== null
					) {
						if (!address) {
							address = val.properties.name;
						}
						addresses[val.properties.name] = {
							city: val.properties.city,
							zipCode: val.properties.postcode,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						zipCodes[val.properties.postcode] = {
							city: val.properties.city,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						cities[val.properties.city] = {
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
					}
				}
				if (Object.keys(addresses).length > 0) {
					return {
						...state,
						addressUpdated: false,
						addresses,
						selectedAddress: address,
						address,
						zipCodes,
						selectedZipCode: addresses[address].zipCode,
						zipCode: addresses[address].zipCode,
						cities,
						selectedCity: addresses[address].city,
						city: addresses[address].city,
						longitude: addresses[address].longitude,
						latitude: addresses[address].latitude
					};
				}
			}
			break;
		}

		case SET_CITY_USING_ZIPCODE_RESULT: {
			const gouvData = action.payload;
			if (gouvData && gouvData.features && gouvData.features.length > 0) {
				const zipCodes = {};
				const cities = {};
				let zipCode = null;
				// eslint-disable-next-line
				for (const val of gouvData.features) {
					if (
						val.properties !== null &&
						val.properties.postcode === state.zipCode &&
						val.geometry !== null &&
						val.geometry.coordinates !== null &&
						val.geometry.coordinates.length === 2 &&
						!cities[val.properties.city]
					) {
						zipCodes[val.properties.postcode] = {
							city: val.properties.city,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						if (!zipCode) {
							zipCode = val.properties.postcode;
						}
						cities[val.properties.city] = {
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
					}
				}
				if (Object.keys(cities).length > 0) {
					return {
						...state,
						zipCodeUpdated: false,
						zipCodes,
						selectedZipCode: zipCode,
						zipCode,
						cities,
						selectedCity: zipCodes[zipCode].city,
						city: zipCodes[zipCode].city,
						longitude: zipCodes[zipCode].longitude,
						latitude: zipCodes[zipCode].latitude
					};
				}
			}
			return {
				...state,
				zipCodeUpdated: false
			};
		}

		case SET_CITY_SELECTED_ADDRESS: {
			if (action.payload === OTHER) {
				return {
					...state,
					selectedAddress: action.payload,
					address: state.customAddress
				};
			} else {
				const address = state.addresses[action.payload];
				return {
					...state,
					selectedZipCode: address.zipCode,
					zipCode: address.zipCode,
					city: address.city,
					selectedCity: address.city,
					selectedAddress: action.payload,
					address: action.payload,
					longitude: address.longitude,
					latitude: address.latitude
				};
			}
		}

		case SET_CITY_SELECTED_ZIPCODE: {
			if (action.payload === OTHER) {
				return {
					...state,
					selectedZipCode: action.payload,
					zipCode: state.customZipCode
				};
			} else {
				const zipCode = state.zipCodes[action.payload];
				return {
					...state,
					selectedZipCode: action.payload,
					zipCode: action.payload,
					city: zipCode.city,
					selectedCity: zipCode.city,
					longitude: zipCode.longitude,
					latitude: zipCode.latitude
				};
			}
		}

		case SET_CITY_SELECTED_CITY:
			if (action.payload === OTHER) {
				return {
					...state,
					selectedCity: action.payload,
					city: state.customCity
				};
			} else {
				return {
					...state,
					selectedCity: action.payload,
					city: action.payload,
					longitude: state.cities[action.payload].longitude,
					latitude: state.cities[action.payload].latitude
				};
			}

		case SET_CITY_DETAILS_RESULT: {
			const {
				_id,
				name,
				customUri,
				address,
				zipCode,
				city,
				webSiteUrl,
				mail,
				latitude,
				longitude,
				phone,
				fax,
				registerkey,
				photoUri,
				logoUri,
				mapUri,
				mapSmallUri,
				channelsIds
			} = action.payload;

			const channels = [];
			const channelsSuggestions = [...state.channelsSuggestions];
			if (channelsIds) {
				for (const channelId of channelsIds) {
					channels.push({
						name: state.channelsEntities[channelId].name,
						id: state.channelsEntities[channelId]._id
					});
					const index = channelsSuggestions.findIndex(
						obj => obj.id === state.channelsEntities[channelId]._id
					);
					if (index >= 0) {
						channelsSuggestions.splice(index, 1);
					}
				}
			}
			return {
				...state,
				_id,
				name,
				customUri,
				address,
				customAddress: address,
				zipCode,
				customZipCode: zipCode,
				city,
				customCity: city,
				webSiteUrl,
				mail,
				latitude,
				longitude,
				phone,
				fax,
				registerkey,
				photoUri,
				logoUri,
				mapUri,
				mapSmallUri,
				newWizard: "update",
				channelsIds: channelsIds ? channelsIds : [],
				channels,
				channelsSuggestions
			};
		}

		case SET_CITY_LATITUDE:
			return {
				...state,
				latitude: action.value
			};

		case SET_CITY_LONGITUDE:
			return {
				...state,
				longitude: action.value
			};

		case SET_CITY_PHONE:
			return {
				...state,
				phone: action.value,
				phoneState: validatePhone(action.value),
				phoneErrorMsg: null
			};

		case CLEAR_CITY_PHONE:
			return {
				...state,
				phone: null,
				phoneState: null,
				phoneErrorMsg: null
			};

		case SET_CITY_PHONE_ERROR:
			return {
				...state,
				phoneErrorMsg: t("profile:invalidphone")
			};

		case SET_CITY_FAX:
			return {
				...state,
				fax: action.value,
				faxState: validatePhone(action.value),
				faxErrorMsg: null
			};

		case CLEAR_CITY_FAX:
			return {
				...state,
				fax: null,
				faxState: null,
				faxErrorMsg: null
			};

		case SET_CITY_FAX_ERROR:
			return {
				...state,
				faxErrorMsg: t("city:invalidfax")
			};

		case SET_CITY_WEB_SITE_URL:
			return {
				...state,
				webSiteUrl: action.value,
				webSiteUrlState: validateUrl(action.value),
				webSiteUrlErrorMsg: null
			};

		case CLEAR_CITY_WEB_SITE_URL:
			return {
				...state,
				webSiteUrl: null,
				webSiteUrlState: null,
				webSiteUrlErrorMsg: null
			};

		case SET_CITY_WEB_SITE_URL_ERROR:
			return {
				...state,
				webSiteUrlErrorMsg: t("gooddeal:invalidurl")
			};

		case SET_CITY_MAIL:
			return {
				...state,
				mail: action.value,
				mailState: validateEmail(action.value),
				mailErrorMsg: null
			};

		case CLEAR_CITY_MAIL:
			return {
				...state,
				mail: null,
				mailState: null,
				mailErrorMsg: null
			};

		case CLEAR_CITY_VALIDATE:
			return {
				...state,
				nameErrorMsg: null,
				phoneErrorMsg: null,
				mailErrorMsg: null,
				webSiteUrlErrorMsg: null,
				lastnameErrorMsg: null,
				firstnameErrorMsg: null,
				addressErrorMsg: null,
				zipCodeErrorMsg: null,
				cityErrorMsg: null,
				clearValidate: true,
				isValid: false
			};

		case SET_FIRST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					firstname: action.value,
					firstnameErrorMsg: null
				};
			}
			break;

		case SET_LAST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					lastname: action.value,
					lastnameErrorMsg: null
				};
			}
			break;

		case VALIDATE_CITY_INPUTS: {
			const firstnameErrorMsg =
				state.firstname && state.firstname.trim().length > 0
					? null
					: t("profile:firstnamemandatory");
			const lastnameErrorMsg =
				state.lastname && state.lastname.trim().length > 0
					? null
					: t("profile:lastnamemandatory");
			const addressErrorMsg =
				state.address && state.address.trim().length > 0
					? null
					: t("city:addressmandatory");
			const zipCodeErrorMsg =
				state.zipCode && state.zipCode.trim().length > 0
					? null
					: t("city:zipCodemandatory");
			const cityErrorMsg =
				state.city && state.city.trim().length > 0
					? null
					: t("city:citymandatory");
			const nameErrorMsg =
				state.name && state.name.trim().length > 0
					? null
					: t("city:namemandatory");

			let mailErrorMsg = null;
			if (!state.mail) {
				mailErrorMsg = t("login:missingemail");
			} else if (validateEmail(state.mail) === "error") {
				mailErrorMsg = t("city:invalidmail");
			}
			let phoneErrorMsg = null;
			if (!state.phone) {
				phoneErrorMsg = t("profile:phonemandatory");
			} else if (validatePhone(state.phone) === "error") {
				phoneErrorMsg = t("profile:invalidphone");
			}
			return {
				...state,
				clearValidate: false,
				firstnameErrorMsg,
				lastnameErrorMsg,
				addressErrorMsg,
				zipCodeErrorMsg,
				cityErrorMsg,
				mailErrorMsg,
				nameErrorMsg,
				phoneErrorMsg,
				isValid:
					!firstnameErrorMsg &&
					!lastnameErrorMsg &&
					!addressErrorMsg &&
					!zipCodeErrorMsg &&
					!cityErrorMsg &&
					!mailErrorMsg &&
					!nameErrorMsg &&
					!phoneErrorMsg
			};
		}

		case SET_CITY_MAIL_ERROR:
			return {
				...state,
				mailErrorMsg: t("city:invalidmail")
			};

		case SET_CITY_ADDRESS:
			return {
				...state,
				customAddress: action.value,
				address: action.value,
				addressErrorMsg: null,
				addressUpdated: true
			};

		case SET_CITY_ZIP_CODE:
			return {
				...state,
				customZipCode: action.value,
				zipCode: action.value,
				zipCodeUpdated: true,
				zipCodeErrorMsg: null
			};

		case SET_CITY_CITY:
			return {
				...state,
				customCity: action.value,
				city: action.value,
				cityErrorMsg: null
			};

		case SET_CITY_FILES_IMAGES:
			return {
				...state,
				files: [
					...state.files.filter(file => file.out !== "photoUri"),
					...action.payload
				],
				photoUri: null
			};

		case REMOVE_CITY_IMAGE: {
			return {
				...state,
				files: [...state.files.filter(file => file.out !== "photoUri")],
				photoUri: null
			};
		}

		case SET_CITY_LOGO_FILES_IMAGES:
			return {
				...state,
				files: [
					...state.files.filter(file => file.out !== "logoUri"),
					...action.payload
				],
				logoUri: null
			};

		case REMOVE_CITY_LOGO_IMAGE: {
			return {
				...state,
				files: [...state.files.filter(file => file.out !== "logoUri")],
				logoUri: null
			};
		}

		case SET_CITYMAP_FILES_IMAGES:
			return {
				...state,
				files: [
					...state.files.filter(
						file => file.out !== "mapUri" && file.out !== "mapSmallUri"
					),
					...action.payload
				],
				mapUri: null,
				mapSmallUri: null
			};

		case REMOVE_CITYMAP_IMAGE:
			return {
				...state,
				files: [
					...state.files.filter(
						file => file.out !== "mapUri" && file.out !== "mapSmallUri"
					)
				],
				mapUri: null,
				mapSmallUri: null
			};

		case SET_CITY_NAME:
			if (state.newWizard) {
				return {
					...state,
					name: action.value,
					nameErrorMsg: null
				};
			} else {
				let customUri = "";
				if (action.value) {
					for (const c of action.value) {
						customUri += replaceChar(c);
					}
				}
				return {
					...state,
					customUri,
					customUriErrorMsg: null,
					name: action.value,
					nameErrorMsg: null
				};
			}

		case SET_CITY_CUSTOM_URI:
			return {
				...state,
				customUri: action.value,
				customUriErrorMsg: null
			};

		case SET_CITY_REGISTER_KEY:
			return {
				...state,
				registerkey: action.value
			};

		case SET_CITY_NAME_ERROR:
			return {
				...state,
				nameErrorMsg: t("city:namemandatory")
			};

		case SET_CITY_CUSTOM_URI_ERROR:
			return {
				...state,
				customUriErrorMsg: t("city:urimandatory")
			};

		case SET_FETCH_ADD_CITY_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("city:addsuccess")
			};

		case SET_REGISTER_CITY_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("city:registersuccess"),
				longitude: state.longitude,
				latitude: state.latitude
			};

		case SET_REGISTER_CITY_FAILURE:
			return {
				...state,
				snackErrorMsg: t("city:registererror")
			};

		case SET_FETCH_DELETE_CITY_RESULT:
			return {
				...state,
				snackInfoMsg: t("city:deletesuccess")
			};

		case SET_FETCH_UPDATE_CITY_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("city:updatesuccess")
			};

		case SET_FETCH_ADD_CITY_FAILURE:
			return {
				...state,
				snackErrorMsg: t("city:adderror")
			};

		case SET_FETCH_UPDATE_CITY_FAILURE:
			return {
				...state,
				snackErrorMsg: t("city:updateerror")
			};

		case SET_FETCH_DELETE_CITY_FAILURE:
			return {
				...state,
				snackErrorMsg: t("city:deleteerror")
			};

		case API_END:
			if (action.payload === FETCH_GET_CITIES) {
				return {
					...state,
					isFetchingList: false
				};
			}
			if (
				action.payload === FETCH_GET_CITY_DETAILS ||
				action.payload === FETCH_ADD_CITY ||
				action.payload === FETCH_UPDATE_CITY ||
				action.payload === FETCH_DELETE_CITY ||
				action.payload === FETCH_GET_CURRENT_CITY_DETAILS ||
				action.payload === FETCH_REORDER_CITY_EQUIPMENTS ||
				action.payload === FETCH_REGISTER_CITY
			) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}
	return state;
}
