import commonStyles from "assets/jss/DigiHapi/commonStyles.js";
import dashboardStyle from "../../material-dashboard-pro-react/views/dashboardStyle";

const styles = {
	...dashboardStyle,
	...commonStyles,
	opacity: {
		backgroundColor: "rgba(0, 0, 0, 0.4)"
	},
	logo: {
		width: 500
	},
	logoMobile: {
		width: 300
	}
};

export default styles;
