// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import {
	SET_POST_DESCRIPTION_EN,
	SET_POST_TITLE_EN,
	SET_POST_PROMOTION_EN
} from "actions/types";
import customInputStyle from "assets/jss/material-dashboard-pro-react/components/customInputStyle";
import CustomInput from "components/CustomInput/CustomInput";
// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center"
	},
	inputAdornmentIcon: {
		color: "#555"
	},
	inputAdornment: {
		position: "relative"
	},
	...customInputStyle
};

class Step4 extends React.Component {
	sendState() {
		return this.state;
	}

	isValidated() {
		return true;
	}

	render() {
		const { dispatch, lang } = this.props;
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Titre"
						id="title-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value: lang && lang.en && lang.en.title ? lang.en.title : "",
							onChange: e =>
								dispatch({
									type: SET_POST_TITLE_EN,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText="Description"
						id="descrption-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value:
								lang && lang.en && lang.en.description
									? lang.en.description
									: "",
							onChange: e =>
								dispatch({
									type: SET_POST_DESCRIPTION_EN,
									value: e.target.value
								}),
							multiline: true,
							rows: 5
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<CustomInput
						labelText={"Promotion"}
						id="promotion-en"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							value:
								lang && lang.en && lang.en.promotion ? lang.en.promotion : "",
							onChange: e =>
								dispatch({
									type: SET_POST_PROMOTION_EN,
									value: e.target.value
								})
						}}
					/>
				</GridItem>
			</GridContainer>
		);
	}
}

Step4.propTypes = {
	classes: PropTypes.object
};

const mapStateToProps = state => {
	const { lang } = state.eventReducer;
	return {
		lang
	};
};

export default connect(mapStateToProps)(withStyles(style)(Step4));
