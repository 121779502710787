import { Record } from "immutable";
import {
	API_END,
	API_START,
	SET_UPLOAD_IMAGES_RESULT,
	SET_SUBFOLDER_IMAGES,
	SET_FILES_IMAGES,
	FETCH_UPLOAD_IMAGES,
	REMOVE_IMAGE
} from "../actions/types";

const InitialState = new Record({
	isFetching: false,
	subFolderName: null,
	uploadImageResult: null,
	files: []
});

const initialState = new InitialState();
export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (action.payload === FETCH_UPLOAD_IMAGES) {
				return state
					.set("isFetching", true)
					.set("subFolderName", null)
					.set("uploadImageResult", null)
					.set("files", []);
			}
			break;

		case SET_SUBFOLDER_IMAGES:
			return state.set("subFolderName", action.payload);

		case SET_FILES_IMAGES:
			return state.set("files", action.payload);

		case REMOVE_IMAGE:
			return state.set("files", []);

		case SET_UPLOAD_IMAGES_RESULT:
			return state.set("uploadImageResult", action.payload);

		case API_END:
			if (action.payload === FETCH_UPLOAD_IMAGES) {
				return state.set("isFetching", false);
			}
			break;

		default:
			return state;
	}
	return state;
}
