import { Record } from "immutable";
import {
	API_END,
	API_START,
	FETCH_ADD_TEMPLATE_CATEGORY,
	SET_TEMPLATE_CATEGORY_NAME_FR,
	SET_TEMPLATE_CATEGORY_NAME_EN,
	SET_ADD_TEMPLATE_CATEGORY_RESULT,
	SET_TEMPLATE_CATEGORY_ORDER,
	SET_ADD_TEMPLATE_CATEGORY_FAILURE,
	SET_TEMPLATES_CATEGORIES_NAMES_RESULT,
	SET_TEMPLATES_CATEGORIES_IDS_RESULT,
	SET_TEMPLATE_CATEGORY_NAME_ERROR,
	FETCH_GET_TEMPLATES_CATEGORIES_NAMES,
	SET_DELETE_TEMPLATE_CATEGORY_FAILURE,
	SET_DELETE_TEMPLATE_CATEGORY_RESULT,
	FETCH_DELETE_TEMPLATE_CATEGORY,
	SET_TEMPLATE_CATEGORY,
	FETCH_UPDATE_TEMPLATE_CATEGORY,
	SET_UPDATE_TEMPLATE_CATEGORY_RESULT,
	SET_UPDATE_TEMPLATE_CATEGORY_FAILURE,
	RESET_TEMPLATE_CATEGORY,
	SET_TEMPLATES_CATEGORIES,
	TEMPLATE_ON_DRAG_END,
	FETCH_REORDER_TEMPLATES_CATEGORIES,
	SET_REORDER_TEMPLATES_CATEGORIES_RESULT,
	SET_REORDER_TEMPLATES_CATEGORIES_FAILURE,
	FETCH_GET_TEMPLATES_CATEGORIES,
	SET_TEMPLATE_CATEGORY_SYSTEM,
	RESET_DATA,
	SET_CLONE_TEMPLATE_CATEGORY_FAILURE,
	SET_CLONE_TEMPLATE_CATEGORY_RESULT,
	SET_TEMPLATES_DATA_FAILURE,
	FETCH_CLONE_TEMPLATE_CATEGORY,
	FETCH_GET_TEMPLATES_CATEGORIES_IDS,
	TYPE_TEMPLATE,
	SET_TEMPLATE_SYSTEM,
	SET_TEMPLATE_TYPE,
	SET_GET_TEMPLATE_DETAILS_RESULT,
	TYPE_ACTIVITY
} from "../actions/types";
import { t } from "../services/i18n";

const InitialState = new Record({
	isFetchingList: false,
	isFetching: false,
	isFetchingCategories: false,
	categoriesNames: [],
	systemTemplatesCategoriesNames: [],
	systemActivitiesCategoriesNames: [],
	systemProposalsCategoriesNames: [],
	templatesCategoriesNames: [],
	activitiesCategoriesNames: [],
	proposalsCategoriesNames: [],
	_id: null,
	name: null,
	lang: null,
	error: null,
	nameError: null,
	nameErrorMsg: null,
	snackInfoMsg: null,
	snackErrorMsg: null,
	order: 0,
	newWizard: null,
	categories: {},
	areCategoriesReordered: false,
	system: false,
	type: null
});

const initialState = new InitialState();
export default function uploadReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_ADD_TEMPLATE_CATEGORY ||
				action.payload === FETCH_UPDATE_TEMPLATE_CATEGORY ||
				action.payload === FETCH_DELETE_TEMPLATE_CATEGORY ||
				action.payload === FETCH_REORDER_TEMPLATES_CATEGORIES ||
				action.payload === FETCH_CLONE_TEMPLATE_CATEGORY
			) {
				return state
					.set("isFetching", true)
					.set("snackInfoMsg", null)
					.set("snackErrorMsg", null);
			}
			if (action.payload === FETCH_GET_TEMPLATES_CATEGORIES_NAMES) {
				return state
					.set("isFetchingList", true)
					.set("newWizard", null)
					.set("snackInfoMsg", null)
					.set("snackErrorMsg", null);
			}
			if (action.payload === FETCH_GET_TEMPLATES_CATEGORIES_IDS) {
				return state
					.set("isFetchingCategories", true)
					.set("snackInfoMsg", null)
					.set("snackErrorMsg", null);
			}
			if (action.payload === FETCH_GET_TEMPLATES_CATEGORIES) {
				return state.set("areCategoriesReordered", false);
			}
			break;

		case RESET_DATA:
			return initialState;

		case RESET_TEMPLATE_CATEGORY:
			return initialState
				.set("categoriesNames", state.categoriesNames)
				.set(
					"systemTemplatesCategoriesNames",
					state.systemTemplatesCategoriesNames
				)
				.set(
					"systemActivitiesCategoriesNames",
					state.systemActivitiesCategoriesNames
				)
				.set(
					"systemProposalsCategoriesNames",
					state.systemProposalsCategoriesNames
				)
				.set("templatesCategoriesNames", state.templatesCategoriesNames)
				.set("activitiesCategoriesNames", state.activitiesCategoriesNames)
				.set("proposalsCategoriesNames", state.proposalsCategoriesNames)
				.set("categories", state.categories);

		case SET_TEMPLATE_TYPE:
			return state.set("type", action.payload);

		case SET_TEMPLATE_SYSTEM:
		case SET_TEMPLATE_CATEGORY_SYSTEM:
			return state.set("system", action.payload);

		case SET_GET_TEMPLATE_DETAILS_RESULT: {
			const { system } = action.payload;
			return state.set("system", system);
		}

		case SET_TEMPLATE_CATEGORY:
			return state.set("_id", action.payload).set("newWizard", "update");

		case SET_TEMPLATE_CATEGORY_NAME_ERROR:
			return state.set("nameErrorMsg", t("template:namemandatory"));

		case SET_TEMPLATES_CATEGORIES_IDS_RESULT:
			switch (state.type) {
				case TYPE_TEMPLATE: {
					if (state.system) {
						return state.set("systemTemplatesCategoriesNames", action.payload);
					} else {
						return state.set("templatesCategoriesNames", action.payload);
					}
				}
				case TYPE_ACTIVITY: {
					if (state.system) {
						return state.set("systemActivitiesCategoriesNames", action.payload);
					} else {
						return state.set("activitiesCategoriesNames", action.payload);
					}
				}
				default: {
					if (state.system) {
						return state.set("systemProposalsCategoriesNames", action.payload);
					} else {
						return state.set("proposalsCategoriesNames", action.payload);
					}
				}
			}

		case SET_TEMPLATES_CATEGORIES_NAMES_RESULT:
			return state.set("categoriesNames", action.payload);

		case SET_TEMPLATE_CATEGORY_NAME_FR:
			return state
				.set("nameError", null)
				.set("name", action.payload.toUpperCase());

		case SET_TEMPLATE_CATEGORY_NAME_EN: {
			let en = state.get("lang") ? state.get("lang").en : {};
			if (action.payload) {
				en = {
					en: {
						...en,
						name: action.payload.toUpperCase()
					}
				};
			}
			return state.set("lang", en);
		}

		case SET_ADD_TEMPLATE_CATEGORY_RESULT:
			return initialState.set("snackInfoMsg", t("template:addcategorysuccess"));

		case SET_REORDER_TEMPLATES_CATEGORIES_RESULT:
			return initialState.set(
				"snackInfoMsg",
				t("template:reordercategorysuccess")
			);

		case SET_CLONE_TEMPLATE_CATEGORY_RESULT:
			return initialState.set(
				"snackInfoMsg",
				t("template:clonecategorysuccess")
			);

		case SET_REORDER_TEMPLATES_CATEGORIES_FAILURE:
			return state.set("snackErrorMsg", t("template:reordercategoryerror"));

		case SET_CLONE_TEMPLATE_CATEGORY_FAILURE:
			return state.set("snackErrorMsg", t("template:clonecategoryerror"));

		case SET_UPDATE_TEMPLATE_CATEGORY_RESULT:
			return initialState
				.set("snackInfoMsg", t("template:updatecategorysuccess"))
				.set("templatesCategoriesNames", [])
				.set("acitivitiesCategoriesNames", []);

		case SET_DELETE_TEMPLATE_CATEGORY_RESULT:
			return initialState
				.set("snackInfoMsg", t("template:deletecategorysuccess"))
				.set("templatesCategoriesNames", [])
				.set("acitivitiesCategoriesNames", []);

		case SET_TEMPLATES_DATA_FAILURE:
			return state.set("snackErrorMsg", t("template:templateerror"));

		case SET_UPDATE_TEMPLATE_CATEGORY_FAILURE:
			return state.set("snackErrorMsg", t("template:updatecategoryerror"));

		case SET_DELETE_TEMPLATE_CATEGORY_FAILURE:
			return state.set("snackErrorMsg", t("template:deletecategoryerror"));

		case SET_ADD_TEMPLATE_CATEGORY_FAILURE: {
			const errorMsg =
				action.payload && action.payload.message ? action.payload.message : "";

			if (errorMsg.endsWith("409")) {
				return state
					.set("nameError", "Ce nom de catégorie de modèles existe déjà !")
					.set("snackErrorMsg", t("template:addcategoryerror"));
			} else {
				return state.set("snackErrorMsg", t("template:addcategoryerror"));
			}
		}

		case SET_TEMPLATE_CATEGORY_ORDER:
			return state.set("order", action.payload);

		case SET_TEMPLATES_CATEGORIES:
			return state.set("categories", {
				...action.entities.categories
			});

		case TEMPLATE_ON_DRAG_END: {
			const { source, destination, draggableId, type } = action.result;
			if (destination) {
				if (type === "TEMPLATE") {
					const categories = { ...state.categories };
					if (source.droppableId === destination.droppableId) {
						const category = categories[source.droppableId];
						const templateIds = Array.from(category.templateIds);
						templateIds.splice(source.index, 1);
						templateIds.splice(destination.index, 0, draggableId);
						category.templateIds = templateIds;
						categories[source.droppableId] = category;
					} else {
						const categorySource = categories[source.droppableId];
						const categoryDestination = categories[destination.droppableId];

						const templateIdsSource = Array.from(categorySource.templateIds);
						const templateIdsDestination = Array.from(
							categoryDestination.templateIds
						);
						templateIdsSource.splice(source.index, 1);
						templateIdsDestination.splice(destination.index, 0, draggableId);
						categorySource.templateIds = templateIdsSource;
						categoryDestination.templateIds = templateIdsDestination;
						categories[source.droppableId] = categorySource;
						categories[destination.droppableId] = categoryDestination;
					}
					return state
						.set("categories", categories)
						.set("areCategoriesReordered", true);
				} else {
					// Type CATEGORY
					const categoriesIds = Object.keys(state.categories);
					// Add 1 to index due to unused items
					const categoryId = categoriesIds.splice(source.index + 1, 1);
					categoriesIds.splice(destination.index + 1, 0, categoryId);
					const categories = {};
					// eslint-disable-next-line
					for (const id of categoriesIds) {
						categories[id] = { ...state.categories[id] };
					}
					return state
						.set("categories", categories)
						.set("areCategoriesReordered", true);
				}
			}
			// dropped outside the list
			break;
		}

		case API_END:
			if (action.payload === FETCH_GET_TEMPLATES_CATEGORIES_NAMES) {
				return state.set("isFetchingList", false);
			}
			if (
				action.payload === FETCH_ADD_TEMPLATE_CATEGORY ||
				action.payload === FETCH_UPDATE_TEMPLATE_CATEGORY ||
				action.payload === FETCH_DELETE_TEMPLATE_CATEGORY ||
				action.payload === FETCH_REORDER_TEMPLATES_CATEGORIES ||
				action.payload === FETCH_CLONE_TEMPLATE_CATEGORY
			) {
				return state.set("isFetching", false);
			}
			if (action.payload === FETCH_GET_TEMPLATES_CATEGORIES_IDS) {
				return state.set("isFetchingCategories", false);
			}
			break;

		default:
			return state;
	}
	return state;
}
