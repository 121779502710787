import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Lock from "@material-ui/icons/Lock";
import AddAlert from "@material-ui/icons/AddAlert";

import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import Activity from "components/DigiHapi/Activity.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import commonStyles from "assets/jss/DigiHapi/commonStyles";

// Redux
import { useDispatch, useSelector } from "react-redux";

import {
	SET_USER_NEW_PASSWORD,
	SET_USER_NEW_PASSWORD_CONFIRM,
	SET_PASSWORD,
	CLEAR_PASSWORD_VALIDATE,
	VALIDATE_PASSWORD_INPUTS
} from "actions/types";
import { fetchChangePassword, fetchLogout } from "actions/login";

const styles = {
	...regularFormsStyle,
	...commonStyles
};

const useStyles = makeStyles(styles);

export default function PasswordPage(props) {
	const classes = useStyles();

	const dispatch = useDispatch();

	const {
		isFetching,
		password,
		newpassword,
		newpasswordConfirm,
		passwordErrorMsg,
		newpasswordErrorMsg,
		snackInfoMsg,
		snackErrorMsg,
		isClearPasswordValidated,
		isPwdValid,
		strategy,
		token
	} = useSelector(state => state.loginReducer);

	const { user } = useSelector(state => state.profileReducer);

	useEffect(() => {
		if (isClearPasswordValidated) {
			dispatch({
				type: VALIDATE_PASSWORD_INPUTS
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClearPasswordValidated]);

	useEffect(() => {
		if (isPwdValid) {
			dispatch(
				fetchChangePassword({
					email: user.email,
					password,
					newpassword,
					token
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isPwdValid]);

	const [br, setBr] = useState(false);
	const [message, setMessage] = useState(null);
	const [color, setColor] = useState("info");

	useEffect(() => {
		if (snackInfoMsg || snackErrorMsg) {
			setBr(true);
			setMessage(snackInfoMsg ? snackInfoMsg : snackErrorMsg);
			setColor(snackInfoMsg ? "info" : "danger");
			if (snackInfoMsg) {
				setTimeout(() => dispatch(fetchLogout()), 5000);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [snackInfoMsg, snackErrorMsg]);

	const changePassword = e => {
		e.preventDefault();
		dispatch({
			type: CLEAR_PASSWORD_VALIDATE
		});
	};

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={6}>
				<Card>
					<CardHeader color="primary" icon>
						<CardIcon color="primary">
							<Lock />
						</CardIcon>
						<h4 className={classes.cardIconTitle}>
							Changement de mot de passe
						</h4>
					</CardHeader>
					<CardBody>
						<GridContainer>
							{(strategy === "local" || !token) && (
								<GridItem xs={12} sm={12} md={12}>
									<CustomInput
										labelText="Mot de passe actuel *"
										id="password"
										formControlProps={{
											fullWidth: true
										}}
										error={passwordErrorMsg !== null}
										helperText={passwordErrorMsg}
										inputProps={{
											value: password ? password : "",
											onChange: e =>
												dispatch({
													type: SET_PASSWORD,
													value: e.target.value
												}),
											type: "password",
											autoComplete: "off"
										}}
									/>
								</GridItem>
							)}
							<GridItem xs={12} sm={12} md={6}>
								<CustomInput
									labelText="Nouveau mot de passe *"
									id="newpassword"
									formControlProps={{
										fullWidth: true
									}}
									success={newpasswordErrorMsg === null && newpassword !== null}
									error={newpasswordErrorMsg !== null}
									helperText={newpasswordErrorMsg}
									inputProps={{
										value: newpassword ? newpassword : "",
										onChange: e =>
											dispatch({
												type: SET_USER_NEW_PASSWORD,
												value: e.target.value
											}),
										type: "password",
										autoComplete: "off"
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={6}>
								<CustomInput
									labelText="Confirmer nouveau mot de passe *"
									id="confirmnewpassword"
									formControlProps={{
										fullWidth: true
									}}
									success={newpasswordErrorMsg === null && newpassword !== null}
									error={newpasswordErrorMsg !== null}
									helperText={newpasswordErrorMsg}
									inputProps={{
										value: newpasswordConfirm ? newpasswordConfirm : "",
										onChange: e =>
											dispatch({
												type: SET_USER_NEW_PASSWORD_CONFIRM,
												value: e.target.value
											}),
										type: "password",
										autoComplete: "off"
									}}
								/>
							</GridItem>
						</GridContainer>
						<Snackbar
							place="br"
							color={color}
							icon={AddAlert}
							message={message ? message : ""}
							open={br}
							closeNotification={() => setBr(false)}
							close
						/>
					</CardBody>
					<CardFooter product>
						<div className={classes.w100}>
							<Button
								className={classes.floatRight}
								color="primary"
								disabled={isFetching}
								onClick={changePassword}
							>
								{isFetching ? <Activity /> : "Modifier"}
							</Button>
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		</GridContainer>
	);
}
