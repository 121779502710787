import {
	API_START,
	API_END,
	FETCH_GET_NEIGHBORHOODS,
	REMOVE_NEIGHBORHOOD_IMAGE,
	SET_NEIGHBORHOOD_FILES_IMAGES,
	SET_NEIGHBORHOOD_NAME,
	SET_NEIGHBORHOOD_NAME_ERROR,
	SET_NEIGHBORHOOD_LATITUDE,
	SET_NEIGHBORHOOD_LONGITUDE,
	FETCH_ADD_NEIGHBORHOOD,
	SET_FETCH_ADD_NEIGHBORHOOD_RESULT,
	SET_FETCH_ADD_NEIGHBORHOOD_FAILURE,
	SET_NEIGHBORHOODMAP_FILES_IMAGES,
	REMOVE_NEIGHBORHOODMAP_IMAGE,
	FETCH_UPDATE_NEIGHBORHOOD,
	SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE,
	SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT,
	RESET_NEIGHBORHOOD,
	FETCH_GET_NEIGHBORHOOD_DETAILS,
	SET_NEIGHBORHOOD_DETAILS_RESULT,
	SET_NEIGHBORHOOD_ADDRESS,
	SET_NEIGHBORHOOD_ZIP_CODE,
	SET_NEIGHBORHOOD_CITY,
	SET_NEIGHBORHOOD_MAIL_ERROR,
	FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS,
	RESET_DATA,
	SET_CITY_USING_ZIPCODE_RESULT,
	SET_ADDRESS_USING_ZIPCODE_RESULT,
	SET_NEIGHBORHOOD_SELECTED_CITY,
	SET_NEIGHBORHOOD_SELECTED_ADDRESS,
	SET_ADDRESS_USING_COORDINATES_RESULT,
	SET_NEIGHBORHOOD_SELECTED_ZIPCODE,
	SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE,
	SET_FETCH_DELETE_NEIGHBORHOOD_RESULT,
	FETCH_DELETE_NEIGHBORHOOD,
	SET_NEIGHBORHOOD_REGISTER_KEY,
	// SET_CURRENT_CITY_DETAILS_RESULT,
	NEIGHBORHOOD_EQUIPMENTS_ON_DRAG_END,
	SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT,
	SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE,
	FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS,
	CLEAR_NEIGHBORHOOD_VALIDATE,
	VALIDATE_NEIGHBORHOOD_INPUTS,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	FETCH_REGISTER_NEIGHBORHOOD,
	SET_REGISTER_NEIGHBORHOOD_RESULT,
	SET_REGISTER_NEIGHBORHOOD_FAILURE,
	SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME,
	SET_NEIGHBORHOOD_CHANNELS,
	ADD_NEIGHBORHOOD_CHANNEL,
	DELETE_NEIGHBORHOOD_CHANNEL,
	ADD_NEIGHBORHOOD_CITY,
	DELETE_NEIGHBORHOOD_CITY,
	SET_CITIES,
	SET_NEIGHBORHOOD_RESIDENCE,
	SET_NEIGHBORHOOD_BUILDING,
	SET_NEIGHBORHOOD_DISTRICT
} from "actions/types";
import { t } from "services/i18n";
const OTHER = "Autre ...";

const initialState = {
	isFetching: false,
	isFetchingList: false,
	_id: null,
	name: null,
	nameErrorMsg: null,
	addresses: {},
	customAddress: null,
	address: null,
	selectedAddress: OTHER,
	addressUpdated: false,
	zipCode: null,
	zipCodes: {},
	customZipCode: null,
	selectedZipCode: OTHER,
	zipCodeUpdated: false,
	customCity: null,
	city: null,
	selectedCity: OTHER,
	cities: {},
	mail: null,
	mailErrorMsg: null,
	latitude: null,
	longitude: null,
	phone: null,
	phoneErrorMsg: null,
	phoneState: null,
	registerkey: null,
	files: [],
	snackErrorMsg: null,
	snackInfoMsg: null,
	newWizard: null,
	logoUri: null,
	mapUri: null,
	mapSmallUri: null,
	// city: null,
	equipments: {},
	areEquipmentsReordered: false,
	source: null,
	destination: null,
	clearValidate: false,
	isValid: false,
	firstname: null,
	lastname: null,
	lastnameErrorMsg: null,
	firstnameErrorMsg: null,
	addressErrorMsg: null,
	zipCodeErrorMsg: null,
	cityErrorMsg: null,
	deleteConfirmationName: "",
	channelsIds: [],
	channels: [],
	channelsSuggestions: [],
	channelsEntities: {},
	citiesIds: [],
	citiesTags: [],
	citiesSuggestions: [],
	citiesEntities: {},
	building: null,
	residence: null,
	district: null
};

function validatePhone(phone) {
	let phoneState = "success";
	const phoneRegex =
		/^[+][0-9]{1,4}[" "][(][0][)][1-9][" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}$/g;
	if (phone && !phoneRegex.test(phone)) {
		phoneState = "error";
	}
	return phoneState;
}

function validateEmail(email) {
	var emailRex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (emailRex.test(email)) {
		return "success";
	}
	return "error";
}

function keepState(state) {
	return {
		...initialState,
		channelsSuggestions: Object.values(state.channelsEntities).map(obj => ({
			name: obj.name,
			id: obj._id
		})),
		channelsEntities: state.channelsEntities,
		citiesSuggestions: Object.values(state.citiesEntities).map(obj => ({
			name: obj.name,
			id: obj._id
		})),
		citiesEntities: state.citiesEntities
	};
}

export default function cityReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (action.payload === FETCH_GET_NEIGHBORHOODS) {
				return {
					...state,
					isFetchingList: true
				};
			}
			if (
				action.payload === FETCH_GET_NEIGHBORHOOD_DETAILS ||
				action.payload === FETCH_ADD_NEIGHBORHOOD ||
				action.payload === FETCH_UPDATE_NEIGHBORHOOD ||
				action.payload === FETCH_DELETE_NEIGHBORHOOD ||
				action.payload === FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS ||
				action.payload === FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS ||
				action.payload === FETCH_REGISTER_NEIGHBORHOOD
			) {
				return {
					...state,
					isFetching: true,
					newWizard: null,
					snackInfoMsg: null,
					snackErrorMsg: null
				};
			}
			break;

		case RESET_DATA:
		case RESET_NEIGHBORHOOD:
			return keepState(state);

		case SET_NEIGHBORHOOD_CHANNELS: {
			return {
				...state,
				channelsSuggestions: Object.values(action.entities.channels).map(
					obj => ({
						name: obj.name,
						id: obj._id
					})
				),
				channelsEntities: action.entities.channels
			};
		}

		case SET_CITIES: {
			return {
				...state,
				citiesSuggestions: Object.values(action.entities.cities).map(obj => ({
					name: obj.name,
					id: obj._id
				})),
				citiesEntities: action.entities.cities
			};
		}

		case ADD_NEIGHBORHOOD_CHANNEL: {
			const channelsSuggestions = state.channelsSuggestions.filter(
				(suggestion, index) => suggestion.id !== action.value.id
			);
			return {
				...state,
				channels: [...state.channels, action.value],
				channelsIds: [...state.channelsIds, action.value.id],
				channelsSuggestions
			};
		}

		case ADD_NEIGHBORHOOD_CITY: {
			const citiesSuggestions = state.citiesSuggestions.filter(
				suggestion => suggestion.id !== action.value.id
			);
			return {
				...state,
				citiesTags: [...state.citiesTags, action.value],
				citiesIds: [...state.citiesIds, action.value.id],
				citiesSuggestions
			};
		}

		case DELETE_NEIGHBORHOOD_CHANNEL: {
			const channelsSuggestions = [
				...state.channelsSuggestions,
				state.channels[action.value]
			];
			return {
				...state,
				channels: state.channels.filter(
					(channel, index) => index !== action.value
				),
				channelsIds: state.channelsIds.filter(
					(channel, index) => index !== action.value
				),
				channelsSuggestions
			};
		}

		case DELETE_NEIGHBORHOOD_CITY: {
			const citiesSuggestions = [
				...state.citiesSuggestions,
				state.citiesTags[action.value]
			];
			return {
				...state,
				citiesTags: state.citiesTags.filter(
					(city, index) => index !== action.value
				),
				citiesIds: state.citiesIds.filter(
					(city, index) => index !== action.value
				),
				citiesSuggestions
			};
		}

		case SET_DELETE_NEIGHBORHOOD_CONFIRMATION_NAME:
			return {
				...state,
				deleteConfirmationName: action.value
			};

		// case SET_CURRENT_CITY_DETAILS_RESULT:
		//   return {
		//     ...state,
		//     city: Object.values(action.entities.city)[0],
		//     equipments: action.entities.equipments,
		//     areEquipmentsReordered: false
		//   };

		case NEIGHBORHOOD_EQUIPMENTS_ON_DRAG_END: {
			const { source, destination, draggableId } = action.result;
			if (destination) {
				const equipmentsIds = Object.keys(state.equipments);
				if (source.droppableId === "used") {
					// Add 1 to index due to unused items
					const equipmentId = equipmentsIds.splice(source.index, 1);
					if (destination.droppableId === "used") {
						equipmentsIds.splice(destination.index, 0, equipmentId);
					}
				} else if (destination.droppableId === "used") {
					equipmentsIds.splice(destination.index, 0, draggableId);
				}
				const equipments = {};
				// eslint-disable-next-line
				for (const id of equipmentsIds) {
					if (state.equipments[id]) {
						equipments[id] = { ...state.equipments[id] };
					} else {
						equipments[id] = { _id: id, name: action.name };
					}
				}
				return {
					...state,
					equipments,
					areEquipmentsReordered: true,
					source,
					destination
				};
			}
			// dropped outside the list
			break;
		}

		case SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_RESULT:
			return {
				...state,
				snackInfoMsg: t("equipment:reordersuccess"),
				areEquipmentsReordered: false
			};

		case SET_REORDER_NEIGHBORHOOD_EQUIPMENTS_FAILURE:
			return {
				...state,
				snackErrorMsg: t("equipment:reordererror")
			};

		case SET_ADDRESS_USING_ZIPCODE_RESULT:
		case SET_ADDRESS_USING_COORDINATES_RESULT: {
			const gouvData = action.payload;
			if (gouvData && gouvData.features && gouvData.features.length > 0) {
				// Sort data by distance
				const sortedData = gouvData.features.sort(function (obj1, obj2) {
					if (obj1.properties && obj2.properties) {
						return obj1.properties.distance - obj2.properties.distance;
					} else {
						return 0;
					}
				});
				const addresses = {};
				const zipCodes = {};
				const cities = {};
				let address = null;
				// eslint-disable-next-line
				for (const val of sortedData) {
					if (
						val.properties !== null &&
						val.properties.postcode !== null &&
						val.properties.name !== null &&
						val.properties.city !== null
					) {
						if (!address) {
							address = val.properties.name;
						}
						addresses[val.properties.name] = {
							city: val.properties.city,
							zipCode: val.properties.postcode,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						zipCodes[val.properties.postcode] = {
							city: val.properties.city,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						cities[val.properties.city] = {
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
					}
				}
				if (Object.keys(addresses).length > 0) {
					return {
						...state,
						addressUpdated: false,
						addresses,
						selectedAddress: address,
						address,
						zipCodes,
						selectedZipCode: addresses[address].zipCode,
						zipCode: addresses[address].zipCode,
						cities,
						selectedCity: addresses[address].city,
						city: addresses[address].city,
						longitude: addresses[address].longitude,
						latitude: addresses[address].latitude
					};
				}
			}
			break;
		}

		case SET_CITY_USING_ZIPCODE_RESULT: {
			const gouvData = action.payload;
			if (gouvData && gouvData.features && gouvData.features.length > 0) {
				const zipCodes = {};
				const cities = {};
				let zipCode = null;
				// eslint-disable-next-line
				for (const val of gouvData.features) {
					if (
						val.properties !== null &&
						val.properties.postcode === state.zipCode &&
						val.geometry !== null &&
						val.geometry.coordinates !== null &&
						val.geometry.coordinates.length === 2 &&
						!cities[val.properties.city]
					) {
						zipCodes[val.properties.postcode] = {
							city: val.properties.city,
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
						if (!zipCode) {
							zipCode = val.properties.postcode;
						}
						cities[val.properties.city] = {
							longitude: val.geometry.coordinates[0],
							latitude: val.geometry.coordinates[1]
						};
					}
				}
				if (Object.keys(cities).length > 0) {
					return {
						...state,
						zipCodeUpdated: false,
						zipCodes,
						selectedZipCode: zipCode,
						zipCode,
						cities,
						selectedCity: zipCodes[zipCode].city,
						city: zipCodes[zipCode].city,
						longitude: zipCodes[zipCode].longitude,
						latitude: zipCodes[zipCode].latitude
					};
				}
			}
			return {
				...state,
				zipCodeUpdated: false
			};
		}

		case SET_NEIGHBORHOOD_SELECTED_ADDRESS: {
			if (action.payload === OTHER) {
				return {
					...state,
					selectedAddress: action.payload,
					address: state.customAddress
				};
			} else {
				const address = state.addresses[action.payload];
				return {
					...state,
					selectedZipCode: address.zipCode,
					zipCode: address.zipCode,
					city: address.city,
					selectedCity: address.city,
					selectedAddress: action.payload,
					address: action.payload,
					longitude: address.longitude,
					latitude: address.latitude
				};
			}
		}

		case SET_NEIGHBORHOOD_SELECTED_ZIPCODE: {
			if (action.payload === OTHER) {
				return {
					...state,
					selectedZipCode: action.payload,
					zipCode: state.customZipCode
				};
			} else {
				const zipCode = state.zipCodes[action.payload];
				return {
					...state,
					selectedZipCode: action.payload,
					zipCode: action.payload,
					city: zipCode && zipCode.city,
					selectedCity: zipCode && zipCode.city,
					longitude: zipCode && zipCode.longitude,
					latitude: zipCode && zipCode.latitude
				};
			}
		}

		case SET_NEIGHBORHOOD_SELECTED_CITY:
			if (action.payload === OTHER) {
				return {
					...state,
					selectedCity: action.payload,
					city: state.customCity
				};
			} else {
				return {
					...state,
					selectedCity: action.payload,
					city: action.payload,
					longitude: state.cities[action.payload].longitude,
					latitude: state.cities[action.payload].latitude
				};
			}

		case SET_NEIGHBORHOOD_DETAILS_RESULT: {
			const {
				_id,
				name,
				address,
				zipCode,
				city,
				latitude,
				longitude,
				registerkey,
				mapUri,
				mapSmallUri,
				channelsIds,
				citiesIds,
				building,
				residence,
				district
			} = action.payload;

			const channels = [];
			const channelsSuggestions = [...state.channelsSuggestions];
			if (channelsIds) {
				for (const channelId of channelsIds) {
					channels.push({
						name: state.channelsEntities[channelId].name,
						id: state.channelsEntities[channelId]._id
					});
					const index = channelsSuggestions.findIndex(
						obj => obj.id === state.channelsEntities[channelId]._id
					);
					if (index >= 0) {
						channelsSuggestions.splice(index, 1);
					}
				}
			}
			const citiesTags = [];
			const citiesSuggestions = [...state.citiesSuggestions];
			if (citiesIds) {
				for (const cityId of citiesIds) {
					citiesTags.push({
						name: state.citiesEntities[cityId].name,
						id: state.citiesEntities[cityId]._id
					});
					const index = citiesSuggestions.findIndex(
						obj => obj.id === state.citiesEntities[cityId]._id
					);
					if (index >= 0) {
						citiesSuggestions.splice(index, 1);
					}
				}
			}
			return {
				...state,
				_id,
				name,
				address,
				customAddress: address,
				zipCode,
				customZipCode: zipCode,
				city,
				customCity: city,
				latitude,
				longitude,
				registerkey,
				mapUri,
				mapSmallUri,
				newWizard: "update",
				channelsIds: channelsIds ? channelsIds : [],
				citiesIds: citiesIds ? citiesIds : [],
				channels,
				channelsSuggestions,
				citiesTags,
				citiesSuggestions,
				building,
				residence,
				district
			};
		}

		case SET_NEIGHBORHOOD_LATITUDE:
			return {
				...state,
				latitude: action.value
			};

		case SET_NEIGHBORHOOD_LONGITUDE:
			return {
				...state,
				longitude: action.value
			};

		case CLEAR_NEIGHBORHOOD_VALIDATE:
			return {
				...state,
				nameErrorMsg: null,
				phoneErrorMsg: null,
				mailErrorMsg: null,
				lastnameErrorMsg: null,
				firstnameErrorMsg: null,
				addressErrorMsg: null,
				zipCodeErrorMsg: null,
				cityErrorMsg: null,
				clearValidate: true,
				isValid: false
			};

		case SET_FIRST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					firstname: action.value,
					firstnameErrorMsg: null
				};
			}
			break;

		case SET_LAST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					lastname: action.value,
					lastnameErrorMsg: null
				};
			}
			break;

		case SET_NEIGHBORHOOD_BUILDING:
			return {
				...state,
				building: action.value
			};

		case SET_NEIGHBORHOOD_RESIDENCE:
			return {
				...state,
				residence: action.value
			};

		case SET_NEIGHBORHOOD_DISTRICT:
			return {
				...state,
				district: action.value
			};

		case VALIDATE_NEIGHBORHOOD_INPUTS: {
			const firstnameErrorMsg =
				state.firstname && state.firstname.trim().length > 0
					? null
					: t("profile:firstnamemandatory");
			const lastnameErrorMsg =
				state.lastname && state.lastname.trim().length > 0
					? null
					: t("profile:lastnamemandatory");
			const addressErrorMsg =
				state.address && state.address.trim().length > 0
					? null
					: t("neighborhood:addressmandatory");
			const zipCodeErrorMsg =
				state.zipCode && state.zipCode.trim().length > 0
					? null
					: t("neighborhood:zipCodemandatory");
			const cityErrorMsg =
				state.city && state.city.trim().length > 0
					? null
					: t("neighborhood:citymandatory");
			const nameErrorMsg =
				state.name && state.name.trim().length > 0
					? null
					: t("neighborhood:namemandatory");

			let mailErrorMsg = null;
			if (!state.mail) {
				mailErrorMsg = t("login:missingemail");
			} else if (validateEmail(state.mail) === "error") {
				mailErrorMsg = t("neighborhood:invalidmail");
			}
			let phoneErrorMsg = null;
			if (!state.phone) {
				phoneErrorMsg = t("profile:phonemandatory");
			} else if (validatePhone(state.phone) === "error") {
				phoneErrorMsg = t("profile:invalidphone");
			}
			return {
				...state,
				clearValidate: false,
				firstnameErrorMsg,
				lastnameErrorMsg,
				addressErrorMsg,
				zipCodeErrorMsg,
				cityErrorMsg,
				mailErrorMsg,
				nameErrorMsg,
				phoneErrorMsg,
				isValid:
					!firstnameErrorMsg &&
					!lastnameErrorMsg &&
					!addressErrorMsg &&
					!zipCodeErrorMsg &&
					!cityErrorMsg &&
					!mailErrorMsg &&
					!nameErrorMsg &&
					!phoneErrorMsg
			};
		}

		case SET_NEIGHBORHOOD_MAIL_ERROR:
			return {
				...state,
				mailErrorMsg: t("neighborhood:invalidmail")
			};

		case SET_NEIGHBORHOOD_ADDRESS:
			return {
				...state,
				customAddress: action.value,
				address: action.value,
				addressErrorMsg: null,
				addressUpdated: true
			};

		case SET_NEIGHBORHOOD_ZIP_CODE:
			return {
				...state,
				customZipCode: action.value,
				zipCode: action.value,
				zipCodeUpdated: true,
				zipCodeErrorMsg: null
			};

		case SET_NEIGHBORHOOD_CITY:
			return {
				...state,
				customCity: action.value,
				city: action.value,
				cityErrorMsg: null
			};

		case SET_NEIGHBORHOOD_FILES_IMAGES:
			return {
				...state,
				files: [
					...state.files.filter(file => file.out !== "logoUri"),
					...action.payload
				],
				logoUri: null
			};

		case REMOVE_NEIGHBORHOOD_IMAGE: {
			return {
				...state,
				files: [...state.files.filter(file => file.out !== "logoUri")],
				logoUri: null
			};
		}

		case SET_NEIGHBORHOODMAP_FILES_IMAGES:
			return {
				...state,
				files: [
					...state.files.filter(
						file => file.out !== "mapUri" && file.out !== "mapSmallUri"
					),
					...action.payload
				],
				mapUri: null,
				mapSmallUri: null
			};

		case REMOVE_NEIGHBORHOODMAP_IMAGE:
			return {
				...state,
				files: [
					...state.files.filter(
						file => file.out !== "mapUri" && file.out !== "mapSmallUri"
					)
				],
				mapUri: null,
				mapSmallUri: null
			};

		case SET_NEIGHBORHOOD_NAME:
			return {
				...state,
				name: action.value,
				nameErrorMsg: null
			};

		case SET_NEIGHBORHOOD_REGISTER_KEY:
			return {
				...state,
				registerkey: action.value
			};

		case SET_NEIGHBORHOOD_NAME_ERROR:
			return {
				...state,
				nameErrorMsg: t("neighborhood:namemandatory")
			};

		case SET_FETCH_ADD_NEIGHBORHOOD_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("neighborhood:addsuccess")
			};

		case SET_REGISTER_NEIGHBORHOOD_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("neighborhood:registersuccess"),
				longitude: state.longitude,
				latitude: state.latitude
			};

		case SET_REGISTER_NEIGHBORHOOD_FAILURE:
			return {
				...state,
				snackErrorMsg: t("neighborhood:registererror")
			};

		case SET_FETCH_DELETE_NEIGHBORHOOD_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("neighborhood:deletesuccess")
			};

		case SET_FETCH_UPDATE_NEIGHBORHOOD_RESULT:
			return {
				...keepState(state),
				snackInfoMsg: t("neighborhood:updatesuccess")
			};

		case SET_FETCH_ADD_NEIGHBORHOOD_FAILURE:
			return {
				...state,
				snackErrorMsg: t("neighborhood:adderror")
			};

		case SET_FETCH_UPDATE_NEIGHBORHOOD_FAILURE:
			return {
				...state,
				snackErrorMsg: t("neighborhood:updateerror")
			};

		case SET_FETCH_DELETE_NEIGHBORHOOD_FAILURE:
			return {
				...state,
				snackErrorMsg: t("neighborhood:deleteerror")
			};

		case API_END:
			if (action.payload === FETCH_GET_NEIGHBORHOODS) {
				return {
					...state,
					isFetchingList: false
				};
			}
			if (
				action.payload === FETCH_GET_NEIGHBORHOOD_DETAILS ||
				action.payload === FETCH_ADD_NEIGHBORHOOD ||
				action.payload === FETCH_UPDATE_NEIGHBORHOOD ||
				action.payload === FETCH_DELETE_NEIGHBORHOOD ||
				action.payload === FETCH_GET_CURRENT_NEIGHBORHOOD_DETAILS ||
				action.payload === FETCH_REORDER_NEIGHBORHOOD_EQUIPMENTS ||
				action.payload === FETCH_REGISTER_NEIGHBORHOOD
			) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}
	return state;
}
