import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardFooter from "components/Card/CardFooter";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle";
import Activity from "components/DigiHapi/Activity";

const style = {
	noDecoration: {
		pointerEvents: "none",
		textDecoration: "none"
	},
	w100: {
		width: "100%"
	},
	...wizardStyle
};
class Wizard extends React.Component {
	constructor(props) {
		super(props);
		var width;
		if (this.props.steps.length === 1) {
			width = "100%";
		} else {
			if (window.innerWidth < 600) {
				if (this.props.steps.length !== 3) {
					width = "50%";
				} else {
					width = 100 / 3 + "%";
				}
			} else {
				if (this.props.steps.length === 2) {
					width = "50%";
				} else {
					width = 100 / 3 + "%";
				}
			}
		}
		this.state = {
			currentStep: this.props.currentStep ? this.props.currentStep : 0,
			color: this.props.color,
			nextButton: this.props.steps.length > 1 ? true : false,
			previousButton: false,
			finishButton: this.props.steps.length === 1 ? true : false,
			width: width,
			movingTabStyle: {
				transition: "transform 0s"
			},
			allStates: {}
		};
		this.navigationStepChange = this.navigationStepChange.bind(this);
		this.refreshAnimation = this.refreshAnimation.bind(this);
		this.previousButtonClick = this.previousButtonClick.bind(this);
		this.previousButtonClick = this.previousButtonClick.bind(this);
		this.finishButtonClick = this.finishButtonClick.bind(this);
		this.updateWidth = this.updateWidth.bind(this);
	}
	wizard = React.createRef();
	componentDidMount() {
		this.refreshAnimation(0);
		window.addEventListener("resize", this.updateWidth);
		if (this.state.currentStep) {
			this.navigationStepChange(this.state.currentStep);
		}
	}
	componentWillUnmount() {
		window.removeEventListener("resize", this.updateWidth);
	}
	updateWidth() {
		this.refreshAnimation(this.state.currentStep);
	}
	navigationStepChange(key) {
		if (this.props.steps) {
			var validationState = true;
			if (key > this.state.currentStep) {
				for (var i = this.state.currentStep; i < key; i++) {
					if (this[this.props.steps[i].stepId].sendState !== undefined) {
						this.setState({
							allStates: {
								...this.state.allStates,
								[this.props.steps[i].stepId]:
									this[this.props.steps[i].stepId].sendState()
							}
						});
					}
					if (
						this[this.props.steps[i].stepId].isValidated !== undefined &&
						this[this.props.steps[i].stepId].isValidated() === false
					) {
						validationState = false;
						this.setState(
							{
								currentStep: i,
								previousButton: i > 0 ? true : false
							},
							() =>
								this.props.onChangeCurrentStep &&
								this.props.onChangeCurrentStep(this.state.currentStep)
						);
						this.navigationStepChange(i);
						break;
					}
				}
			}
			if (validationState) {
				this.setState(
					{
						currentStep: key,
						nextButton: this.props.steps.length > key + 1 ? true : false,
						previousButton: key > 0 ? true : false,
						finishButton: this.props.steps.length === key + 1 ? true : false
					},
					() =>
						this.props.onChangeCurrentStep &&
						this.props.onChangeCurrentStep(this.state.currentStep)
				);
				this.refreshAnimation(key);
			}
		}
	}
	nextButtonClick() {
		if (
			(this.props.validate &&
				((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
					undefined &&
					this[
						this.props.steps[this.state.currentStep].stepId
					].isValidated()) ||
					this[this.props.steps[this.state.currentStep].stepId].isValidated ===
						undefined)) ||
			this.props.validate === undefined
		) {
			if (
				this[this.props.steps[this.state.currentStep].stepId].sendState !==
				undefined
			) {
				this.setState({
					allStates: {
						...this.state.allStates,
						[this.props.steps[this.state.currentStep].stepId]:
							this[this.props.steps[this.state.currentStep].stepId].sendState()
					}
				});
			}
			var key = this.state.currentStep + 1;
			this.setState(
				{
					currentStep: key,
					nextButton: this.props.steps.length > key + 1 ? true : false,
					previousButton: key > 0 ? true : false,
					finishButton: this.props.steps.length === key + 1 ? true : false
				},
				() =>
					this.props.onChangeCurrentStep &&
					this.props.onChangeCurrentStep(this.state.currentStep)
			);
			this.refreshAnimation(key);
		}
	}
	previousButtonClick() {
		if (
			this[this.props.steps[this.state.currentStep].stepId].sendState !==
			undefined
		) {
			this.setState({
				allStates: {
					...this.state.allStates,
					[this.props.steps[this.state.currentStep].stepId]:
						this[this.props.steps[this.state.currentStep].stepId].sendState()
				}
			});
		}
		var key = this.state.currentStep - 1;
		if (key >= 0) {
			this.setState(
				{
					currentStep: key,
					nextButton: this.props.steps.length > key + 1 ? true : false,
					previousButton: key > 0 ? true : false,
					finishButton: this.props.steps.length === key + 1 ? true : false
				},
				() =>
					this.props.onChangeCurrentStep &&
					this.props.onChangeCurrentStep(this.state.currentStep)
			);
			this.refreshAnimation(key);
		}
	}
	finishButtonClick() {
		if (
			(this.props.validate === false &&
				this.props.finishButtonClick !== undefined) ||
			(this.props.validate &&
				((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
					undefined &&
					this[
						this.props.steps[this.state.currentStep].stepId
					].isValidated()) ||
					this[this.props.steps[this.state.currentStep].stepId].isValidated ===
						undefined) &&
				this.props.finishButtonClick !== undefined)
		) {
			this.setState(
				{
					allStates: {
						...this.state.allStates,
						[this.props.steps[this.state.currentStep].stepId]:
							this[this.props.steps[this.state.currentStep].stepId].sendState()
					}
				},
				() => {
					this.props.finishButtonClick(this.state.allStates);
				}
			);
		}
	}
	refreshAnimation(index) {
		var total = this.props.steps.length;
		var li_width = 100 / total;
		var total_steps = this.props.steps.length;
		var move_distance =
			this.wizard.current.children[0].offsetWidth / total_steps;
		var index_temp = index;
		var vertical_level = 0;

		var mobile_device = window.innerWidth < 600 && total > 3;

		if (mobile_device) {
			move_distance = this.wizard.current.children[0].offsetWidth / 2;
			index_temp = index % 2;
			li_width = 50;
		}

		this.setState({ width: li_width + "%" });

		var step_width = move_distance;
		move_distance = move_distance * index_temp;

		var current = index + 1;

		if (current === 1 || (mobile_device === true && index % 2 === 0)) {
			move_distance -= 8;
		} else if (
			current === total_steps ||
			(mobile_device === true && index % 2 === 1)
		) {
			move_distance += 8;
		}

		if (mobile_device) {
			vertical_level = parseInt(index / 2, 10);
			vertical_level = vertical_level * 38;
		}
		var movingTabStyle = {
			width: step_width,
			transform:
				"translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
			transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
		};
		this.setState({ movingTabStyle: movingTabStyle });
	}
	render() {
		const {
			classes,
			title,
			subtitle,
			color,
			steps,
			onClose,
			isFetching = false
		} = this.props;

		const postClasses = isFetching ? " " + classes.noDecoration : "";
		return (
			<div className={classes.wizardContainer} ref={this.wizard}>
				<Card className={classes.card} noaos>
					<div className={classes.wizardHeader}>
						<h3 className={classes.title}>{title}</h3>
						<h5 className={classes.subtitle}>{subtitle}</h5>
					</div>
					<div className={classes.wizardNavigation}>
						<ul className={classes.nav}>
							{steps.map((prop, key) => {
								return (
									<li
										className={classes.steps}
										key={key}
										style={{ width: this.state.width }}
									>
										<a
											href="#pablo"
											className={classes.stepsAnchor + postClasses}
											onClick={e => {
												e.preventDefault();
												this.navigationStepChange(key);
											}}
										>
											{prop.stepName}
										</a>
									</li>
								);
							})}
						</ul>
						<div
							className={classes.movingTab + " " + classes[color]}
							style={this.state.movingTabStyle}
						>
							{steps[this.state.currentStep].stepName}
						</div>
					</div>
					<div className={classes.content}>
						{steps.map((prop, key) => {
							const stepContentClasses = cx({
								[classes.stepContentActive]: this.state.currentStep === key,
								[classes.stepContent]: this.state.currentStep !== key
							});
							return (
								<div className={stepContentClasses} key={key}>
									<prop.stepComponent
										innerRef={node => (this[prop.stepId] = node)}
										allStates={this.state.allStates}
										disabled={isFetching}
									/>
								</div>
							);
						})}
					</div>

					<CardFooter product>
						<div className={classes.w100}>
							<div className={classes.left}>
								{this.state.previousButton ? (
									<Button
										color="primary"
										className={this.props.previousButtonClasses}
										onClick={() => this.previousButtonClick()}
										disabled={isFetching}
									>
										{this.props.previousButtonText}
									</Button>
								) : null}
							</div>
							<div className={classes.right}>
								<Button
									className={this.props.cancelButtonClasses}
									onClick={onClose}
									disabled={isFetching}
								>
									{this.props.cancelButtonText}
								</Button>
								{this.state.nextButton ? (
									<Button
										color="primary"
										className={this.props.nextButtonClasses}
										onClick={() => this.nextButtonClick()}
									>
										{this.props.nextButtonText}
									</Button>
								) : null}
								{this.state.finishButton ? (
									<Button
										color="primary"
										className={this.finishButtonClasses}
										onClick={() => this.finishButtonClick()}
										disabled={isFetching}
									>
										{isFetching ? <Activity /> : this.props.finishButtonText}
									</Button>
								) : null}
							</div>
							<div className={classes.clearfix} />
						</div>
					</CardFooter>
				</Card>
			</div>
		);
	}
}

Wizard.defaultProps = {
	color: "primary",
	title: "Here should go your title",
	subtitle: "And this would be your subtitle",
	cancelButtonText: "Annuler",
	previousButtonText: "Précédent",
	cancelButtonClasses: "",
	previousButtonClasses: "",
	nextButtonClasses: "",
	nextButtonText: "Suivant",
	finishButtonClasses: "",
	finishButtonText: "Terminer"
};

Wizard.propTypes = {
	classes: PropTypes.object.isRequired,
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			stepName: PropTypes.string.isRequired,
			stepComponent: PropTypes.object.isRequired,
			stepId: PropTypes.string.isRequired
		})
	).isRequired,
	color: PropTypes.oneOf([
		"primary",
		"warning",
		"danger",
		"success",
		"info",
		"rose",
		"blue",
		"brown",
		"beige"
	]),
	title: PropTypes.string,
	subtitle: PropTypes.string,
	previousButtonClasses: PropTypes.string,
	cancelButtonText: PropTypes.string,
	previousButtonText: PropTypes.string,
	nextButtonClasses: PropTypes.string,
	nextButtonText: PropTypes.string,
	finishButtonClasses: PropTypes.string,
	finishButtonText: PropTypes.string,
	finishButtonClick: PropTypes.func,
	validate: PropTypes.bool
};

export default withStyles(style)(Wizard);
