import {
	API_START,
	API_END,
	LOGOUT_RESULT,
	SET_PROFILE_DETAILS,
	SET_LOCATION_ID,
	SET_DATE_RANGE,
	SET_PHOTO_URI,
	SET_FIRST_NAME,
	SET_LAST_NAME,
	SET_BIRTHDATE,
	SET_ADDRESS,
	SET_ZIP_CODE,
	SET_CITY,
	SET_PHONE,
	SET_DESCRIPTION_FR,
	SET_DESCRIPTION_EN,
	SET_USER_HOBBIES,
	FETCH_UPDATE_PROFILE,
	CLEAR_PROFILE_PRO_VALIDATE,
	VALIDATE_PROFILE_PRO_INPUTS,
	CLEAR_PROFILE_VALIDATE,
	VALIDATE_PROFILE_INPUTS,
	CLEAR_PHOTO,
	DELETE_USER_RESULT,
	SET_USER_FAILURE,
	SET_COUNTRY_CODE,
	FETCH_GET_USER,
	SET_PSEUDO,
	SET_LANG,
	SET_GENDER,
	SET_COMPANY_NAME,
	SET_SIRET_CODE,
	SET_USER_PASSWORD,
	SET_USER_PASSWORD_CONFIRM,
	FETCH_REGISTER_PROFESSIONAL,
	SET_REGISTER_PROFESSIONAL_RESULT,
	SET_REGISTER_PROFESSIONAL_FAILURE,
	SET_LOGIN_WITH_FB_RESULT,
	RESET_DATA,
	SET_PROFILE_FILES_IMAGES,
	REMOVE_PROFILE_IMAGE,
	SET_PROFILE_EDIT,
	SET_UPDATE_PROFILE_FAILURE,
	SET_UPDATE_PROFILE_SUCCESS,
	SET_COMPANY_PHONE,
	FETCH_GET_GOOD_DEALS_CATEGORIES,
	FETCH_GET_CURRENT_CAMPSITE_DETAILS,
	FETCH_GET_GLOBAL_INFORMATIONS_VERSION,
	FETCH_GET_CAMPSITES,
	ROLE_ADVERTISER
} from "../actions/types";
import moment from "moment";
import { t } from "../services/i18n";

const initialState = {
	isFetching: false,
	areFetching: {},
	files: [],
	email: null,
	id: null,
	photoUri: null,
	pseudo: null,
	firstname: null,
	lastname: null,
	gender: "M",
	company: null,
	companySiret: null,
	birthdate: null,
	address: null,
	zipCode: null,
	city: null,
	companyAddress: null,
	companyZipCode: null,
	companyCity: null,
	password: null,
	passwordConfirm: null,
	country: null,
	phone: null,
	companyPhone: null,
	description: null,
	hobbiesIds: null,
	startDate: null,
	endDate: null,
	locationId: null,
	dateRangeChanged: false,
	locationIdChanged: false,
	pseudoErrorMsg: null,
	firstnameErrorMsg: null,
	lastnameErrorMsg: null,
	companyErrorMsg: null,
	siretErrorMsg: null,
	phoneErrorMsg: null,
	addressErrorMsg: null,
	zipCodeErrorMsg: null,
	cityErrorMsg: null,
	passwordErrorMsg: null,
	birthdayErrorMsg: null,
	daterangeErrorMsg: null,
	locationErrorMsg: null,
	isValid: false,
	firstLogin: false,
	role: null,
	user: null,
	defaultCountry: null,
	clearValidate: false,
	displayPassword: true,
	token: null,
	profileEdit: false,
	snackInfoMsg: null,
	snackErrorMsg: null,
	lang: null
};

function validatePhone(phone, mandatory = false) {
	let phoneErrorMsg = null;
	const phoneRegex =
		/^[+][0-9]{1,4}[" "][(][0][)][1-9][" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}[" "][0-9]{2}$/g;
	if (mandatory && (!phone || phone.trim().length === 0)) {
		phoneErrorMsg = t("profile:phonemandatory");
	} else if (phone && !phoneRegex.test(phone)) {
		phoneErrorMsg = t("profile:invalidphone");
	}
	return phoneErrorMsg;
}

function validateBirthday(birthdate) {
	let birthdayErrorMsg;

	if (!birthdate) {
		birthdayErrorMsg = t("profile:birthdatemandatory");
	} else {
		const birthday = moment(birthdate, "DD-MM-YYYY").toDate();
		let age = Math.floor(
			(new Date().getTime() - birthday.getTime()) / 31536000000
		);
		if (age < 13) {
			birthdayErrorMsg = t("profile:tooyoungerror");
		}
	}

	return birthdayErrorMsg;
}

function validateSiret(siret) {
	let siretErrorNumber = null;
	if (siret) {
		if (siret.length !== 14) {
			siretErrorNumber = t("profile:invalidsiret");
		} else {
			// Donc le SIRET est un numérique à 14 chiffres
			// Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants
			// correspondent au numéro d'établissement
			// et enfin le dernier chiffre est une clef de LUHN.
			let sum = 0;
			let tmp;
			for (var cpt = 0; cpt < siret.length; cpt++) {
				if (cpt % 2 === 0) {
					// Les positions impaires : 1er, 3è, 5è, etc...
					tmp = siret.charAt(cpt) * 2; // On le multiplie par 2
					if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
				} else tmp = siret.charAt(cpt);
				sum += parseInt(tmp);
			}
			if (sum % 10 !== 0) {
				siretErrorNumber = t("profile:invalidsiret");
			}
		}
	}
	return siretErrorNumber;
}

export default function profileReducer(state = initialState, action = {}) {
	switch (action.type) {
		case API_START:
			if (
				action.payload === FETCH_UPDATE_PROFILE ||
				action.payload === FETCH_REGISTER_PROFESSIONAL
			) {
				return {
					...state,
					snackInfoMsg: null,
					snackErrorMsg: null,
					isFetching: true
				};
			}
			if (action.payload === FETCH_GET_USER) {
				return {
					...state,
					snackInfoMsg: null,
					snackErrorMsg: null,
					isFetching: true,
					areFetching: { isFetchingGetUser: true }
				};
			}
			if (action.payload === FETCH_GET_GOOD_DEALS_CATEGORIES) {
				return {
					...state,
					areFetching: {
						...state.areFetching,
						isFetchingGetGoodDealsCategories: true
					}
				};
			}
			if (action.payload === FETCH_GET_CURRENT_CAMPSITE_DETAILS) {
				return {
					...state,
					areFetching: {
						...state.areFetching,
						isFetchingGetCurrentCampsiteDetails: true
					}
				};
			}
			if (action.payload === FETCH_GET_GLOBAL_INFORMATIONS_VERSION) {
				return {
					...state,
					areFetching: {
						...state.areFetching,
						isFetchingGetGlobalInformationsVersion: true
					}
				};
			}
			if (action.payload === FETCH_GET_CAMPSITES) {
				return {
					...state,
					areFetching: {
						...state.areFetching,
						isFetchingGetCampsites: true
					}
				};
			}
			break;

		case RESET_DATA:
			return {
				...state,
				...initialState
			};

		case SET_PROFILE_EDIT: {
			if (action.value) {
				return {
					...state,
					profileEdit: action.value
				};
			} else {
				const {
					photoUri,
					firstname,
					lastname,
					gender,
					company,
					companySiret,
					address,
					companyAddress,
					zipCode,
					companyZipCode,
					city,
					companyCity,
					phone,
					companyPhone,
					description,
					lang
				} = state.user;

				return {
					...state,
					profileEdit: false,
					photoUri,
					firstname,
					lastname,
					gender,
					companySiret,
					company,
					address,
					companyAddress,
					zipCode,
					companyZipCode,
					city,
					companyCity,
					phone,
					companyPhone,
					description,
					lang,
					firstnameErrorMsg: null,
					lastnameErrorMsg: null,
					companyErrorMsg: null,
					siretErrorMsg: null,
					phoneErrorMsg: null,
					addressErrorMsg: null,
					zipCodeErrorMsg: null,
					cityErrorMsg: null,
					passwordErrorMsg: null
				};
			}
		}

		case SET_UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				snackInfoMsg: t("profile:updatesuccess"),
				profileEdit: false
			};

		case SET_UPDATE_PROFILE_FAILURE:
			if (
				action.error &&
				action.error.message &&
				action.error.message.endsWith("409")
			) {
				return {
					...state,
					pseudoErrorMsg: t("profile:pseudoduplicate"),
					snackErrorMsg: t("profile:updateerror")
				};
			} else {
				return {
					...state,
					snackErrorMsg: t("profile:updateerror")
				};
			}

		case SET_PROFILE_FILES_IMAGES:
			return {
				...state,
				photoUri: null,
				files: action.payload
			};

		case REMOVE_PROFILE_IMAGE:
			return {
				...state,
				photoUri: null,
				files: []
			};

		case SET_LOGIN_WITH_FB_RESULT:
			if (action.data && action.data.accessToken) {
				return {
					...state,
					displayPassword: false,
					token: action.data.accessToken
				};
			}
			break;

		case SET_LANG:
			return {
				...state,
				defaultCountry: action.country
			};

		case CLEAR_PROFILE_PRO_VALIDATE:
			return {
				...state,
				firstnameErrorMsg: null,
				lastnameErrorMsg: null,
				isValid: false,
				clearValidate: true
			};

		case VALIDATE_PROFILE_PRO_INPUTS: {
			const firstnameErrorMsg =
				state.firstname && state.firstname.trim().length > 0
					? null
					: t("profile:firstnamemandatory");
			const lastnameErrorMsg =
				state.lastname && state.lastname.trim().length > 0
					? null
					: t("profile:lastnamemandatory");
			return {
				...state,
				clearValidate: false,
				firstnameErrorMsg,
				lastnameErrorMsg,
				isValid: !firstnameErrorMsg && !lastnameErrorMsg
			};
		}

		case CLEAR_PROFILE_VALIDATE:
			return {
				...state,
				firstnameErrorMsg: null,
				lastnameErrorMsg: null,
				phoneErrorMsg: null,
				companyErrorMsg: null,
				siretErrorMsg: null,
				addressErrorMsg: null,
				zipCodeErrorMsg: null,
				cityErrorMsg: null,
				passwordErrorMsg: null,
				isValid: false,
				clearValidate: true
			};

		case VALIDATE_PROFILE_INPUTS: {
			const firstnameErrorMsg =
				state.firstname && state.firstname.trim().length > 0
					? null
					: t("profile:firstnamemandatory");
			const lastnameErrorMsg =
				state.lastname && state.lastname.trim().length > 0
					? null
					: t("profile:lastnamemandatory");
			if (state.role === ROLE_ADVERTISER) {
				const companyErrorMsg =
					state.company && state.company.trim().length > 0
						? null
						: t("profile:companymandatory");
				const siretErrorMsg = validateSiret(state.companySiret);
				const phoneErrorMsg = validatePhone(state.companyPhone, true);
				const addressErrorMsg =
					state.companyAddress && state.companyAddress.trim().length > 0
						? null
						: t("profile:addressmandatory");
				const zipCodeErrorMsg =
					state.companyZipCode && state.companyZipCode.trim().length > 0
						? null
						: t("profile:zipcodemandatory");
				const cityErrorMsg =
					state.companyCity && state.companyCity.trim().length > 0
						? null
						: t("profile:citymandatory");
				return {
					...state,
					clearValidate: false,
					firstnameErrorMsg,
					lastnameErrorMsg,
					companyErrorMsg,
					siretErrorMsg,
					phoneErrorMsg,
					addressErrorMsg,
					zipCodeErrorMsg,
					cityErrorMsg,
					isValid:
						!firstnameErrorMsg &&
						!lastnameErrorMsg &&
						!companyErrorMsg &&
						!siretErrorMsg &&
						!phoneErrorMsg &&
						!addressErrorMsg &&
						!zipCodeErrorMsg &&
						!cityErrorMsg
				};
			}

			const phoneErrorMsg = validatePhone(state.phone, false);
			return {
				...state,
				clearValidate: false,
				firstnameErrorMsg,
				lastnameErrorMsg,
				phoneErrorMsg,
				isValid: !firstnameErrorMsg && !lastnameErrorMsg && !phoneErrorMsg
			};
		}

		case SET_USER_FAILURE:
			return {
				...state,
				snackErrorMsg: t("profile:error")
			};

		case CLEAR_PHOTO:
			return {
				...state,
				photoUri: undefined,
				files: []
			};

		case DELETE_USER_RESULT:
		case LOGOUT_RESULT:
			return {
				...state,
				user: null
			};

		case SET_PROFILE_DETAILS: {
			const {
				id,
				email,
				photoUri,
				pseudo,
				firstname,
				lastname,
				gender,
				birthdate,
				address,
				zipCode,
				city,
				company,
				companySiret,
				companyAddress,
				companyZipCode,
				companyCity,
				phone,
				companyPhone,
				description,
				role,
				lang
			} = action.payload;

			return {
				...state,
				id,
				email,
				photoUri,
				pseudo: pseudo && pseudo.indexOf("@") >= 0 ? null : pseudo,
				firstname,
				lastname,
				gender,
				birthdate,
				address,
				zipCode,
				city,
				company,
				companySiret,
				companyAddress,
				companyZipCode,
				companyCity,
				phone,
				companyPhone,
				description,
				lang,
				role,
				user: action.payload
			};
		}

		case SET_PSEUDO:
			return {
				...state,
				pseudo: action.value
			};

		case SET_FIRST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					firstname: action.value,
					firstnameErrorMsg: null
				};
			}
			break;

		case SET_LAST_NAME:
			if (
				!action.value ||
				RegExp("[a-zA-Z- 'çéèêë^¨ÉÈÊaâàù]{1}").test(
					action.value[action.value.length - 1]
				)
			) {
				return {
					...state,
					lastname: action.value,
					lastnameErrorMsg: null
				};
			}
			break;

		case SET_COMPANY_NAME:
			return {
				...state,
				company: action.value,
				companyErrorMsg: null
			};

		case SET_SIRET_CODE:
			if (
				!action.value ||
				(RegExp("[0-9]{1}").test(action.value[action.value.length - 1]) &&
					action.value.length <= 14)
			) {
				return {
					...state,
					companySiret: action.value,
					siretErrorMsg: null
				};
			}
			break;

		case SET_PHONE:
			return {
				...state,
				phone: action.value
			};

		case SET_COMPANY_PHONE:
			return {
				...state,
				companyPhone: action.value,
				phoneErrorMsg: null
			};

		case SET_BIRTHDATE:
			return {
				...state,
				birthdate: action.value,
				birthdayErrorMsg: validateBirthday(action.value)
			};

		case SET_ADDRESS:
			return {
				...state,
				companyAddress: action.value,
				addressErrorMsg: null
			};

		case SET_GENDER:
			return {
				...state,
				gender: action.value
			};

		case SET_ZIP_CODE:
			return {
				...state,
				companyZipCode: action.value,
				zipCodeErrorMsg: null
			};

		case SET_CITY:
			return {
				...state,
				companyCity: action.value,
				cityErrorMsg: null
			};

		case SET_USER_PASSWORD: {
			return {
				...state,
				password: action.value
			};
		}

		case SET_USER_PASSWORD_CONFIRM: {
			return {
				...state,
				passwordConfirm: action.value
			};
		}

		case SET_COUNTRY_CODE:
			return {
				...state,
				countryCode: action.value
			};

		case SET_DESCRIPTION_FR:
			return {
				...state,
				description: action.value
			};

		case SET_DESCRIPTION_EN: {
			let en = state.lang ? state.lang.en : {};
			if (action.value) {
				en = {
					en: {
						...en,
						description: action.value
					}
				};
			}
			return {
				...state,
				lang: en
			};
		}

		case SET_USER_HOBBIES:
			return {
				...state,
				hobbiesIds: action.value
			};

		case SET_LOCATION_ID: {
			const { locationId: previous_locationId } = state.user;
			return {
				...state,
				locationId: action.value,
				locationIdChanged: previous_locationId !== action.value,
				locationErrorMsg: action.value
					? null
					: t("profile:accomodationmandatory")
			};
		}

		case SET_DATE_RANGE: {
			const { startDate: previous_startDate, endDate: previous_endDate } =
				state.user;
			return {
				...state,
				daterangeErrorMsg: action.value
					? null
					: t("profile:bookingdatesmandatory"),
				dateRangeChanged:
					previous_startDate !== action.value.startDate ||
					previous_endDate !== action.value.endDate,
				startDate: action.value.startDate,
				endDate: action.value.endDate
			};
		}

		case SET_PHOTO_URI:
			return {
				...state,
				photoUri: action.value,
				files: action.files
			};

		case SET_REGISTER_PROFESSIONAL_RESULT:
			return {
				...state,
				snackInfoMsg: t("profile:addsuccess")
			};

		case SET_REGISTER_PROFESSIONAL_FAILURE:
			return {
				...state,
				snackErrorMsg: t("profile:adderror"),
				profileEdit: false
			};

		case API_END:
			if (action.payload === FETCH_GET_USER) {
				const { isFetchingGetUser, ...others } = state.areFetching;
				return {
					...state,
					isFetching: Object.keys(state.areFetching).length > 1,
					areFetching: others
				};
			}
			if (action.payload === FETCH_GET_GOOD_DEALS_CATEGORIES) {
				const { isFetchingGetGoodDealsCategories, ...others } =
					state.areFetching;
				return {
					...state,
					isFetching: Object.keys(state.areFetching).length > 1,
					areFetching: others
				};
			}
			if (action.payload === FETCH_GET_CURRENT_CAMPSITE_DETAILS) {
				const { isFetchingGetCurrentCampsiteDetails, ...others } =
					state.areFetching;
				return {
					...state,
					isFetching: Object.keys(state.areFetching).length > 1,
					areFetching: others
				};
			}
			if (action.payload === FETCH_GET_GLOBAL_INFORMATIONS_VERSION) {
				const { isFetchingGetGlobalInformationsVersion, ...others } =
					state.areFetching;
				return {
					...state,
					isFetching: Object.keys(state.areFetching).length > 1,
					areFetching: others
				};
			}
			if (action.payload === FETCH_GET_CAMPSITES) {
				const { isFetchingGetCampsites, ...others } = state.areFetching;
				return {
					...state,
					isFetching: Object.keys(state.areFetching).length > 1,
					areFetching: others
				};
			}
			if (
				action.payload === FETCH_UPDATE_PROFILE ||
				action.payload === FETCH_REGISTER_PROFESSIONAL
			) {
				return {
					...state,
					isFetching: false
				};
			}
			break;

		default:
			return state;
	}
	return state;
}
